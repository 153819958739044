const MobileDeviceIcon = () => {
  return (
    <svg
      width={26}
      height={28}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 22H12.51M8.5 25H16.5C17.0304 25 17.5391 24.7893 17.9142 24.4142C18.2893 24.0391 18.5 23.5304 18.5 23V9C18.5 8.46957 18.2893 7.96086 17.9142 7.58579C17.5391 7.21071 17.0304 7 16.5 7H8.5C7.96957 7 7.46086 7.21071 7.08579 7.58579C6.71071 7.96086 6.5 8.46957 6.5 9V23C6.5 23.5304 6.71071 24.0391 7.08579 24.4142C7.46086 24.7893 7.96957 25 8.5 25Z"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="11.5" width={14} height={14} rx={7} fill="#FEE2E2" />
      <path
        d="M15.5 4L21.5 10M15.5 10L21.5 4L15.5 10Z"
        stroke="#FF6D6D"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobileDeviceIcon;
