import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IOrder from '@/types/IOrder';

export interface IListOrders {
  page: string;
  pageSize: number;
  fulfillmentStatus: string;
  orgID: number;
}

export interface IProperty {
  name: string;
  value: string;
}

export interface ILineItem {
  quantity: number;
  variant_id: string | number;
  properties: IProperty[];
}

export interface ICursorPaging {
  next?: {
    page_info: string;
  };
  previous?: {
    page_info: string;
  };
}

export interface ICustomer {
  email: string;
}



export interface IShippingAddress {
  first_name: string
  last_name: string,
  address1: string,
  city: string,
  zip: string,
  province?: string,
  province_code?: string,
  country?: string,
  country_code?: string,
  company: string,
}

export interface IBillingAddress {
  first_name: string
  last_name: string,
  address1: string,
  city: string,
  zip: string,
  province?: string,
  province_code?: string,
  country?: string,
  country_code?: string,
  company: string,
}

export interface IReOrderData {
  financial_status: string,
  customer: ICustomer,
  shipping_address: IShippingAddress,
  order_status_url: string
}

export interface IShippingLine {
  title: string,
  price: string,
  code: string
}

export interface ITaxLine {
  price: string,
  rate: string,
  title: string
}

const ordersAPI = {
  listOrders: ({
    page = '',
    pageSize = 20,
    fulfillmentStatus = '',
    orgID = -1,
  }: Partial<IListOrders>): Promise<
    AxiosResponse<{ data: IOrder[]; paging: ICursorPaging }>
  > => {
    /*
      A request that includes the page_info parameter can't include any other parameters except for limit and fields 
      (if it applies to the endpoint). If you want your results to be filtered by other parameters, 
      then you need to include those parameters in the first request you make.
    */

    return axiosApiService.get(
      `${BASE_URL}/admin/api/orders?limit=${pageSize}${page !== '' ? `&page_info=${page}` : ''
      }${fulfillmentStatus !== '' && page === ''
        ? `&fulfillment_status=${fulfillmentStatus}`
        : ''
      }
      ${orgID !== -1 && page === '' ? `&org_id=${orgID}` : ''}`,
    );
  },

  createReorder: (
    organisationId: number,
    lineItems: ILineItem[],
    financialStatus: string,
    currency: string,
    customer: ICustomer,
    shippingAddress: IShippingAddress,
    billingAddress: IBillingAddress,
    shipping_lines: IShippingLine[],
    tax_lines: ITaxLine[]
  ): Promise<AxiosResponse<{ data: IReOrderData }>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/orders`,
      {
        organisation_id: organisationId,
        line_items: lineItems,
        financial_status: financialStatus,
        currency,
        customer,
        shipping_address: shippingAddress,
        billing_address: billingAddress,
        shipping_lines,
        tax_lines
      },
    );
  },
};

export default ordersAPI;
