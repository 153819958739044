import qs from 'qs';

const getVariantId = (minimum_no_of_profiles: number) => {
  const envVariableName = `VITE_SUBSCRIPTION_TIER_${minimum_no_of_profiles}_ID`;

  const { [envVariableName]: variantId } = import.meta.env;
  return variantId as string;
}

const tiers = [
  {
    label: '5-9 profiles',
    minimum_no_of_profiles: 5,
    maximum_no_of_profiles: 9,
    variant_id: getVariantId(5),
    price: 49
  },
  {
    label: '10-24 profiles',
    minimum_no_of_profiles: 10,
    maximum_no_of_profiles: 24,
    variant_id: getVariantId(10),
    price: 99
  },
  {
    label: '25-49 profiles',
    minimum_no_of_profiles: 25,
    maximum_no_of_profiles: 49,
    variant_id: getVariantId(25),
    price: 349
  },
  {
    label: '50-149 profiles',
    minimum_no_of_profiles: 50,
    maximum_no_of_profiles: 149,
    variant_id: getVariantId(50),
    price: 999
  },
  {
    label: '150-249 profiles',
    minimum_no_of_profiles: 150,
    maximum_no_of_profiles: 249,
    variant_id: getVariantId(150),
    price: 1999
  },
  {
    label: '250-499 profiles',
    minimum_no_of_profiles: 250,
    maximum_no_of_profiles: 499,
    variant_id: getVariantId(250),
    price: 3749
  },
  {
    label: '500-999 profiles',
    minimum_no_of_profiles: 500,
    maximum_no_of_profiles: 999,
    variant_id: getVariantId(500),
    price: 7499
  },
  {
    label: '1000-1999 profiles',
    minimum_no_of_profiles: 1000,
    maximum_no_of_profiles: 1999,
    variant_id: getVariantId(1000),
    price: 14999
  }
]

const getTierByTotalProfiles = (no_of_profiles: number) => {
  return tiers.find(tier => {
    return no_of_profiles >= tier.minimum_no_of_profiles && no_of_profiles <= tier.maximum_no_of_profiles;
  });
}

const getTierByVariantId = (variantId: string) => {
  return tiers.find(tier => tier.variant_id === variantId);
}

const generateCheckoutBaseUrl = (variantId: string, attributes: object) => {
  const checkoutUrl = import.meta.env.VITE_SUBSCRIPTION_CHECKOUT_URL as string;
  const quantity = 1;
  const sellingPlanId = import.meta.env.VITE_SUBSCRIPTION_SELLING_PLAN_ID as string;
  const encodedAttributes = qs.stringify(attributes);
  const queryString = qs.stringify({ attributes: encodedAttributes });

  return `${checkoutUrl}/${variantId}:${quantity}:${sellingPlanId}?${queryString}`;
}

export { getTierByTotalProfiles, getTierByVariantId, generateCheckoutBaseUrl };