import { Link } from 'react-router-dom';

import { ICardAdmin } from '@/types/ICard';

const OrderCell: React.FC<{ card: ICardAdmin }> = ({ card }) => {
  const orderNumber = card.order_number;

  return (
    <td className="p-4 pb-3 hidden md:table-cell">
      {orderNumber && (
        <Link
          to={`/orders-v2/?search=${orderNumber}#all_orders`}
          className="appearance-none text-sm leading-5 text-brand-500 text-left truncate"
          target="_blank"
        >
          {`#${orderNumber}`}
        </Link>
      )}
    </td>
  );
};

export default OrderCell;
