import Button from '@/components/Button';
import Input from '@/components/Input';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getCreditsBalance, replaceText } from '@/api/pdf';

const PdfPocPage: React.FC = () => {
  const [file, setFile] = useState('');
  const [name, setName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [creditsBalance, setCreditsBalance] = useState<number>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getBalance = async () => {
      const response = await getCreditsBalance();
      setCreditsBalance(response.data.remainingCredits);
    };

    getBalance();
  }, []);

  // const processPdf = () => {
  //   const baseUrl = `${window.location.protocol}//${window.location.host}/`;
  //   const documentBlobObjectUrl = URL.createObjectURL(file);

  //   const updatePDF = async () => {
  //     const instance = await PSPDFKit.load({
  //       baseUrl,
  //       document: documentBlobObjectUrl,
  //       headless: true,
  //       container: '#pspdfkit',
  //       enableRichText: (_) => true,
  //     });

  //     const annotations = await instance.getAnnotations(0);
  //     console.log(annotations.size);
  //     const annotationsToUpdate = annotations
  //       .filter((it) => it instanceof PSPDFKit.Annotations.TextAnnotation)
  //       .map((annotation) => {
  //         console.log(annotation.text, replaceText);
  //         return searchText
  //           .split(' ')
  //           .every((word) => annotation.text.value.includes(word))
  //           ? annotation.set('text', {
  //               format: 'plain',
  //               value: replaceText,
  //             })
  //           : annotation;
  //       });

  //     await instance.update(annotationsToUpdate);

  //     instance.exportPDF().then((buffer) => {
  //       const blob = new Blob([buffer], { type: 'application/pdf' });
  //       const fileName = 'document.pdf';
  //       if (window.navigator.msSaveOrOpenBlob) {
  //         window.navigator.msSaveOrOpenBlob(blob, fileName);
  //       } else {
  //         const objectUrl = URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.href = objectUrl;
  //         a.style = 'display: none';
  //         a.download = fileName;
  //         document.body.appendChild(a);
  //         a.click();
  //         URL.revokeObjectURL(objectUrl);
  //         document.body.removeChild(a);
  //       }
  //     });
  //   };

  //   updatePDF();
  // };

  const uploadPdf = async () => {
    setLoading(true);
    const searchStrings = [
      fieldMap.name,
      fieldMap.jobTitle,
      fieldMap.mobile,
      fieldMap.email,
      fieldMap.phone,
      fieldMap.address,
      fieldMap.website,
    ];
    const replaceStrings = [
      name,
      jobTitle,
      mobile,
      email,
      phone,
      address,
      website,
    ];
    try {
      const response = await replaceText(file, searchStrings, replaceStrings);

      const a = document.createElement('a');
      a.href = response.data.url;
      a.classList.add('hidden');
      a.download = 'poc.pdf';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(response.data.url);
      document.body.removeChild(a);
      setCreditsBalance(response.data.remainingCredits);
    } finally {
      setLoading(false);
    }
  };

  const fieldMap = {
    name: '[firstname] [lastname]',
    jobTitle: '[jobtitle]',
    mobile: '[mobile]',
    email: '[email]',
    phone: '[phone]',
    address: '[address]',
    website: '[website]',
  };

  return (
    <div className="flex">
      <div className="w-1/3">
        <div className="pt-10 pl-10 p-5">
          <div className="text-2xl">Edit PDF</div>
          <div className="mt-8 ">
            <div className="mb-4">
              <Input
                label="PDF File URL (Dropbox, Google Drive or pdf.co)"
                type="text"
                value={file}
                onChange={setFile}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Name"
                type="text"
                value={name}
                onChange={setName}
                placeholder={fieldMap.name}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Job Title"
                type="text"
                value={jobTitle}
                onChange={setJobTitle}
                placeholder={fieldMap.jobTitle}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Mobile"
                type="text"
                value={mobile}
                onChange={setMobile}
                placeholder={fieldMap.mobile}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Email"
                type="text"
                value={email}
                onChange={setEmail}
                placeholder={fieldMap.email}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Phone"
                type="text"
                value={phone}
                onChange={setPhone}
                placeholder={fieldMap.phone}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Address"
                type="text"
                value={address}
                onChange={setAddress}
                placeholder={fieldMap.address}
              />
            </div>
            <div className="mb-4">
              <Input
                label="Website"
                type="text"
                value={website}
                onChange={setWebsite}
                placeholder={fieldMap.website}
              />
            </div>
            <div className="flex justify-end">
              <Button
                buttonText="Process"
                onClick={uploadPdf}
                loading={loading}
              />
            </div>
          </div>

          {/* <div
        id="pspdfkit"
        style={{
          width: '100%',
          height: '100vh',
        }}
      /> */}
        </div>
      </div>
      <div className="pt-10 pl-10 p-5 w-2/3 flex justify-end">
        <div>
          <a href="https://app.pdf.co/" className="mr-2">
            <span className="mr-1">Credits balance:</span>
            {creditsBalance === undefined ? (
              <Skeleton width={30} height={20} />
            ) : (
              <span className="font-bold text-sm">{creditsBalance}</span>
            )}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PdfPocPage;
