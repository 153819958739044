import { Fragment, useCallback, useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon as DotsVerticalIcon } from '@heroicons/react/20/solid';

import adminAPI from '@/api/admin';
import Badge, { BADGE_KIND } from '@/components/Badge';
import InputCheckbox from '@/components/InputCheckbox';
import Modal from '@/components/Modals/Modal';
import classNames from '@/helpers/classNames';
import IOrganisation from '@/types/IOrganisation';

import Search from './Search';

interface IOrdersListItem {
  id: string;
  url: string;
  name: string;
  email: string;
  activationKey: string | undefined;
  activationStatus: string | undefined;
  activationOrg: number | undefined;
  designUrl: string[];
  status: string | undefined;
  selected: boolean;
  activationId: number | undefined;
  hasQrCodes: boolean;
  checkItem: () => void;
  listOrders: () => void;
  markSuccess: () => void;
}

export default function OrdersListItem({
  id,
  url,
  name,
  email,
  activationKey,
  activationStatus,
  activationOrg,
  designUrl,
  status,
  selected,
  activationId,
  checkItem,
  listOrders,
  markSuccess,
}: IOrdersListItem) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orgDataModal, setOrgDataModal] = useState<IOrganisation[]>();
  const [orgModalSearch, setOrgModalSearch] = useState<string>('');
  const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false);
  const [orgIDModal, setOrgIDModal] = useState<number>(-1);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const listOrganisationsModal = useCallback(
    async (newOrgModalSearch: string) => {
      const res = await adminAPI.listOrganisations({
        pageSize: 5,
        search: newOrgModalSearch,
      });

      setOrgDataModal(
        res.data?.data?.filter((item: IOrganisation) => item.name),
      );

      return res.data;
    },
    [],
  );

  function handleReassign(
    orgIdModal: number,
    cardActivationID: number | undefined,
  ) {
    if (cardActivationID !== undefined) {
      setIsLoading(true);
      setSuccess('');
      setError(false);
      adminAPI
        .updateOrderOrganisation(cardActivationID, {
          card_activation: {
            organisation_id: orgIdModal,
          },
        })
        .catch(() => setError(true))
        .then(() => setIsLoading(false))
        .then(() => listOrders())
        .then(() => markSuccess())
        .finally(() => setIsOpen(false));
    }
  }

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${id}}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div
              className={classNames(
                'min-w-0 flex-1 md:grid md:grid-cols-5 md:gap-4 xl:grid-cols-6',
              )}
            >
              <div className="flex items-center">
                <p className="text-sm text-gray-500 font-medium">
                  <a href={url}>{id}</a>
                </p>
              </div>
              <div className="xl:flex flex-col">
                <p className="hidden lg:block text-sm text-gray-900 truncate">
                  {name}
                </p>
                <p className="hidden lg:block text-sm text-gray-500 truncate">
                  {email}
                </p>
              </div>
              <div className="flex items-center">
                <div>
                  <p className="text-sm text-gray-500 font-medium">
                    {activationKey}
                  </p>
                  <p className="mt-1">
                    {activationStatus === 'activated' && (
                      <Badge kind={BADGE_KIND.GREEN} text="Activated" />
                    )}
                    {activationStatus === 'not_activated' && (
                      <Badge kind={BADGE_KIND.AMBER} text="Not activated" />
                    )}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                {activationOrg && (
                  <p className="text-sm text-gray-900 font-medium mb-1">
                    <a href={`/?scope=user&uOrgID=${activationOrg}`}>
                      #{activationOrg}
                    </a>
                  </p>
                )}
              </div>
              <div className="flex items-center">
                {designUrl.map(url => (
                  <p className="items-center text-sm text-gray-500 font-medium">
                    <a href={url}>Click here</a>
                  </p>
                ))}
              </div>
              <div className="flex items-center justify-end">
                <p className="text-sm text-gray-500 font-medium">{status}</p>
                <Menu as="div" className="relative inline-block text-left">
                  <div className="flex items-center">
                    <Menu.Button className="appearance-none">
                      <DotsVerticalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                      <div>
                        <Menu.Item>
                          <button
                            type="button"
                            onClick={() => setIsOpen(true)}
                            className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
                          >
                            Assign to different org
                          </button>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            dialogTitle="Move Order and Profiles"
            dialogDescription="This will move the order and profiles associated with the order to the selected organisation"
            successButtonText="Move profiles"
            onSuccess={() => {
              handleReassign(orgIDModal, activationId);
            }}
            isLoading={isLoading}
          >
            <div className="mt-6">
              <div className="mt-6 relative">
                <Search
                  id={`OrgList-1-20-date-desc--${orgModalSearch}`}
                  placeholder="Search for Organisation ID or Name"
                  search={orgModalSearch}
                  setSearch={value => {
                    if (value !== orgModalSearch) {
                      setOrgIDModal(-1);
                      setShowOptionsModal(true);
                    }
                    setOrgModalSearch(value);
                  }}
                  fetchQuery={newOrgModalSearch =>
                    listOrganisationsModal(newOrgModalSearch)
                  }
                />

                {orgModalSearch && showOptionsModal && (
                  <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="py-1">
                      {orgDataModal && orgDataModal.length ? (
                        orgDataModal.map(item => (
                          <button
                            type="button"
                            key={item.id}
                            className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                            onClick={() => {
                              setOrgIDModal(item.id);
                              setShowOptionsModal(false);
                              setOrgModalSearch(`#${item.id} ${item.name}`);
                            }}
                          >
                            #{item.id}{' '}
                            <span className="text-gray-500">{item.name}</span>
                          </button>
                        ))
                      ) : (
                        <span className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left text-grey-500">
                          No matching items found
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </li>
  );
}
