import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import ICollection from '@/types/ICollection';

const collectionsAPI = {
  listCollections: (): Promise<AxiosResponse<{ data: ICollection[] }>> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/collections`);
  },
  createCollection: (
    name: string,
    csv: File,
  ): Promise<AxiosResponse<ICollection>> => {
    const formData = new FormData();
    formData.append('file', csv);
    formData.append('name', name);

    return axiosApiService.post(`${BASE_URL}/admin/api/collections`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteCollection: (id: string): Promise<AxiosResponse<string>> => {
    return axiosApiService.delete(`${BASE_URL}/admin/api/collections/${id}`);
  },
  updateCollection: (
    id: number,
    profileIds: number[],
    orgIds: number[],
    cardIds: number[],
  ) => {
    return axiosApiService.put(`${BASE_URL}/admin/api/collections/${id}`, {
      profile_ids: profileIds,
      organisation_ids: orgIds,
      card_ids: cardIds,
    });
  },
};

export default collectionsAPI;
