import { CSVImportError } from '.';
import ErrorIcon from '../Icons/ErrorIcon';
import CsvFieldsRow, { FieldMapping } from './CsvFieldRow';
import data from './data';

export interface CsvFileUploadStep2Props {
  csvFields: string[];
  csvEntries: Record<string, unknown>[];
  setHasImportError: (value: boolean) => void;
  hasImportError: boolean;
  originalCsvFieldMapping: FieldMapping[];
  csvFieldMapping: FieldMapping[];
  setCsvFieldMapping: (value: FieldMapping[]) => void;
  updateFieldMapping: (value: FieldMapping) => void;
  importErrors: CSVImportError;
  setImportErrors: (value: CSVImportError) => void;
  setCsvMappedEntries: (value: Record<string, unknown>[]) => void;
  csvMappedEntries: Record<string, unknown>[];
  isAnyFieldMissing: boolean;
}

const CsvFileUploadStep2 = ({
  csvEntries,
  csvFields,
  hasImportError,
  csvFieldMapping,
  importErrors,
  isAnyFieldMissing,
  updateFieldMapping,
}: CsvFileUploadStep2Props) => {
  const exampleEntry = csvEntries[0];

  return (
    <>
      <div className="relative my-6 overflow-x-auto border border-solid rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="table w-full text-xs text-gray-700 uppercase bg-gray-50 [&>tr>*>div]:w-[140px] [&>tr>*>div]:break-all">
            <tr className="border-b border-solid uppercase">
              <th
                scope="col"
                className="w-1/4 px-6 py-3 text-gray-900 text-sm font-medium leading-4 tracking-[0.7px]"
              >
                CSV Fields
              </th>
              <th
                scope="col"
                className="w-1/4 px-6 py-3 text-gray-900 text-sm text-center font-medium leading-4 tracking-[0.7px]"
              >
                CSV Import
              </th>
              <th
                scope="col"
                className="w-1/4 px-6 py-3 text-gray-900 text-sm font-medium leading-4 tracking-[0.7px]"
              >
                Tapt Field
              </th>
              <th
                scope="col"
                className="w-1/4 px-6 py-3 text-gray-900 text-sm text-center font-medium leading-4 tracking-[0.7px]"
              >
                Mapped
              </th>
            </tr>
          </thead>
          <tbody className="block w-full table-fixed h-[18.75rem] large-screen:h-[40rem] overflow-auto [&>tr:not(:last-child)]:border-b [&>tr:not(:last-child)]:border-solid [&>tr>*>div]:w-[140px] [&>tr>*>div]:break-all">
            {csvFields.map((field, index) => {
              const fieldMapping = csvFieldMapping.find(
                i => i.csvField === field,
              );

              return (
                <CsvFieldsRow
                  csvFieldMapping={csvFieldMapping}
                  exampleEntry={exampleEntry}
                  field={field}
                  index={index}
                  errors={importErrors[field]}
                  key={index}
                  fieldMapping={fieldMapping}
                  onChange={value => {
                    if (fieldMapping) {
                      updateFieldMapping({
                        ...fieldMapping,
                        taptField: value,
                      });
                    }
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      {(hasImportError || isAnyFieldMissing) && (
        <div
          className="flex items-center p-4 text-sm text-red-800 rounded-lg bg-red-50"
          role="alert"
        >
          <ErrorIcon className="flex-shrink-0 inline w-4 h-4 mr-3" />
          <span className="sr-only">Info</span>
          <div>
            {isAnyFieldMissing
              ? data.mappingErrorMessage
              : data.importErrorMessage}
          </div>
        </div>
      )}
    </>
  );
};

export default CsvFileUploadStep2;
