import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useBreakpoint } from 'use-breakpoint';
import { IMostActiveProfile } from '@/types/IAnalytics';
import { BREAKPOINTS } from '@/components/ColumnChart';

const defaultHeight = 340;

type MostActiveProfilesProps = {
  data: IMostActiveProfile[];
  height?: number;
  title: string;
  subtitle: string;
  mobileSubtitle: string;
};

function MostActiveProfiles({
  data,
  height,
  title,
  subtitle,
  mobileSubtitle,
}: MostActiveProfilesProps) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');

  const dataPresent = data.length > 1;
  const currentData = dataPresent ? data : [];
  const series = [
    {
      data: currentData.map((item) => item.total),
      name: 'Total',
    },
  ];
  const labels = currentData.map((item) => item.full_name);
  const categories = labels?.map((label) => label.split(' '));

  const defaultOptions: ApexOptions = {
    chart: {
      fontFamily: 'Poppins',
      toolbar: {
        show: false,
        offsetX: -20,
      },
    },
    plotOptions: {
      area: {
        fillTo: 'end',
      },
      bar: {
        borderRadius: 8,
      },
    },
    dataLabels: {
      enabled: false,
    },
    theme: {
      monochrome: {
        enabled: true,
        color: '#873CFF',
      },
    },
    tooltip: {
      style: {
        fontSize: '14px',
      },
      custom({ dataPointIndex }: { dataPointIndex: number }) {
        const profile: IMostActiveProfile | undefined =
          currentData[dataPointIndex];
        const { views, sends, receives } = {
          views: profile?.views_count || 0,
          sends: profile?.sends_count || 0,
          receives: profile?.receives_count || 0,
        };

        return `<div class="p-3">
                  <div>Profile views: <strong>${views}</strong></div>
                  <div>Contacts sent: <strong>${sends}</strong></div>
                  <div>Contacts received: <strong>${receives}</strong></div>
                </div>`;
      },
    },
  };

  const options: ApexOptions = {
    ...defaultOptions,
    responsive: [
      {
        breakpoint: BREAKPOINTS.tablet,
        options: {
          xaxis: {
            categories: categories.slice(0, 5),
            labels: {
              rotate: -45,
              rotateAlways: true,
            },
          },
        },
      },
    ],
    xaxis: {
      categories,
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
    },
  };

  return (
    <div className="flex flex-col justify-between relative">
      <div>
        <h2 className="text-gray-900 font-poppins font-medium text-2xl md:text-2xl">
          {title}
        </h2>
        <span className="text-gray-500 text-sm mt-2 hidden md:block">
          {subtitle}
        </span>
        <h3 className="text-gray-500 mt-2 md:hidden">{mobileSubtitle}</h3>
        <hr className="h-px mt-4 mb-8 bg-gray-200 border-0 " />
      </div>
      <Chart
        height={height || defaultHeight}
        options={options}
        series={
          breakpoint === 'mobile'
            ? [{ data: series[0].data.slice(0, 5), name: 'Total' }]
            : series
        }
        type="bar"
      />
    </div>
  );
}

export default MostActiveProfiles;
