import { useHistory } from 'react-router-dom';

import { ICardAdmin } from '@/types/ICard';

const OrganisationCell: React.FC<{ card: ICardAdmin }> = ({ card }) => {
  const history = useHistory();
  const profile = card.profile;

  return (
    <td className="p-4 pb-3 hidden md:table-cell">
      {profile && (
        <>
          <p className="font-medium truncate text-sm text-gray-900">
            {profile.organisation_name}
          </p>
          <button
            type="button"
            onClick={() =>
              history.push(`/view-organisation/${profile.organisation_id}`, {
                fromName: 'Cards',
                fromURL: '/',
              })
            }
            className="appearance-none text-sm leading-5 text-brand-500 text-left truncate"
          >
            <span className="text-sm md:hidden">Org ID:&nbsp;</span>
            {`#${profile.organisation_id}`}
          </button>
        </>
      )}
    </td>
  );
};

export default OrganisationCell;
