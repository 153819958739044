import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IFile from '@/types/IFile';
import { IListPaging } from '@/types/IList';

const filesAPI = {
  listFiles: (
    orgID: number,
  ): Promise<AxiosResponse<{ data: IFile[]; paging: IListPaging }>> => {
    return axiosApiService.get(`${BASE_URL}/api/organisations/${orgID}/files`);
  },
  createFile: async (body: {
    file: {
      organisation_id: number;
      name: string;
    };
  }): Promise<AxiosResponse<{ data: IFile }>> => {
    return axiosApiService.post(`${BASE_URL}/api/files`, body);
  },
  uploadFile: (
    fileID: number,
    formData: FormData,
  ): Promise<AxiosResponse<{ data: IFile }>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/files/${fileID}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  uploadFileMultiple: (
    fileID: number[],
    formData: FormData,
  ): Promise<AxiosResponse<{ data: IFile[] }>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/files/${fileID}/upload_multiple`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  uploadOrderFile: (
    fileID: number,
    formData: FormData,
  ): Promise<AxiosResponse<{ data: IFile }>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/design-files/${fileID}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  showFile: (
    orgID: number,
    fileID: number,
  ): Promise<AxiosResponse<{ data: IFile }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/files/${fileID}`,
    );
  },
  downloadFile: (
    url: string,
  ): any => {
    return axios.get(
      !url.includes('http') ? `${BASE_URL}${url}` : url,
      {
        responseType: "blob",
      }
    );
  },
  showConsent: (fileID: number): Promise<AxiosResponse<{ data: IFile }>> => {
    return axiosApiService.get(`${BASE_URL}/api/files/${fileID}`);
  },
  deleteFile: (orgID: number, fileID: number): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/api/organisations/${orgID}/files/${fileID}`,
    );
  },
  createAndUploadFile: async (
    orgID: number,
    file: File,
  ): Promise<AxiosResponse<{ data: IFile }>> => {

    const res = await filesAPI.createFile({
      file: {
        organisation_id: orgID,
        name: file.name,
      },
    })

    const formData = new FormData();
    formData.append('file', file);
    return filesAPI.uploadFile(res.data?.data?.id, formData);
  },
  createAndUploadOrderFile: async (
    orgID: number,
    file: File,
  ): Promise<AxiosResponse<{ data: IFile }>> => {

    const res = await filesAPI.createFile({
      file: {
        organisation_id: orgID,
        name: file.name,
      },
    })

    const formData = new FormData();
    formData.append('file', file);
    return filesAPI.uploadOrderFile(res.data?.data?.id, formData);
  },

};

export default filesAPI;
