import styled from "styled-components";

const CustomScroll = styled.div({
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        backgroundColor: 'none',
        position: 'relative',
        // right: '-20px'
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        backgroundColor: '#652DBF',
        borderRadius: '100px',
    },
    '&.outside-scroll': {
        width: 'calc(100% + 10px)',
        '& .overflow-auto': {
            paddingRight: '20px'
        }
    }

})


export { CustomScroll as default, CustomScroll };