import { IIcon } from '@/types/IIcon';

const Shop = ({ className }: IIcon): JSX.Element => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C2.44772 3 2 3.44772 2 4V19.9429C2 20.7447 2.30942 21.5192 2.86879 22.0946C3.42909 22.6709 4.19511 23 5 23H19C19.8049 23 20.5709 22.6709 21.1312 22.0946C21.6906 21.5192 22 20.7447 22 19.9429V4C22 3.44772 21.5523 3 21 3H3ZM4 19.9429V5H20V19.9429C20 20.2322 19.888 20.5042 19.6972 20.7004C19.5074 20.8957 19.256 21 19 21H5C4.74402 21 4.49263 20.8957 4.30278 20.7004C4.112 20.5042 4 20.2322 4 19.9429ZM9 9C9 8.44772 8.55228 8 8 8C7.44772 8 7 8.44772 7 9C7 10.3261 7.52678 11.5979 8.46447 12.5355C9.40215 13.4732 10.6739 14 12 14C13.3261 14 14.5979 13.4732 15.5355 12.5355C16.4732 11.5979 17 10.3261 17 9C17 8.44772 16.5523 8 16 8C15.4477 8 15 8.44772 15 9C15 9.79565 14.6839 10.5587 14.1213 11.1213C13.5587 11.6839 12.7956 12 12 12C11.2044 12 10.4413 11.6839 9.87868 11.1213C9.31607 10.5587 9 9.79565 9 9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Shop;
