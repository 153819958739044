import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Tab } from '@headlessui/react';
import { EyeIcon } from '@heroicons/react/20/solid';

import filesAPI from '@/api/files';
import organisationsAPI from '@/api/organisations';
import profilesAPI from '@/api/profiles';
import { SuccessAlert } from '@/components/Alert';
import { BUTTON_KIND } from '@/components/Button';
import Button from '@/components/Button';
import ColorPicker from '@/components/ColorPicker';
import { AddProfilesModal } from '@/components/GroupPage/AddProfilesModal';
import { GroupEditingAccess } from '@/components/GroupPage/GroupEditingAccess';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Layout from '@/components/Layout';
import { ProfilePreviewModal } from '@/components/ProfilePreview';
import TabList from '@/components/TabList';
import UploadPhoto from '@/components/UploadPhoto';
import COLORS from '@/constants/colors';
import { COVER_IMAGE } from '@/constants/files';
import GroupInfoForm from '@/containers/GroupInfoForm';
import { objectKeys } from '@/helpers/objects';
import useAuth from '@/hooks/useAuth';
import { PAGE_NAME as GROUP_PREVIEW_PAGE_NAME } from '@/pages/GroupPreviewPage';
import { ColorSetters } from '@/types/ColorSetters';
import IFile from '@/types/IFile';
import { IOrganisationInvite, IOrganisationUser } from '@/types/IOrganisation';

interface CreateGroupPageProps {
  location: {
    state: {
      [key: string]: string;
    };
  };
}

const tabs = [
  {
    value: 'general',
    name: 'General',
  },
  {
    value: 'group-template',
    name: 'Group Template',
  },
];

function CreateGroupPage({ location }: CreateGroupPageProps) {
  const { orgID } = useAuth();
  const history = useHistory();

  const { data: orgSettings } = useQuery(
    'showOrganisationSettings',
    showOrganisationSettings,
    {
      enabled: orgID !== undefined,
    },
  );

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [subheading, setSubheading] = useState('');

  const [groupNameError, setGroupNameError] = useState(false);
  const [addedProfiles, setAddedProfiles] = useState<Set<number>>(new Set());
  const [isAddProfilesModalOpen, setIsAddProfilesModalOpen] = useState(false);
  const [editors, setEditors] = useState<IOrganisationUser[]>([]);
  const [invites, setInvites] = useState<IOrganisationInvite[]>([]);
  const [newGroupManagers, setNewGroupManagers] = useState<string[]>([]);

  const [groupCoverImageFile, setGroupCoverImageFile] = useState<
    IFile | undefined
  >(undefined);
  const [backgroundColor, setBackgroundColor] = useState(
    COLORS.organisation.defaultProfile.backgroundColor,
  );
  const [textColor, setTextColor] = useState(
    COLORS.organisation.defaultProfile.textColor,
  );
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState(
    COLORS.organisation.defaultProfile.buttonBackgroundColor,
  );
  const [buttonTextColor, setButtonTextColor] = useState(
    COLORS.organisation.defaultProfile.buttonTextColor,
  );

  const [profilesSuccess, setProfilesSuccess] = useState<string | undefined>(
    undefined,
  );

  const { mutate: createGroup, isLoading: isCreatingGroup } = useMutation({
    mutationKey: 'createOrganisationGroup',
    mutationFn: createOrganisationGroup,
    onSuccess: () => {
      history.push('/groups', {
        success: 'Group has been created successfully.',
      });
    },
  });

  const colorSetters: ColorSetters = {
    bg_color: setBackgroundColor,
    text_color: setTextColor,
    button_bg_color: setButtonBackgroundColor,
    button_text_color: setButtonTextColor,
  };

  useEffect(() => {
    if (orgSettings) {
      setGroupCoverImageFile(orgSettings.company_logo);
      objectKeys(colorSetters).forEach(key => {
        const value = orgSettings[key] ?? '';
        if (value) {
          colorSetters[key](value);
        }
      });

      loadSettingsAfterExitingPreview();
    }
  }, [orgSettings]);

  useEffect(() => {
    if (location.state?.tab === 'group-template') {
      setSelectedIndex(1);
    }
  }, [location.state]);

  const loadSettingsAfterExitingPreview = async () => {
    const isFromPreviewPage =
      location?.state?.fromPage === GROUP_PREVIEW_PAGE_NAME;
    if (isFromPreviewPage) {
      objectKeys(colorSetters).forEach(key => {
        if (location.state[key]) {
          colorSetters[key](location.state[key]);
        }
      });

      if (location.state.company_logo_file_id && orgID) {
        const res = await filesAPI.showFile(
          orgID,
          Number(location.state.company_logo_file_id),
        );
        setGroupCoverImageFile(res.data.data);
      } else {
        setGroupCoverImageFile(undefined);
      }
    }
  };

  async function createGroupEditors(groupID: number) {
    const promises = editors.map(editor =>
      organisationsAPI.createGroupEditor(groupID, {
        editor: {
          role_id: editor.id,
        },
      }),
    );

    return Promise.all(promises);
  }

  async function createOrganisationGroup() {
    if (orgID === undefined) {
      throw new Error('No org ID');
    }

    if (groupName === '') {
      setGroupNameError(true);
      throw new Error('Group name cannot be blank');
    }

    const { data: group } = await organisationsAPI.createOrganisationGroup(
      orgID,
      {
        organisation_group: {
          name: groupName,
          description: subheading,
          bg_color: backgroundColor,
          text_color: textColor,
          button_bg_color: buttonBackgroundColor,
          button_text_color: buttonTextColor,
          group_logo_file_id: groupCoverImageFile
            ? groupCoverImageFile.id
            : null,
        },
      },
    );

    const groupID = group.data.id;

    await createGroupEditors(groupID);
    await profilesAPI.updateProfilesShared({
      orgID,
      body: {
        ids: Array.from(addedProfiles),
        profile: {
          group_id: groupID,
        },
      },
    });

    const invitePromises = invites.map(invite =>
      organisationsAPI.updateInvite(orgID, invite.id, [
        ...invite.group_ids,
        groupID,
      ]),
    );
    await Promise.all(invitePromises);

    const newManagerInvites = newGroupManagers.map(email =>
      organisationsAPI.inviteUser(orgID, {
        invite: {
          email,
          organisation_id: orgID,
          role: 'org_editor',
          group_ids: [groupID],
        },
      }),
    );
    await Promise.all(newManagerInvites);

    return group.data;
  }

  async function showOrganisationSettings() {
    if (orgID === undefined) {
      return;
    }

    const res = await organisationsAPI.showOrganisationSettings(orgID);

    return res.data.data;
  }

  const colors = [
    {
      name: 'Page background color',
      color: backgroundColor,
      setColor: setBackgroundColor,
    },
    {
      name: 'Text color',
      color: textColor,
      setColor: setTextColor,
    },
    {
      name: 'Button background color',
      color: buttonBackgroundColor,
      setColor: setButtonBackgroundColor,
    },
    {
      name: 'Button text color',
      color: buttonTextColor,
      setColor: setButtonTextColor,
    },
  ];

  return (
    <Layout pageName="Create Group" className="bg-gray-50">
      <Tab.Group
        defaultIndex={0}
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full border-b border-gray-200">
          <div className="w-full">
            <TabList tabs={tabs} value={tabs[selectedIndex].value} />
          </div>
        </div>
        <Tab.Panels>
          <Tab.Panel className="outline-none">
            <div className="mt-10 mb-14">
              <InfoPanelContainer
                title="Group Information"
                information="Add details to better identify your group."
              >
                <GroupInfoForm
                  groupName={groupName}
                  groupNameError={groupNameError}
                  subheading={subheading}
                  setGroupName={setGroupName}
                  setSubheading={setSubheading}
                />
              </InfoPanelContainer>
              <InfoPanelDivider />
              <InfoPanelContainer
                title="Select Profiles"
                information="Add profiles from your organisation to this group."
              >
                <div className="flex flex-col xl:flex-row justify-between gap-2">
                  <div className="flex flex-col text-gray-900 text-sm gap-1">
                    Number of profiles in group
                    <span className="text-gray-500">
                      {addedProfiles.size} profile
                      {addedProfiles.size === 1 ? '' : 's'}
                    </span>
                  </div>
                  <div className="flex justify-end space-x-4">
                    <Button
                      kind={BUTTON_KIND.PRIMARY}
                      buttonText="Add profiles"
                      onClick={() => setIsAddProfilesModalOpen(true)}
                    />
                  </div>
                </div>
                {profilesSuccess && <SuccessAlert message={profilesSuccess} />}
              </InfoPanelContainer>
              <InfoPanelDivider />
              <InfoPanelContainer
                title="Group Editing Access"
                information="These users will have access to the group and be able to manage it."
              >
                <GroupEditingAccess
                  groupID={undefined}
                  editors={editors}
                  createGroupInvites={invites}
                  newInvitees={newGroupManagers}
                  createGroupCallback={(users, invites, invitees) => {
                    setEditors(users);
                    setInvites(invites);
                    setNewGroupManagers(invitees);
                  }}
                />
              </InfoPanelContainer>
            </div>
          </Tab.Panel>
          <Tab.Panel className="outline-none">
            <div className="mt-10 mb-14">
              <InfoPanelContainer
                title="Profile Page Appearance"
                information="Choose an image to display at the top of profile pages of cardholders in this group. Choose custom colour to reflect your company's branding."
              >
                <div className="flex flex-col">
                  <span className="font-medium text-xl">Cover image</span>
                  <span className="text-gray-500 text-sm">
                    Choose an image to display at the top of digital profiles.
                  </span>
                </div>
                <div className="flex items-center pt-3">
                  <UploadPhoto
                    title="Cover image"
                    photo={groupCoverImageFile}
                    setPhoto={setGroupCoverImageFile}
                    loading={orgSettings === undefined}
                    size="large"
                    aspectRatio={16 / 11}
                    fileFormatMessage="Recommended dimensions 1024px x 704px"
                    maxHeight={COVER_IMAGE.MAX_HEIGHT}
                    maxWidth={COVER_IMAGE.MAX_WIDTH}
                  />
                </div>
                <div className="font-medium text-xl text-gray-900 py-4">
                  Colors
                  <p className="text-gray-500 text-sm font-normal pt-1">
                    Create a custom theme for cardholder profile pages. Maintain
                    good readability by ensuring there is sufficient contrast
                    between text and background colours.
                  </p>
                </div>
                <div className="flex flex-col xl:flex-row xl:items-end justify-between gap-6 xl:gap-0">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {colors.map((color, index) => (
                      <div key={index} className="col-span-1">
                        <p className="mb-2 text-sm leading-5 font-medium text-gray-900">
                          {color.name}
                        </p>
                        <ColorPicker
                          color={color.color}
                          setColor={color.setColor}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="self-end">
                    <Button
                      buttonText="Preview"
                      kind={BUTTON_KIND.PRIMARY}
                      icon={<EyeIcon />}
                      onClick={() => setIsPreviewOpen(true)}
                    />
                    <ProfilePreviewModal
                      isOpen={isPreviewOpen}
                      setIsOpen={setIsPreviewOpen}
                      settings={{
                        bgColor: backgroundColor,
                        textColor: textColor,
                        buttonBgColor: buttonBackgroundColor,
                        buttonTextColor: buttonTextColor,
                        companyLogo: groupCoverImageFile,
                      }}
                    />
                  </div>
                </div>
              </InfoPanelContainer>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            className="flex-1 xl:flex-none"
            buttonText="Discard changes"
            kind={BUTTON_KIND.WHITE}
            onClick={() => history.push('/groups')}
          />
          <Button
            className="flex-1 xl:flex-none"
            buttonText="Save & close"
            loading={isCreatingGroup}
            onClick={() => createGroup()}
          />
        </div>
      </InfoPanelFooter>
      <AddProfilesModal
        groupID={undefined}
        isOpen={isAddProfilesModalOpen}
        setIsOpen={setIsAddProfilesModalOpen}
        onSuccess={profileIDs => {
          setAddedProfiles(profileIDs);
          setProfilesSuccess(
            `Profile${
              profileIDs.size === 1 ? '' : 's'
            } have been added to the group.`,
          );
        }}
      />
    </Layout>
  );
}

export default CreateGroupPage;
