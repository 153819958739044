import { useCallback, useEffect, useState } from 'react';

import { UserIcon } from '@heroicons/react/24/outline';

import { AxiosResponse } from 'axios';

import ICollection from '@/types/ICollection';
import { IListPaging } from '@/types/IList';
import { IProfileBase } from '@/types/IProfile';

import SearchableInput, { Item } from '../SearchableInput';

export type Props = {
  fetchData?: (profileIds: number[]) => void;
  listProfiles: ({
    search,
    orgID,
  }: {
    search?: string;
    orgID?: number;
  }) => Promise<
    AxiosResponse<{
      data: IProfileBase[];
      paging: IListPaging;
    }>
  >;
  collection?: ICollection | { organisations: Item[]; profiles: Item[] };
};

const ProfileFilter: React.FC<Props> = ({
  fetchData,
  listProfiles,
  collection,
}) => {
  const [profiles, setProfiles] = useState<Item[] | undefined>(undefined);

  const mapProfilesToItems = useCallback(
    (remoteProfiles: IProfileBase[]): Item[] => {
      return remoteProfiles.map(({ id, first_name, last_name }) => {
        const title =
          first_name === null && last_name === null
            ? 'No name'
            : `${first_name?.trim() || ''} ${last_name?.trim() || ''}`;

        return {
          id,
          title,
        };
      });
    },
    [],
  );

  useEffect(() => {
    const getProfiles = async () => {
      const {
        data: { data: remoteProfiles },
      } = await listProfiles({});

      setProfiles(mapProfilesToItems(remoteProfiles));
    };

    getProfiles();
  }, [mapProfilesToItems, listProfiles]);

  const searchItems = async (query: string) => {
    const {
      data: { data: remoteProfiles },
    } = await listProfiles({ search: query });

    return mapProfilesToItems(remoteProfiles);
  };

  return (
    <SearchableInput
      key="profile-filter-analytics"
      icon={<UserIcon className="w-4 h-4 mr-2" strokeWidth={2} />}
      title="Profiles"
      items={profiles}
      selectedItems={collection?.profiles}
      fetchData={fetchData}
      searchItems={searchItems}
      emptyMessage="No profiles available"
    />
  );
};

export default ProfileFilter;
