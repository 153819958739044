import { FC } from 'react';

import { RouteComponentProps, useLocation } from 'react-router-dom';

interface IMatchParams {
  profile_hash_id: string;
  ref: string;
}

type ViewProfilePageType = RouteComponentProps<IMatchParams>;

const DEFAULT_PROFILE_LINK = 'https://profile.tapt.io';

const ViewProfilePage: FC<ViewProfilePageType> = ({
  match: {
    params: { profile_hash_id: profileHashID },
  },
}) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  window.location.replace(
    `${
      import.meta.env.VITE_PROFILE_LINK || DEFAULT_PROFILE_LINK
    }/profile/${profileHashID}?ref=${queryParams.get('ref') || ''}`,
  );

  return null;
};

export { ViewProfilePage as default };
