import { useState } from 'react';

import {
  ArrowDownTrayIcon as DownloadIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

import filesAPI from '@/api/files';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Badge, { BADGE_KIND } from '@/components/Badge';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button';
import Spinner from '@/components/Icons/Spinner';
import { MESSAGES } from '@/constants/messages-en';
import IDesign from '@/types/IDesign';
import IFile from '@/types/IFile';
import { Box } from '@material-ui/core';

interface ICardDesigns {
  selectedDesign: IDesign | undefined;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const CardDesigns: React.FC<ICardDesigns> = ({ selectedDesign, setStep }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState(false);

  const download = async (designFile: IFile) => {
    setIsDownloading(true);

    try {
      const response = await filesAPI.downloadFile(designFile.original_url);

      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response.data]));
      a.download = designFile.name;
      a.click();

      setSuccess(
        `File ${designFile?.file?.file_name} downloaded successfully!`,
      );
    } catch (error) {
      setError(true);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <Box className="border border-gray-200 rounded-lg">
        <Box
          className="w-full flex justify-center items-center border-b border-gray-100"
          style={{ height: '32px' }}
        >
          <Box className="min-w-0 flex-1 grid grid-cols-12 text-sm leading-5 font-medium text-gray-900">
            <Box className="col-span-6 lg:col-span-4 xl:col-span-2 flex justify-start pl-3">
              Order
            </Box>

            <Box className="col-span-6 ml-3 lg:col-span-4 xl:col-span-7 flex justify-start">
              File name
            </Box>
            <Box className="col-span-1 hidden lg:flex justify-center items-center">
              View
            </Box>
            <Box className="col-span-3 hidden lg:flex xl:col-span-2 justify-center items-center">
              Download
            </Box>
          </Box>
        </Box>
        <Box
          className="w-full flex border-b border-gray-100"
          sx={{ height: '53px' }}
        >
          <Box className="min-w-0 flex-1 grid grid-cols-12 text-sm leading-5 font-normal">
            <Box className="col-span-6 lg:col-span-4 xl:col-span-2  flex justify-start items-center pl-3">
              <Badge
                text={
                  selectedDesign?.order
                    ? `Order #${selectedDesign.order.order_number}`
                    : 'In progress'
                }
                kind={selectedDesign?.order ? BADGE_KIND.GRAY : BADGE_KIND.AMBER}
              />
            </Box>
            <Box className="col-span-6 flex justify-start items-center ml-3 text-gray-500 lg:col-span-4 xl:col-span-7">
              <p className="truncate">{selectedDesign?.design_file?.name}</p>
            </Box>
            <Box className="col-span-1  hidden  justify-center items-center lg:flex">
              <EyeIcon
                className="stroke-1.3 text-gray-500 group-hover:text-brand-400 cursor-pointer"
                style={{ width: '24px', height: '24px' }}
                onClick={() =>
                  window.open(
                    `${import.meta.env.VITE_API_BASE_URL}${
                      selectedDesign?.design_file?.original_url
                    }`,
                    '_blank',
                  )
                }
              />
            </Box>
            <Box className="col-span-3 hidden justify-center items-center lg:flex xl:col-span-2 ">
              {!isDownloading ? (
                selectedDesign?.design_file && (
                  <DownloadIcon
                    className="cursor-pointer"
                    width="24"
                    height="24"
                    color="#9CA3AF"
                    onClick={() => {
                      selectedDesign?.design_file &&
                        download(selectedDesign.design_file);
                    }}
                  />
                )
              ) : (
                <Spinner />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="flex items-center justify-end gap-3 mt-5">
        <Button
          buttonText="Change template"
          onClick={() => setStep(0)}
          kind={BUTTON_KIND.PRIMARY}
          size={BUTTON_SIZE.XSMALL}
        />
      </Box>
    </>
  );
};

export default CardDesigns;
