import { format } from 'date-fns';

import { ICardAdmin } from '@/types/ICard';

const CreatedCell: React.FC<{ card: ICardAdmin }> = ({ card }) => {
  const insertedAt = new Date(card.inserted_at);
  return (
    <td className="p-4 pb-3">
      <p className="text-sm text-gray-900">{format(insertedAt, 'dd/MM/yy')}</p>
      <p className="text-xs text-gray-500 font-medium">
        {insertedAt.toLocaleTimeString()}
      </p>
    </td>
  );
};

export default CreatedCell;
