import filesAPI from '@/api/files';

export const downloadFile = async (url: string, file_name: string) => {
  try {
    const response = await filesAPI.downloadFile(url);

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response.data]));
    a.download = file_name;
    a.click();
  } catch (error) {
    // setError(true);
  }
};
