import { Fragment, ReactNode } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

import { ICardAdmin } from '@/types/ICard';

const ActionsMenu: React.FC<{ card: ICardAdmin; children: ReactNode }> = ({
  children,
}) => {
  return (
    <td className="font-medium p-4 pb-3 w-20">
      <div className="flex items-center justify-center">
        <Menu as="div" className="relative inline-block">
          <div className="flex items-center">
            <Menu.Button className="appearance-none">
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="py-1">{children}</div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </td>
  );
};

export default ActionsMenu;
