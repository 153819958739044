import clsx from 'clsx';

export interface StepsProps {
  currentStep: number;
  totalSteps: number;
}
export default function Steps({ currentStep, totalSteps }: StepsProps) {
  return (
    <>
      <nav
        className="flex items-center justify-center pb-6"
        aria-label="Progress"
      >
        <p className="text-xs font-normal leading-4 text-gray-500">
          Step {currentStep} of {totalSteps}
        </p>
        <ol className="flex items-center ml-8 space-x-5">
          {Array.from(Array(totalSteps).keys()).map((i) => {
            if (currentStep === i + 1) {
              return (
                <li key={i}>
                  {/* Current Step */}
                  <span className="relative flex items-center justify-center">
                    <span
                      className="absolute flex w-5 h-5 p-px"
                      aria-hidden="true"
                    >
                      <span className="w-full h-full rounded-full bg-brand-200" />
                    </span>
                    <span
                      className="relative block h-2.5 w-2.5 rounded-full bg-brand-500"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Step {i}</span>
                  </span>
                </li>
              );
            }
            return (
              <li key={i}>
                {/* Completed Step */}
                <span
                  className={clsx(
                    'block h-2.5 w-2.5 rounded-full',
                    i < currentStep
                      ? 'bg-brand-600'
                      : 'bg-gray-200 hover:bg-gray-400',
                  )}
                >
                  <span className="sr-only">Step {i}</span>
                </span>
              </li>
            );
          })}
        </ol>
      </nav>
    </>
  );
}
