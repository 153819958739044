import IOrder from '@/types/IOrderV2';
import React from 'react';

type Props = {
  designDetails: IOrder['design_details'];
};

function renderDesignDetail(index: number, key: string, value: string) {
  if (key === 'own_design_file_url') {
    const filename = value.split('/').pop();
    return (
      <div key={index} className="text-xs mb-1">
        <span className="font-medium">Own design: </span>
        <a href={value} target="_blank" rel="noopener noreferrer">
          {filename}
        </a>
      </div>
    );
  }

  return (
    <div key={index} className="text-xs mb-1">
      <span className="font-medium">{key}: </span>
      {value}
    </div>
  );
}

const CardDesignDetails: React.FC<Props> = ({ designDetails }) => {
  if (!designDetails) return null;

  return (
    <div className="max-w-fit bg-brand-50 rounded-lg px-4 py-6 mb-3">
      <div className="text-gray-700 text-xs font-semibold uppercase">
        Custom card design
      </div>
      <div className="mt-6">
        {Object.keys(designDetails).map((detailKey, index) =>
          renderDesignDetail(index, detailKey, designDetails[detailKey]),
        )}
      </div>
    </div>
  );
};

export default CardDesignDetails;
