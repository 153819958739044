import { useCallback, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import cardsAPI from '@/api/cards';
import { ActivationPageAnimation } from '@/components/ActivationPageAnimation';
import Button, { BUTTON_KIND } from '@/components/Button';
import LoadingAnimation from '@/components/LoadingAnimation';
import { AppStoreLink, PlayStoreLink } from '@/constants/apps';
import isIOS from '@/helpers/isIos';
import searchParamsQuery from '@/helpers/urlHandler';
import { ICardAdmin } from '@/types/ICard';

interface IActivationPage {
  location: {
    search: string;
  };
  match: {
    params: {
      card_hash_id: string;
    };
  };
}
const NewActivationPage: React.FC<IActivationPage> = ({ location, match }) => {
  const [appLink, setAppLink] = useState<string>('/');
  const cardHashID = match.params.card_hash_id;
  const { key } = searchParamsQuery(location.search);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cardData, setCardData] = useState<ICardAdmin>();
  useEffect(() => {
    setAppLink(isIOS() ? AppStoreLink : PlayStoreLink);
  }, []);

  const getCardData = useCallback(() => {
    cardsAPI
      .showCardWithHashID(cardHashID)
      .then(response => {
        const cardDataResponse = response?.data?.data;
        if (cardDataResponse) {
          setCardData(cardDataResponse);
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [cardHashID]);

  useEffect(() => {
    getCardData();
  }, [getCardData]);

  if (cardData) {
    const { status, profile } = cardData;

    if (status === 'connected' && profile && profile.profile_hash) {
      // Log view from card
      return <Redirect to={`/view-profile/${profile.profile_hash}?ref=card`} />;
    }
  }

  if (!isLoading && !key) {
    return <Redirect to="/" />;
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <div className="py-32">
          <LoadingAnimation className="h-16 w-16 text-brand-500 mx-auto" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex flex-col items-center justify-center extra-small-screen:max-w-full sm:max-w-[23.438rem] w-full  pt-[4.375rem] px-6 pb-8 extra-small-screen:h-full">
        <h2 className="text-xl font-normal leading-8 text-center text-gray-900">
          Log in or create an account <br />
          <span className="text-2xl font-medium">Via the </span>
          <span className="text-2xl font-medium text-brand-500">tapt app</span>
        </h2>
        {/* <div className="max-h-[23.5rem] h-[18rem] sm:h-[23.5rem] mt-10 mb-16" /> */}
        <ActivationPageAnimation />
        <div className="flex justify-center w-full">
          <Button
            buttonText="Download the tapt app!"
            kind={BUTTON_KIND.CIRCULAR}
            onClick={() => {
              window.open(appLink, '_blank');
            }}
          />
        </div>
        <div className="flex justify-center w-full mt-[2.938rem]">
          <Link
            to={{
              pathname: '/sign-up',
              state: {
                activationKey: key,
              },
            }}
            className="text-xs font-normal leading-8 text-center text-gray-900 underline"
          >
            Continue via the web instead
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewActivationPage;
