import { useState, useEffect, useCallback } from 'react';
import Layout from '@/components/Layout';
import { IProfileQuickFileLink, IProfileSocialLink } from '@/types/IProfile';
import IFile from '@/types/IFile';
import adminAPI from '@/api/admin';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import EditSocialLink from '@/components/EditSocialLink';
import EditQuickLink from '@/components/EditQuickLink';
import EditFileLink from '@/components/EditFileLink';
import ToggleField from '@/components/ToggleField';
import CardDesign from '@/components/CardDesign';
import DesignTemplate from '@/assets/documents/Tapt Design Template.pdf';
import PersonalInfoForm from '@/containers/PersonalInfoForm';
import CompanyInfoForm from '@/containers/CompanyInfoForm';
import MESSAGES from '@/constants/messages-en';

export default function EditProfileAdminPage({
  location,
  match,
}: {
  location: {
    state: {
      fromName: string;
      fromURL: string;
    };
  };
  match: { params: { profile_id: number } };
}): JSX.Element {
  const locationState = location?.state;
  const profileID = match.params.profile_id;

  const [photo, setPhoto] = useState<IFile>();
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [email, setEmail] = useState('');
  const [extraEmails, setExtraEmails] = useState<string[]>([]);
  const [mobileNumber, setMobileNumber] = useState('');
  const [extraMobileNumbers, setExtraMobileNumbers] = useState<string[]>([]);
  const [jobTitle, setJobTitle] = useState('');
  const [note, setNote] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  const [extraCompanyPhoneNumbers, setExtraCompanyPhoneNumbers] = useState<
    string[]
  >([]);
  const [country, setCountry] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateProvince, setStateProvince] = useState('');
  const [postCode, setPostCode] = useState('');
  const [profileSocialLinks, setProfileSocialLinks] = useState<
    IProfileSocialLink[]
  >([]);
  const [profileQuickFileLinks, setProfileQuickFileLinks] = useState<
    IProfileQuickFileLink[]
  >([]);
  const [nameToggle, setNameToggle] = useState<boolean>(false);
  const [companyNameToggle, setCompanyNameToggle] = useState<boolean>(false);
  const [jobTitleToggle, setJobTitleToggle] = useState<boolean>(false);
  const [mobileNumberToggle, setMobileNumberToggle] = useState<boolean>(false);
  const [companyPhoneNumberToggle, setCompanyPhoneNumberToggle] =
    useState<boolean>(false);
  const [emailToggle, setEmailToggle] = useState<boolean>(false);
  const [companyWebsiteToggle, setCompanyWebsiteToggle] =
    useState<boolean>(false);
  const [streetAddressToggle, setStreetAddressToggle] =
    useState<boolean>(false);
  const [primaryC, setPrimaryC] = useState<string>('');
  const [primaryM, setPrimaryM] = useState<string>('');
  const [primaryY, setPrimaryY] = useState<string>('');
  const [primaryK, setPrimaryK] = useState<string>('');
  const [secondaryC, setSecondaryC] = useState<string>('');
  const [secondaryM, setSecondaryM] = useState<string>('');
  const [secondaryY, setSecondaryY] = useState<string>('');
  const [secondaryK, setSecondaryK] = useState<string>('');
  const [designFile, setDesignFile] = useState<IFile>();
  const [logoFile, setLogoFile] = useState<IFile>();
  const [cardDesign, setCardDesign] = useState<number>(0);
  const [contactExchangeEnabled, setContactExchangeEnabled] =
    useState<boolean>(false);
  const [cardholderEditingEnabled, setCardholderEditingEnabled] =
    useState<boolean>(false);
  const [designNote, setDesignNote] = useState<string>('');
  const [locationLatLng, setLocationLatLng] =
    useState<google.maps.LatLngLiteral>();
  const [mapEnabled, setMapEnabled] = useState(false);

  const showProfile = useCallback(() => {
    adminAPI
      .showProfile(profileID)
      .then((res) => {
        const data = res?.data;
        const { design } = data?.data;
        if (data?.data?.first_name) setFirstName(data.data.first_name);
        if (data?.data?.last_name) setLastName(data.data.last_name);
        if (data?.data?.middle_name) setMiddleName(data.data.middle_name);
        if (data?.data?.email) setEmail(data.data.email);
        if (data?.data?.extra_emails) setExtraEmails(data.data.extra_emails);
        if (data?.data?.mobile_number) setMobileNumber(data.data.mobile_number);
        if (data?.data?.extra_mobile_numbers)
          setExtraMobileNumbers(data.data.extra_mobile_numbers);
        if (data?.data?.job_title) setJobTitle(data.data.job_title);
        if (data?.data?.note) setNote(data.data.note);
        if (data?.data?.company_name) setCompanyName(data.data.company_name);
        if (data?.data?.company_website)
          setCompanyWebsite(data.data.company_website);
        if (data?.data?.company_phone_number)
          setCompanyPhoneNumber(data.data.company_phone_number);
        if (data?.data?.extra_company_phone_numbers)
          setExtraCompanyPhoneNumbers(data.data.extra_company_phone_numbers);
        if (data?.data?.country) setCountry(data.data.country);
        if (data?.data?.street_address)
          setStreetAddress(data.data.street_address);
        if (data?.data?.city) setCity(data.data.city);
        if (data?.data?.state) setStateProvince(data.data.state);
        if (data?.data?.post_code) setPostCode(data.data.post_code);
        if (data?.data?.photo) setPhoto(data.data.photo);
        if (data?.data?.contact_exchange_enabled)
          setContactExchangeEnabled(data.data.contact_exchange_enabled);
        if (data?.data?.status === 'editing') setCardholderEditingEnabled(true);
        if (data?.data?.profile_social_links?.length > 0)
          setProfileSocialLinks(
            data.data.profile_social_links.map((item, index) => ({
              ...item,
              order: index,
            })),
          );
        if (data?.data?.location)
          setLocationLatLng({
            lat: data.data.location[0],
            lng: data.data.location[1],
          });
        setMapEnabled(data?.data?.map_enabled);

        let newLinksArray: IProfileQuickFileLink[] = [];
        if (data?.data?.profile_quick_links?.length > 0) {
          newLinksArray = data.data.profile_quick_links;
        }

        if (data?.data?.profile_file_links?.length > 0) {
          newLinksArray = [...newLinksArray, ...data.data.profile_file_links];
        }

        newLinksArray.sort(
          (a: IProfileQuickFileLink, b: IProfileQuickFileLink) => {
            return a.order - b.order;
          },
        );
        setProfileQuickFileLinks(
          newLinksArray.map((item: IProfileQuickFileLink, index: number) => ({
            ...item,
            order: index,
          })),
        );

        if (design) {
          if (design.design_file) setDesignFile(design.design_file);
          if (design.primary_color)
            setPrimaryC(design.primary_color[0].toString());
          if (design.primary_color)
            setPrimaryM(design.primary_color[1].toString());
          if (design.primary_color)
            setPrimaryY(design.primary_color[2].toString());
          if (design.primary_color)
            setPrimaryK(design.primary_color[3].toString());
          if (design.secondary_color)
            setSecondaryC(design.secondary_color[0].toString());
          if (design.secondary_color)
            setSecondaryM(design.secondary_color[1].toString());
          if (design.secondary_color)
            setSecondaryY(design.secondary_color[2].toString());
          if (design.secondary_color)
            setSecondaryK(design.secondary_color[3].toString());
          if (design.flags.name) setNameToggle(design.flags.name);
          if (design.flags.email) setEmailToggle(design.flags.email);
          if (design.flags.company_name)
            setCompanyNameToggle(design.flags.company_name);
          if (design.flags.job_title) setJobTitleToggle(design.flags.job_title);
          if (design.flags.mobile_number)
            setMobileNumberToggle(design.flags.mobile_number);
          if (design.flags.company_phone_number)
            setCompanyPhoneNumberToggle(design.flags.company_phone_number);
          if (design.flags.company_website)
            setCompanyWebsiteToggle(design.flags.company_website);
          if (design.flags.street_address)
            setStreetAddressToggle(design.flags.street_address);
          if (design.description) setDesignNote(design.description);
          if (design.logo_file) setLogoFile(design.logo_file);

          if (
            !design.design_file &&
            (design.logo_file || design.primary_color || design.secondary_color)
          )
            setCardDesign(2);
        } else {
          setCardDesign(1);
        }
      })
      .finally(() => {});
  }, [profileID]);

  useEffect(() => {
    showProfile();
  }, [showProfile]);

  return (
    <Layout
      pageName="Edit Profile"
      description={MESSAGES.profile.edit.heading}
      location={locationState}
    >
      <InfoPanelContainer
        title="Personal Information"
        information={MESSAGES.profile.edit.personalInfo}
      >
        <PersonalInfoForm
          photo={photo}
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          pronouns={pronouns}
          email={email}
          extraEmails={extraEmails}
          mobileNumber={mobileNumber}
          extraMobileNumbers={extraMobileNumbers}
          jobTitle={jobTitle}
          note={note}
          setPhoto={setPhoto}
          setFirstName={setFirstName}
          setMiddleName={setMiddleName}
          setLastName={setLastName}
          setPronouns={setPronouns}
          setEmail={setEmail}
          setExtraEmails={setExtraEmails}
          setMobileNumber={setMobileNumber}
          setExtraMobileNumbers={setExtraMobileNumbers}
          setJobTitle={setJobTitle}
          setNote={setNote}
          disabled
        />
      </InfoPanelContainer>

      {profileSocialLinks.length > 0 && (
        <>
          <InfoPanelDivider />

          <InfoPanelContainer
            title="Social Media Links"
            information={MESSAGES.profile.edit.social}
          >
            <ul
              key={`ProfileSocialLinks-${profileSocialLinks.length}`}
              className="space-y-3 pb-3"
            >
              {profileSocialLinks.map((socialLink, index) => (
                <EditSocialLink
                  key={`SocialLink-${socialLink.order}`}
                  setProfileSocialLinks={setProfileSocialLinks}
                  profileSocialLinks={profileSocialLinks}
                  socialLink={socialLink}
                  index={index}
                  disabled
                />
              ))}
            </ul>
          </InfoPanelContainer>
        </>
      )}

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Company Information"
        information="Information about your company"
        hint={MESSAGES.profile.edit.company}
      >
        <CompanyInfoForm
          companyName={companyName}
          companyWebsite={companyWebsite}
          companyPhoneNumber={companyPhoneNumber}
          extraCompanyPhoneNumbers={extraCompanyPhoneNumbers}
          country={country}
          streetAddress={streetAddress}
          city={city}
          stateProvince={stateProvince}
          postCode={postCode}
          locationLatLng={locationLatLng}
          mapEnabled={mapEnabled}
          setCompanyName={setCompanyName}
          setCompanyWebsite={setCompanyWebsite}
          setCompanyPhoneNumber={setCompanyPhoneNumber}
          setExtraCompanyPhoneNumbers={setExtraCompanyPhoneNumbers}
          setCountry={setCountry}
          setStreetAddress={setStreetAddress}
          setCity={setCity}
          setStateProvince={setStateProvince}
          setPostCode={setPostCode}
          setLocationLatLng={setLocationLatLng}
          setMapEnabled={setMapEnabled}
          disabled
        />
      </InfoPanelContainer>

      {profileQuickFileLinks?.length > 0 && (
        <>
          <InfoPanelDivider />

          <InfoPanelContainer
            title="Quick Links and Files"
            information={MESSAGES.profile.edit.links.heading}
            hint={MESSAGES.profile.edit.links.description}
          >
            {profileQuickFileLinks?.length > 0 && (
              <ul
                key={`ProfileQuickFileLinks-${profileQuickFileLinks.length}`}
                className="space-y-3 pb-3"
              >
                {profileQuickFileLinks.map((quickFileLink, index) => {
                  if (quickFileLink.file_id) {
                    return (
                      <EditFileLink
                        key={`FileLink-${quickFileLink.order}`}
                        orgID={-1}
                        setProfileFileLinks={setProfileQuickFileLinks}
                        profileFileLinks={profileQuickFileLinks}
                        fileLink={quickFileLink}
                        index={index}
                        disabled
                      />
                    );
                  }
                  return (
                    <EditQuickLink
                      key={`QuickLink-${quickFileLink.order}`}
                      setProfileQuickLinks={setProfileQuickFileLinks}
                      profileQuickLinks={profileQuickFileLinks}
                      quickLink={quickFileLink}
                      index={index}
                      disabled
                    />
                  );
                })}
              </ul>
            )}
          </InfoPanelContainer>
        </>
      )}

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Contact exchange form"
        information={MESSAGES.profile.edit.contact}
      >
        <ToggleField
          label="Enable form"
          enabled={contactExchangeEnabled}
          setter={setContactExchangeEnabled}
          description="Choose whether you would like to recieve contact details from people."
          disabled
        />
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Card Design"
        information={
          <span>
            Choose how you would like to design the card which belongs to this
            profile.
            <br />
            <br />
            <b>Note</b>: Tapt will always email design proofs before we start
            printing.
            <br />
            <br />
            If you would like to have a go at designing your own card template,
            download this Adobe Illustrator file:{' '}
            <a href={DesignTemplate} target="_blank" rel="noopener noreferrer">
              Download card design template.
            </a>
          </span>
        }
        isRadio
      >
        <CardDesign
          orgID={-1}
          primaryC={primaryC}
          setPrimaryC={setPrimaryC}
          primaryM={primaryM}
          setPrimaryM={setPrimaryM}
          primaryY={primaryY}
          setPrimaryY={setPrimaryY}
          primaryK={primaryK}
          setPrimaryK={setPrimaryK}
          secondaryC={secondaryC}
          setSecondaryC={setSecondaryC}
          secondaryM={secondaryM}
          setSecondaryM={setSecondaryM}
          secondaryY={secondaryY}
          setSecondaryY={setSecondaryY}
          secondaryK={secondaryK}
          setSecondaryK={setSecondaryK}
          designFile={designFile}
          setDesignFile={setDesignFile}
          logoFile={logoFile}
          setLogoFile={setLogoFile}
          cardDesign={cardDesign}
          setCardDesign={setCardDesign}
          designNote={designNote}
          setDesignNote={setDesignNote}
          disabled
        />
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Variable fields"
        information={MESSAGES.profile.edit.print}
      >
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="mb-4">
            <ToggleField
              label="First and last Name"
              enabled={nameToggle}
              setter={setNameToggle}
              description="e.g. John Smith"
              disabled
            />
          </div>
          <div className="mb-4">
            <ToggleField
              label="Company name"
              enabled={companyNameToggle}
              setter={setCompanyNameToggle}
              description="e.g. ACME Inc."
              disabled
            />
          </div>
          <div className="mb-4">
            <ToggleField
              label="Job title/Position"
              enabled={jobTitleToggle}
              setter={setJobTitleToggle}
              description="e.g. Associate Consultant"
              disabled
            />
          </div>
          <div className="mb-4">
            <ToggleField
              label="Company website"
              enabled={companyWebsiteToggle}
              setter={setCompanyWebsiteToggle}
              description="e.g. ACME.com"
              disabled
            />
          </div>
          <div className="mb-4">
            <ToggleField
              label="Mobile number"
              enabled={mobileNumberToggle}
              setter={setMobileNumberToggle}
              description="e.g. 0400 111 222"
              disabled
            />
          </div>
          <div className="mb-4">
            <ToggleField
              label="Company phone number"
              enabled={companyPhoneNumberToggle}
              setter={setCompanyPhoneNumberToggle}
              description="e.g. (03) 9800 5800"
              disabled
            />
          </div>
          <div className="mb-4">
            <ToggleField
              label="Email address"
              enabled={emailToggle}
              setter={setEmailToggle}
              description="e.g. John.Smith@ACME.com"
              disabled
            />
          </div>
          <div className="">
            <ToggleField
              label="Company street address"
              enabled={streetAddressToggle}
              setter={setStreetAddressToggle}
              description="e.g. 123 Collins Street, Melbourne, 3000"
              disabled
            />
          </div>
        </div>
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Enable cardholder editing"
        information={MESSAGES.profile.edit.enable.heading}
      >
        <ToggleField
          label="Enable cardholder editing"
          enabled={cardholderEditingEnabled}
          setter={setCardholderEditingEnabled}
          description={MESSAGES.profile.edit.enable.description}
          disabled
        />
      </InfoPanelContainer>
    </Layout>
  );
}
