import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';

import { Transition } from '@headlessui/react';

import hexToRgba from 'hex-to-rgba';
import isDarkColor from 'is-dark-color';

import filesAPI from '@/api/files';
import defaultBackgroundImageUrl from '@/assets/svg/ProfilePageDefaultHeaderImage.svg';
import LoadingAnimation from '@/components/LoadingAnimation';
import ModalStandalone from '@/components/Modals/ModalStandalone';
import NavbarMinimal from '@/components/NavbarMinimal';
import {
  ProfilePageDetails,
  ProfilePageFooter,
  ProfilePageShareDetails,
} from '@/components/ProfilePage';
import ProfilePageAddToContactsButton from '@/components/ProfilePage/ProfilePageAddToContactsButton';
import {
  defaultProfileData,
  headerMenuData,
  loadingOrganisationSettings,
  mockOrganisationData,
  mockProfileData,
} from '@/components/ProfilePage/data';
import COLORS from '@/constants/colors';
import searchParamsQuery from '@/helpers/urlHandler';
import useProgressiveImg from '@/hooks/useProgressiveImg';
import { IOrganisationSettings } from '@/types/IOrganisation';
import {
  IProfileFileLink,
  IProfilePublic,
  IProfileQuickLink,
} from '@/types/IProfile';
import { css, cx } from '@emotion/css';

interface IMatchParams {
  profile_hash_id: string;
}

type ViewProfilePageType = RouteComponentProps<IMatchParams>;

const HEADER_IMAGE_ASPECT_RATIO = 11 / 16;

const SampleProfilePage: React.FC<ViewProfilePageType> = props => {
  const { location } = props;

  const profileHashID = 'sample';

  const [isLoading, setIsLoading] = useState(true);
  const [profileData, setProfileData] =
    useState<IProfilePublic>(defaultProfileData);
  const [organisationData, setOrganisationData] =
    useState<IOrganisationSettings>(loadingOrganisationSettings);
  const [isShareDetailsOpen, setIsShareDetailsOpen] = useState(false);

  const {
    city,
    company_name: companyName,
    company_phone_number: companyPhoneNumber,
    company_website: companyWebsite,
    contact_exchange_enabled: contactExchangeEnabled,
    country,
    email,
    first_name: firstName,
    job_title: jobTitle,
    last_name: lastName,
    middle_name: middleName,
    pronouns,
    mobile_number: mobileNumber,
    note,
    photo,
    post_code: postCode,
    profile_file_links: profileFileLinks,
    profile_quick_links: profileQuickLinks,
    profile_social_links: profileSocialLinks,
    state,
    status,
    street_address: streetAddress,
    vcf_url: vcfUrl,
  } = profileData;

  const {
    bg_color: backgroundColor = COLORS.organisation.defaultProfile
      .backgroundColor,
    button_bg_color: buttonBackgroundColor = COLORS.organisation.defaultProfile
      .buttonBackgroundColor,
    button_text_color: buttonTextColor = COLORS.organisation.defaultProfile
      .buttonTextColor,
    company_logo: headerImage,
    company_consent_file: consentStatement,
    company_consent_message: consentMessage,
    company_logo_default: companyLogoDefault,
    contact_exchange: contactExchange,
    text_color: textColor = COLORS.organisation.defaultProfile.textColor,
  } = organisationData;

  const FOOTER_TEXT_COLOR_LIGHT = COLORS.profile.footer.colorTextLight;
  const FOOTER_TEXT_COLOR_DARK = COLORS.profile.footer.colorTextDark;

  const footerTextColor = isDarkColor(backgroundColor)
    ? FOOTER_TEXT_COLOR_LIGHT
    : FOOTER_TEXT_COLOR_DARK;

  const quickLinks = [...profileFileLinks, ...profileQuickLinks].map(link => {
    const {
      file,
      order,
      title: label,
      value,
    } = link as Partial<IProfileFileLink & IProfileQuickLink>;
    const linkType: 'file' | 'link' = file ? 'file' : 'link';
    return {
      href: value || file?.original_url || '',
      label: label || '',
      linkType,
      order: order && order >= 0 ? order : -1,
    };
  });

  const companyLogoBlur = headerImage?.medium_url;
  const headerImageMedium = headerImage?.medium_url;
  const [companyLogoUrl, { blur }] = useProgressiveImg(
    companyLogoBlur as string,
    headerImageMedium as string,
  );
  const hasHeaderImage = !!(headerImage?.medium_url || companyLogoDefault);
  const headerImageSrc = companyLogoDefault
    ? defaultBackgroundImageUrl
    : companyLogoUrl || defaultBackgroundImageUrl;

  const profileImageUrl = photo?.thumb_url
    ? photo?.thumb_url
    : photo?.small_url;

  const styles = {
    background: css`
      background-color: ${backgroundColor};
    `,
    card: css`
      background: ${backgroundColor};
      color: ${textColor};
      height: calc(100% + 3.5rem);
    `,
    gradient: css`
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.1) 100%
      );
    `,
    button: css`
      background: ${buttonBackgroundColor};
      color: ${buttonTextColor};
      &:hover {
        background: ${hexToRgba(buttonBackgroundColor, '0.8')};
        color: ${buttonTextColor};
      }
    `,
    textColor: css`
      color: ${textColor};
    `,
    headerImageOffset: css`
      padding-top: calc(${HEADER_IMAGE_ASPECT_RATIO * 100}vw - 30px);
      // TODO: Improvement - replace media query value with Tailwind config reference
      @media (min-width: 1024px) {
        padding-top: calc((1024px * ${HEADER_IMAGE_ASPECT_RATIO}) - 30px);
      }
    `,
  };

  const getProfileData = useCallback(() => {
    setIsLoading(true);
    setProfileData(mockProfileData);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  useEffect(() => {
    const { hash, search } = location;
    const queryParams = searchParamsQuery(search + hash);

    setOrganisationData(prev => ({
      ...prev,
      bg_color: queryParams.bg_color,
      button_bg_color: queryParams.button_bg_color,
      button_text_color: queryParams.button_text_color,
      text_color: queryParams.text_color,
      contact_exchange: mockOrganisationData.contact_exchange,
      is_contact_exchange_sms_enabled:
        mockOrganisationData.is_contact_exchange_sms_enabled,
      is_cardholder_editing_enabled:
        mockOrganisationData.is_cardholder_editing_enabled,
    }));

    if (queryParams.company_logo_file_id) {
      filesAPI
        .showFile(
          Number(queryParams.organisation_id),
          Number(queryParams.company_logo_file_id),
        )
        .then(res => {
          setOrganisationData(prev => ({
            ...prev,
            company_logo: res.data.data,
            company_logo_default: false,
          }));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setOrganisationData(prev => ({
        ...prev,
        company_logo_default: true,
      }));
    }

    if (queryParams.profile_generator_params_base64) {
      const params = JSON.parse(
        window.atob(queryParams.profile_generator_params_base64),
      );
      setOrganisationData(prev => ({
        ...prev,
        company_logo: JSON.parse(params.company_logo_json),
        company_logo_default: false,
        button_bg_color: params.button_bg_color,
      }));

      setProfileData(prev => ({
        ...prev,
        first_name: params.first_name,
        middle_name: params.middle_name,
        last_name: params.last_name,
        pronouns: params.pronouns,
        email: params.email,
        mobile_number: params.mobile_number,
        job_title: params.job_title,
        company_name: params.company_name,
        company_website: params.company_website,
        note: params.note,
        profile_social_links: JSON.parse(params.profile_social_links_json),
        profile_quick_links: [],
        profile_file_links: [],
        company_phone_number: '',
      }));
    }
  }, [location]);

  return (
    <div className={styles.background}>
      <Helmet>
        <meta name="robots" content="noindex" />

        {/* <!-- Facebook Pixel Code --> */}
        <script>
          {`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '921502218298727');fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=921502218298727&ev=PageView&noscript=1" />`}
        </noscript>
        {/* <!-- End Facebook Pixel Code --> */}

        {/* <!-- LinkedIn Insight Tag --> */}
        <script type="text/javascript">
          _linkedin_partner_id = &quot;3439836&quot;;
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids
          || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        </script>
        <script type="text/javascript">
          {`(function(l) {if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};window.lintrk.q=[]}var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})(window.lintrk);`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3439836&fmt=gif" />`}
        </noscript>
        {/* <!-- End LinkedIn Insight Tag --> */}
      </Helmet>
      <div className="bg-black bg-opacity-10">
        <div
          className={cx(
            styles.background,
            'max-w-screen-lg m-auto min-h-screen flex flex-col overflow-hidden',
          )}
        >
          <div className="fixed w-full max-w-screen-lg m-auto z-10">
            <Transition
              enter="transition-opacity duration-75 delay-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              show={!isLoading}
            >
              <NavbarMinimal
                menuData={headerMenuData}
                profileHashID={profileHashID}
              />
            </Transition>
          </div>
          {hasHeaderImage && (
            <Transition
              enter="transition-opacity duration-300 delay-700"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              show={!isLoading}
            >
              <div
                className={cx(
                  'fixed max-w-screen-lg m-auto w-full pointer-events-none',
                  'mt-4overflow-hidden bg-center bg-contain bg-no-repeat',
                )}
              >
                <img
                  className="w-full"
                  src={headerImageSrc}
                  alt=""
                  style={{
                    filter: blur ? 'blur(20px)' : 'none',
                    transition: blur ? 'none' : 'filter 0.3s ease-out',
                  }}
                />
              </div>
            </Transition>
          )}
          <div
            className={cx(
              { [styles.headerImageOffset]: hasHeaderImage },
              { 'pt-36': !hasHeaderImage },
              'flex flex-col min-h-screen',
              css(`
                @supports (-webkit-touch-callout: none) {
                  min-height: -webkit-fill-available;
                }
              `),
            )}
          >
            {isLoading && (
              <div className="absolute left-1/2 top-1/2 -ml-2.5 -mt-2.5 mr-2 bg-white rounded-full bg-opacity-50">
                <LoadingAnimation className="h-16 w-16 text-brand-500 mx-auto" />
              </div>
            )}
            <Transition
              enter="transition duration-300 transform delay-150"
              enterFrom="opacity-0 translate-y-1/4"
              enterTo="opacity-100 translate-y-0"
              show={!isLoading}
            >
              <div className="relative">
                <div
                  className={cx(
                    styles.gradient,
                    'absolute -top-14 h-28 inset-x-0 w-full',
                  )}
                />
                <div
                  className={cx(
                    styles.card,
                    'rounded-tr-4xl sticky top-0 transform shadow flex-auto',
                  )}
                >
                  <div className="transform -translate-y-14 -mb-14 mx-4 md:mx-12">
                    <div className="mb-14">
                      <ProfilePageDetails
                        addToContactsButton={() => (
                          <button
                            className="appearance-none"
                            type="button"
                            onClick={() => {
                              ReactGA.event({
                                category: 'View Profile',
                                action: 'Add Contact',
                              });
                              if (contactExchangeEnabled) {
                                setTimeout(() => {
                                  setIsShareDetailsOpen(true);
                                }, 1000);
                              }
                            }}
                          >
                            <ProfilePageAddToContactsButton
                              className={styles.button}
                              href={vcfUrl}
                            />
                          </button>
                        )}
                        backgroundColor={backgroundColor}
                        cardholderEditingEnabled={status === 'editing'}
                        companyAddress={[
                          streetAddress,
                          [city, state, postCode].filter(v => v).join(' '),
                          country,
                        ]
                          .filter(v => v)
                          .join('\n')}
                        companyName={companyName}
                        companyPhoneNumber={companyPhoneNumber}
                        companyWebsite={companyWebsite}
                        contactExchangeEnabled={contactExchangeEnabled}
                        email={email}
                        error={false}
                        jobTitle={jobTitle}
                        mobileNumber={mobileNumber}
                        name={[firstName, middleName, lastName].join(' ')}
                        pronouns={pronouns}
                        note={note}
                        profileHashID={profileHashID}
                        profileImageUrl={profileImageUrl}
                        quickLinks={quickLinks}
                        socialLinks={profileSocialLinks}
                        textColor={textColor}
                        setIsShareDetailsOpen={setIsShareDetailsOpen}
                      />
                      <ProfilePageFooter
                        textColor={footerTextColor}
                        profileHashID={profileHashID}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
          <ModalStandalone
            isOpen={isShareDetailsOpen}
            onClose={() => setIsShareDetailsOpen(false)}
          >
            <ProfilePageShareDetails
              contactExchange={contactExchange}
              profileHashID={profileHashID}
              profileName={firstName}
              styles={styles}
              setIsOpen={setIsShareDetailsOpen}
              consentStatement={consentStatement}
              consentMessage={consentMessage}
            />
          </ModalStandalone>
          {!isLoading && (
            <div className="fixed p-4 w-full bottom-0 md:hidden">
              <Transition
                enter="transition duration-300 transform delay-150"
                enterFrom="translate-y-16"
                enterTo="translate-y-0"
                show={!isLoading}
              >
                <button
                  className="appearance-none w-full"
                  type="button"
                  onClick={() => {
                    if (contactExchangeEnabled) {
                      setTimeout(() => {
                        setIsShareDetailsOpen(true);
                      }, 1000);
                    }
                  }}
                >
                  <ProfilePageAddToContactsButton
                    fullWidth
                    href={vcfUrl}
                    className={styles.button}
                  />
                </button>
              </Transition>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { SampleProfilePage as default };
