import axios, { AxiosResponse } from 'axios';

import axiosApiService from '@/services/axiosApiService';
import { ICardAdmin, ICardUser } from '@/types/ICard';
import IList, { IListPaging } from '@/types/IList';
import { VariantValue } from '@/types/IProduct';

import { BASE_URL } from '../config';

export interface IListCards {
  orgID: number;
  page: number;
  pageSize: number;
  sort: string;
  order: string;
  status: string;
  search: string;
}

const cardsAPI = {
  listCards: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    status = '',
    search = '',
    types = [],
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: ICardUser[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(`${BASE_URL}/api/organisations/${orgID}/cards`, {
      params: {
        page_size: pageSize,
        page,
        [`sort[${sort}]`]: order,
        ...(status && { status }),
        ...(search && { search }),
        ...(types && { types }),
      },
    });
  },
  listCardTypes: (orgID: number): Promise<AxiosResponse<VariantValue[]>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/cards/types`,
    );
  },
  createCard: (
    orgID: number,
    body: {
      card: {
        design_id?: number;
        profile_id?: number;
        type: string;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organiations/${orgID}/cards`,
      body,
    );
  },
  updateCard: (
    orgID: number,
    cardHash: string,
    profileHash: string,
  ): Promise<AxiosResponse<ICardUser>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/cards/${cardHash}`,
      {
        card: { profile_hash: profileHash },
      },
    );
  },
  updateCardsShared: (
    orgID: number,
    body: {
      ids: number[];
      card: {
        status?: string;
        profile_id?: number | null;
        design_id?: number;
      };
    },
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/cards/shared`,
      body,
    );
  },
  showCard: (
    orgID: number,
    cardID: number,
  ): Promise<AxiosResponse<{ data: ICardAdmin }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/cards/${cardID}`,
    );
  },
  showCardWithHashID: (
    hashID: string,
  ): Promise<AxiosResponse<{ data: ICardAdmin }>> => {
    // Set header to disable response caching
    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    };

    return axiosApiService.get(`${BASE_URL}/api/cards/${hashID}`, config);
  },
  importCardsCSV: (
    orgID: number,
    formData: FormData,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/csv_import`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  exportCardsCSV: (orgID: number): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/cards/export_csv`,
    );
  },
  activateCards: (
    activationKey: string,
    orgID: number,
    profileID?: number,
  ): Promise<
    AxiosResponse<{ data: { type: string; profile?: { id: number } } }>
  > => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/card_activations/activate/${activationKey}`,
      {
        profile_id: profileID,
      },
    );
  },
  showActivatationKeyType: (
    activationKey: string,
  ): Promise<AxiosResponse<{ data: { type: string } }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/card_activations/${activationKey}`,
    );
  },
  activateCardsWithProfileHashID: (
    activationKey: string,
    profileHashID: string,
  ): Promise<
    AxiosResponse<{ data: { type: string; profile?: { id: number } } }>
  > => {
    return axiosApiService.post(
      `${BASE_URL}/api/card_activations/activate/${activationKey}`,
      {
        profile_hash_id: profileHashID,
      },
    );
  },
};

export default cardsAPI;
