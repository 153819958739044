import { Dispatch, SetStateAction, useState } from 'react';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

import Button, { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button';
import IOrderV2 from '@/types/IOrderV2';
import { Box } from '@material-ui/core';

interface IOrderInformation {
  handleClickLinkedOrg: (orgId: number) => void;
  orgID: number;
  setIsMoveOrder: Dispatch<SetStateAction<boolean>>;
  organisationPastOrder: IOrderV2[];
  customerName: string;
  customerEmail: string;
}
export default function OrderInformation({
  handleClickLinkedOrg,
  orgID,
  setIsMoveOrder,
  organisationPastOrder,
  customerName,
  customerEmail,
}: IOrderInformation) {
  const [copyNameStatus, setCopyNameStatus] = useState<boolean>(false);
  const [copyEmailStatus, setCopyEmailStatus] = useState<boolean>(false);

  const handleCopy = (type: string, value: string) => {
    navigator.clipboard.writeText(value);
    if (type === 'name') {
      setCopyNameStatus(true);
      setTimeout(() => setCopyNameStatus(false), 2000);
    }
    if (type === 'email') {
      setCopyEmailStatus(true);
      setTimeout(() => setCopyEmailStatus(false), 2000);
    }
  };
  return (
    <Box className=" font-poppins font-medium text-base leading-5 text-gray-900 grid gap-3">
      <p>Order Information</p>
      <Box className="grid grid-cols-2 gap-10">
        <Box className="flex justify-center items-center">
          <Box className="w-full font-poppins font-normal text-sm">
            <p className="font-medium">Full name</p>
            <p className="truncate">{customerName}</p>
          </Box>
          <Box
            className="ml-2 cursor-pointer flex gap-1 justify-center items-center"
            onClick={() => handleCopy('name', customerName)}
          >
            {copyNameStatus && (
              <span className="text-xs leading-4 font-medium font-poppins text-brand-500">
                copied!
              </span>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
            >
              <path
                d="M12.5 18H2.5V5C2.5 4.45 2.05 4 1.5 4C0.95 4 0.5 4.45 0.5 5V18C0.5 19.1 1.4 20 2.5 20H12.5C13.05 20 13.5 19.55 13.5 19C13.5 18.45 13.05 18 12.5 18ZM17.5 14V2C17.5 0.9 16.6 0 15.5 0H6.5C5.4 0 4.5 0.9 4.5 2V14C4.5 15.1 5.4 16 6.5 16H15.5C16.6 16 17.5 15.1 17.5 14ZM15.5 14H6.5V2H15.5V14Z"
                fill="#873CFF"
              />
            </svg>
          </Box>
        </Box>
        <Box className="flex justify-center items-center">
          <Box className="w-full font-poppins font-normal text-sm">
            <p className="font-medium">Email</p>
            <p className="truncate">{customerEmail}</p>
          </Box>
          <Box
            className="ml-2 cursor-pointer flex gap-1 justify-center items-center"
            onClick={() => handleCopy('email', customerEmail)}
          >
            {copyEmailStatus && (
              <span className="text-xs leading-4 font-medium font-poppins text-brand-500">
                copied!
              </span>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
            >
              <path
                d="M12.5 18H2.5V5C2.5 4.45 2.05 4 1.5 4C0.95 4 0.5 4.45 0.5 5V18C0.5 19.1 1.4 20 2.5 20H12.5C13.05 20 13.5 19.55 13.5 19C13.5 18.45 13.05 18 12.5 18ZM17.5 14V2C17.5 0.9 16.6 0 15.5 0H6.5C5.4 0 4.5 0.9 4.5 2V14C4.5 15.1 5.4 16 6.5 16H15.5C16.6 16 17.5 15.1 17.5 14ZM15.5 14H6.5V2H15.5V14Z"
                fill="#873CFF"
              />
            </svg>
          </Box>
        </Box>
      </Box>
      <Box className="flex items-center justify-start font-poppins text-sm font-medium">
        Linked Organisation
        <Box className="flex flex-1 justify-end items-center gap-3">
          <Button
            buttonText="Open Linked Org"
            size={BUTTON_SIZE.XSMALL}
            kind={BUTTON_KIND.WHITE}
            onClick={() => handleClickLinkedOrg(orgID)}
          />
          <Button
            buttonText="Change Org"
            size={BUTTON_SIZE.XSMALL}
            kind={BUTTON_KIND.WHITE}
            onClick={() => setIsMoveOrder(true)}
          />
        </Box>
      </Box>
      <Box className=" font-poppins font-medium text-sm text-gray-700">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between py-2 text-sm font-medium text-left">
                <span>Past orders</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-brand-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-wrap gap-2">
                {organisationPastOrder.map(item => (
                  <span
                    key={item.id}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 cursor-pointer hover:bg-brand-200"
                    onClick={() =>
                      window.open(`/orders-v2/${item.uuid}`, '_blank')
                    }
                  >
                    #{item.order_number}
                  </span>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Box>
    </Box>
  );
}
