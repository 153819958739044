import { Dispatch, SetStateAction } from 'react';
import { Box } from '@material-ui/core';
import Input from '@/components/Input';
import InputCheckbox from '@/components/InputCheckbox';
import InputSelect from '@/components/InputSelect';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import { IShippingInformation } from '@/types/ReOrderExperience/IReorderMultistepPage';
import { ICOUNTRIES, ISTATES } from '@/types/ReOrderExperience/IStep3';

interface IShippingAddressComponent {
  shippingInformation: IShippingInformation;
  setShippingInformation: Dispatch<SetStateAction<IShippingInformation>>;
  billAddressCheckBox: boolean;
  setBillAddressCheckBox: Dispatch<SetStateAction<boolean>>;
  COUNTRIES: ICOUNTRIES[];
  STATES: ISTATES[];
  error: boolean;
}

const ShippingAddress = (props: IShippingAddressComponent) => {
  const {
    shippingInformation,
    setShippingInformation,
    billAddressCheckBox,
    setBillAddressCheckBox,
    COUNTRIES,
    STATES,
    error,
  } = props;

  return (
    <InfoPanelContainer
      title={
        <div>
          <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
            Shipping address
          </p>
        </div>
      }
      border={false}
      padding=""
    >
      <Box className="grid grid-cols-6 gap-6">
        <Box className="col-span-6 sm:col-span-3 md:col-span-6">
          <Box className="grid gap-6 p-6 border rounded-lg border-gray-100">
            <Box className="text-left">
              <Input
                type="text"
                label="Company Name"
                value={shippingInformation.company}
                onChange={(company) => {
                  setShippingInformation({ ...shippingInformation, company });
                }}
                required
              />
              {error && !shippingInformation.company && (
                <p className="mt-1 text-red-450 text-sm">
                  Please fill company name input
                </p>
              )}
            </Box>
            <Box className="text-left w-1/2">
              <InputSelect
                id="Country/Region"
                label="Country/Region"
                value={shippingInformation.countryRegion || 'Australia'}
                onChange={(countryRegion) => {
                  setShippingInformation({
                    ...shippingInformation,
                    countryRegion,
                  });
                }}
                options={COUNTRIES}
                selectClassName="block w-full py-2 font-normal text-gray-900 focus:ring-brand-500 focus:border-brand-500 shadow-sm border-gray-300 rounded-md"
              />
              {error && !shippingInformation.countryRegion && (
                <p className="mt-1 text-red-450 text-sm">
                  Please fill country region input
                </p>
              )}
            </Box>

            <Box className="col-span-full">
              <Input
                type="text"
                label="Street address"
                value={shippingInformation.address}
                onChange={(address) => {
                  setShippingInformation({
                    ...shippingInformation,
                    address,
                  });
                }}
                required
              />
              {error && !shippingInformation.address && (
                <p className="mt-1 text-red-450 text-sm">
                  Please fill address input
                </p>
              )}
            </Box>

            <Box className="grid grid-cols-3 gap-6">
              <Box className="col-span-1">
                <Input
                  type="text"
                  label="City"
                  value={shippingInformation.city}
                  onChange={(city) => {
                    setShippingInformation({
                      ...shippingInformation,
                      city,
                    });
                  }}
                  required
                />
                {error && !shippingInformation.city && (
                  <p className="mt-1 text-red-450 text-sm">
                    Please fill city input
                  </p>
                )}
              </Box>

              <Box className="col-span-1">
                <InputSelect
                  id="State / Province"
                  label="State / Province"
                  value={shippingInformation.state}
                  onChange={(state) => {
                    setShippingInformation({ ...shippingInformation, state });
                  }}
                  options={STATES}
                  selectClassName="block w-full py-2 font-normal text-gray-900 focus:ring-brand-500 focus:border-brand-500 shadow-sm border-gray-300 rounded-md"
                />
                {error && !shippingInformation.state && (
                  <p className="mt-1 text-red-450 text-sm">
                    Please fill state input
                  </p>
                )}
              </Box>
              <Box className="col-span-1">
                <Input
                  type="text"
                  label="Zip / Postal"
                  value={shippingInformation.postCode}
                  onChange={(postCode) => {
                    setShippingInformation({
                      ...shippingInformation,
                      postCode,
                    });
                  }}
                  required
                />
                {error && !shippingInformation.postCode && (
                  <p className="mt-1 text-red-450 text-sm">
                    Please fill postcode input
                  </p>
                )}
              </Box>
            </Box>
            <Box className="flex justify-start items-center ">
              <InputCheckbox
                id="checkbox for same as shipping address"
                value={billAddressCheckBox}
                onChange={setBillAddressCheckBox}
                label="Same as billing information"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </InfoPanelContainer>
  );
};
export default ShippingAddress;
