import { useState, useCallback } from 'react';
import ordersAPI from '@/api/ordersV2';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import { IListNew } from '@/types/IList';
import IOrder from '@/types/IOrderV2';
import Pagination from '@/components/Pagination';
import OrdersListItem from '@/components/OrdersPage/OrdersV2List/Item';
import Search from '../Search';
import Sort from '../Sort';
import LoadingAnimation from '../LoadingAnimation';
import DesignBar from '../DesignBar';
import clsx from 'clsx';

export default function OrdersTab() {
  const { orgID } = useAuth();

  const [orders, setOrders] = useState<IOrder[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('updated_at');
  const [order, setOrder] = useState('desc');

  const listOrders = useCallback(
    async ({
      newPage = page,
      newPageSize = pageSize,
      newSort = sort,
      newOrder = order,
      newSearch = search,
    }: IListNew) => {
      if (orgID === undefined) {
        const emptyData = {
          data: [],
          paging: {
            page_number: 1,
            page_offset: 0,
            page_size: 1,
            total_entries: 0,
            total_pages: 0,
          },
        };

        return emptyData;
      }

      const res = await ordersAPI.listOrdersUserOrganisation(orgID, {
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        search: newSearch,
      });

      setOrders(res.data.data);
      setPage(newPage);
      setPageSize(newPageSize);
      setSearch(newSearch);
      setIsLoading(false);

      return res.data;
    },
    [page, pageSize, sort, order, search, orgID],
  );

  return (
    <>
      <div className="outline-none">
        <div className="py-7">
          <div className="pb-8 flex flex-1 flex-col space-y-3 items-start sm:flex-row-reverse sm:space-y-0 sm:items-center sm:justify-between">
            <div className="flex flex-shrink-0 space-x-3">
              <div className="inline-flex">
                <div className="relative mr-4">
                  <Search
                    id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${search}`}
                    placeholder="Search"
                    search={search}
                    setSearch={setSearch}
                    fetchQuery={(newSearch) => listOrders({ newSearch })}
                  />
                </div>
                <Sort
                  id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${search}`}
                  options={[
                    {
                      sort: 'inserted_at',
                      order: 'desc',
                      label: 'Date created (Newest first)',
                    },
                    {
                      sort: 'inserted_at',
                      order: 'asc',
                      label: 'Date created (Oldest first)',
                    },
                    {
                      sort: 'quantity',
                      order: 'asc',
                      label: 'No of Profile (Lowest first)',
                    },
                    {
                      sort: 'quantity',
                      order: 'desc',
                      label: 'No of Profile (Highest first)',
                    },
                    {
                      sort: 'type',
                      order: 'shop',
                      label: 'Order Type (Shop)',
                    },
                    {
                      sort: 'type',
                      order: 'draft',
                      label: 'Order Type (Draft)',
                    },
                  ]}
                  sort={sort}
                  setSort={setSort}
                  order={order}
                  setOrder={setOrder}
                  fetchQuery={(newSort, newOrder) =>
                    listOrders({ newSort, newOrder })
                  }
                />
              </div>
            </div>
            <DesignBar />
          </div>
          {orders && orders.length > 0 ? (
            <div className="flex flex-col">
              <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                <div className="block w-full lg:px-8">
                  <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8">
                    <ul
                      className={clsx('divide-y divide-gray-200', {
                        'opacity-40': isLoading,
                      })}
                    >
                      <li key="OrderListHeader" className="hidden md:block">
                        <div className="bg-gray-50">
                          <div className="flex items-center py-2.5">
                            <div className="min-w-0 flex-1 flex items-start md:items-center">
                              <div className="md:grid-cols-4 md:gap-8 xl:grid-cols-6 min-w-0 flex-1 md:grid text-gray-900 uppercase tracking-wider font-medium text-sm">
                                <p className="flex text-center justify-center items-center">
                                  Order No.
                                </p>
                                <p className="flex text-center justify-center items-center">
                                  Date
                                </p>
                                <p className="hidden xl:flex text-center justify-center items-center">
                                  Order Info
                                </p>
                                <p className="flex text-center justify-center items-center">
                                  Design
                                </p>
                                <p className="hidden xl:flex text-center justify-center items-center">
                                  Fulfilment
                                </p>
                                <p className="flex text-center justify-center items-center">
                                  Reorder
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      {orders.map((order, index) => (
                        <OrdersListItem
                          key={order.id}
                          uuid={order.uuid}
                          orderNumber={order.order_number}
                          numberOfProfiles={order.order_quantity}
                          designFile={order.design_file}
                          orderStatus={order.order_stage}
                          order_status_url={order.order_status_url}
                          inserted_at={order.inserted_at}
                          index={index}
                        />
                      ))}
                    </ul>
                    {isLoading && (
                      <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                        <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.order.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.order.empty.description}
              </p>
            </div>
          )}
        </div>
      </div>
      <Pagination
        id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${search}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(newPage, newPageSize) =>
          listOrders({ newPage, newPageSize })
        }
        setIsLoading={setIsLoading}
      />
    </>
  );
}
