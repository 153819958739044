import {
  DevicePhoneMobileIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  ShareIcon,
  ShoppingBagIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';

import CardIcon from '../Icons/Card';
import EditIcon from '../Icons/EditIcon';
import Support from '../Icons/Support';

const listData = [
  {
    id: 1,
    title: 'Download the app',
    icon: <DevicePhoneMobileIcon className="text-brand-500 w-6 h-6" />,
    url: '',
  },
  {
    id: 2,
    title: 'Ways to share your profile',
    icon: <ShareIcon className="w-6 h-6 text-brand-500" strokeWidth={2} />,
    url: '#',
  },
  {
    id: 3,
    title: 'FAQs',
    icon: (
      <QuestionMarkCircleIcon
        className="w-6 h-6 text-brand-500"
        strokeWidth={2}
      />
    ),
    url: 'https://tapt.io/help/',
  },
  {
    id: 4,
    title: 'How to add additional admins',
    icon: <UserPlusIcon className="w-6 h-6 text-brand-500" strokeWidth={2} />,
    url: 'https://help.tapt.io/en/articles/8449605-managing-user-roles-in-tapt-dashboard-account-administrator-vs-group-manager',
  },
  {
    id: 6,
    title: 'How to use your card',
    icon: (
      <div className="text-brand-500">
        <CardIcon />
      </div>
    ),
    url: 'https://tapt.io/how-to-use/',
  },
  {
    id: 7,
    title: 'How to bulk edit',
    icon: <EditIcon />,
    url: 'https://help.tapt.io/en/articles/8449603-profiles-management-from-the-tapt-dashboard',
  },
  {
    id: 8,
    title: 'How to order a replacement card',
    icon: (
      <ShoppingBagIcon className="w-6 h-6 text-brand-500" strokeWidth={2} />
    ),
    url: 'https://help.tapt.io/en/articles/8449621-ordering-replacement-card-s',
  },
  {
    id: 9,
    title: 'Support',
    icon: <Support />,
    url: 'https://help.tapt.io/',
  },
  {
    id: 10,
    title: 'Sales',
    icon: <PhoneIcon className="w-6 h-6 text-brand-500" strokeWidth={2} />,
    url: 'https://tapt.io/pages/sales-enquiry',
  },
];

export default listData;
