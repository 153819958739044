import { IOrganisationGroup } from '@/types/IOrganisation';
import InputCheckbox from '../InputCheckbox';

type Editor = {
  id: number;
  name: string;
  groups: IOrganisationGroup[];
  status: 'active' | 'pending';
};

type EditorItemProps = {
  editor: Editor;
  isChecked: boolean;
  onCheckChange: (value: boolean) => void;
};

export function EditorItem({
  editor,
  isChecked,
  onCheckChange,
}: EditorItemProps) {
  return (
    <tr className="relative hover:bg-gray-50 cursor-pointer">
      <td className="whitespace-nowrap text-sm py-3 px-6">
        <InputCheckbox
          id={`Checkbox-${editor.id}`}
          value={isChecked}
          onChange={onCheckChange}
        />
        <a
          className="after:absolute after:inset-0"
          onClick={() => onCheckChange(!isChecked)}
        />
      </td>
      <td className="whitespace-nowrap text-sm py-3 px-6 text-gray-900 font-medium">
        {editor.name}
      </td>
      <td className="whitespace-nowrap text-sm py-3 px-6 text-gray-900">
        {editor.groups.map((group) => group.name).join(', ')}
      </td>
      <td className="whitespace-nowrap py-3 px-6 flex justify-center">
        {editor.status === 'active' && (
          <div className="bg-emerald-50 text-emerald-700 font-medium py-0.5 px-3 rounded-full text-sm w-max h-min">
            Active
          </div>
        )}
        {editor.status === 'pending' && (
          <div className="bg-amber-50 text-amber-800 font-medium py-0.5 px-3 rounded-full text-sm w-max h-min">
            Pending
          </div>
        )}
      </td>
    </tr>
  );
}

export function EditorItemMobile({
  editor,
  isChecked,
  onCheckChange,
}: EditorItemProps) {
  return (
    <div className="relative border border-gray-300 shadow-sm rounded-md flex-row justify-between p-2">
      <a
        className="after:absolute after:inset-0"
        onClick={() => onCheckChange(!isChecked)}
      />
      <div className="flex flex-row">
        <InputCheckbox
          id={`Checkbox-${editor.id}`}
          value={isChecked}
          onChange={onCheckChange}
        />
        <div className="flex flex-row justify-between flex-1">
          <div className="flex flex-col pl-2">
            <span className="font-medium text-gray-900 truncate">
              {editor.name}
            </span>
            <span className="text-gray-500 truncate">
              {editor.groups.map((group) => group.name).join(', ')}
            </span>
          </div>
          {editor.status === 'active' && (
            <div className="bg-emerald-50 text-emerald-700 font-medium py-0.5 px-3 rounded-full text-sm w-max h-min">
              Active
            </div>
          )}
          {editor.status === 'pending' && (
            <div className="bg-amber-50 text-amber-800 font-medium py-0.5 px-3 rounded-full text-sm w-max h-min">
              Pending
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
