const bannerData = [
  {
    id: 1,
    image: '/images/Banners_1.jpg',
    mobileImage: '/images/Banners_4.jpg',
    title: 'What’s new at tapt...',
    SubTitle: 'Download the tapt app today!',
    button: 'Learn more',
    shouldRedirectToAppStore: true,
  },
  {
    id: 2,
    image: '/images/Banners_2.jpg',
    mobileImage: '/images/Banners_5.jpg',
    title: 'What’s new at tapt...',
    SubTitle: 'Follow us to keep updated!',
    button: 'See socials',
    url: 'https://www.linkedin.com/company/taptofficial/',
  },
];

export default bannerData;
