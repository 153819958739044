import clsx from 'clsx';

import { ICardAdmin } from '@/types/ICard';

const ActivationKeyCell: React.FC<{
  card: ICardAdmin;
}> = ({ card: { activation_key } }) => (
  <td className={clsx('p-4 pb-3 hidden md:table-cell')}>
    {activation_key && (
      <p className="text-sm text-gray-900">#{activation_key}</p>
    )}
  </td>
);

export default ActivationKeyCell;
