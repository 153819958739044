import { CSSProperties, ReactNode } from 'react';

export interface ITableData {
  className?: string;
  style?: CSSProperties;
  children: ReactNode | string | JSX.Element | any;
}

const TableData = ({ style, children, className }: ITableData): JSX.Element => {
  return (
    <th className={className} style={style}>
      {children}
    </th>
  );
};

export default TableData;
