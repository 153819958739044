import { useState } from 'react';
import { Tab } from '@headlessui/react';
import { Helmet } from 'react-helmet-async';
import useAuth from '@/hooks/useAuth';

import Layout from '@/components/Layout';
import TabList from '@/components/TabList';
import { CurrentPlan, Invoices, PaymentMethods } from '@/components/PlanPage';

const tabs = [
  {
    name: 'Current Plan',
    value: 'current-plan',
    current: true,
  },
  {
    name: 'Payment Methods',
    value: 'payment-methods',
    current: false,
  },
  {
    name: 'Invoices',
    value: 'invoices',
    current: false,
  },
];

export default function PlanPage(): JSX.Element {
  const { orgID } = useAuth();
  const [tab, setTab] = useState<string>(tabs[0].value);
  return (
    <Layout pageName="Manage Plan">
      <Helmet>
        <script src="https://js.chargebee.com/v2/chargebee.js" />
      </Helmet>
      <Tab.Group
        onChange={(index: number) => {
          setTab(tabs[index].value);
        }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
          <div className="pt-4 md:pt-0 w-full md:w-auto">
            <TabList tabs={tabs} value={tab} />
          </div>
        </div>
        <Tab.Panels>
          <Tab.Panel className="outline-none">
            {orgID && <CurrentPlan orgID={orgID} />}
          </Tab.Panel>
          <Tab.Panel className="outline-none">
            {orgID && <PaymentMethods orgID={orgID} />}
          </Tab.Panel>
          <Tab.Panel className="outline-none">
            {orgID && <Invoices orgID={orgID} />}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
}
