type RestrictionComponentProps<P, T = Record<string, unknown>> = T & {
  mainComponentProps: P;
  restrictionMessage?: string;
};
export default function withRestriction<T, L = Record<string, unknown>>(
  ResultComponent: React.FC<T>,
  RestrictedComponent: React.FC<RestrictionComponentProps<T, L>>,
  restrictionComponentProps?: L,
) {
  const Component: React.FC<
    T & { isRestricted?: boolean; restrictionMessage?: string }
  > = (props) => {
    const { isRestricted, restrictionMessage, ...rest } = props;
    if (isRestricted)
      return (
        <RestrictedComponent
          {...(restrictionComponentProps ?? ({} as NonNullable<L>))}
          restrictionMessage={restrictionMessage}
          mainComponentProps={rest as T}
        />
      );
    return (
      <ResultComponent {...(rest as unknown as T & JSX.IntrinsicAttributes)} />
    );
  };

  return Component;
}
