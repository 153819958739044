import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { IOrgLoginItem } from '@/types/IAnalytics';

const defaultOptions: ApexOptions = {
  chart: {
    sparkline: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      shadeIntensity: 1,
    },
  },
  plotOptions: {
    area: {
      fillTo: 'end',
    },
  },
  tooltip: {
    style: {
      fontSize: '14px',
    },
  },
};

const defaultHeight = 200;

type OrgLoginsProps = {
  data: IOrgLoginItem;
  title: string;
  subtitle: string;
};

export const formatNumber = (num: number) => {
  const formatter = new Intl.NumberFormat('en-AU');
  return formatter.format(num);
};

function OrgLogins({ data, title, subtitle }: OrgLoginsProps) {
  const series: ApexOptions['series'] = [
    {
      name: 'Logins',
      data: Object.values(data.entries),
      color: '#873CFF',
    },
  ];
  const labels: ApexOptions['labels'] = Object.keys(data.entries);

  return (
    <div className="flex flex-col min-h-full min-w-full justify-between">
      <div className="flex justify-between min-w-full">
        <div>
          <div className="flex flex-wrap justify-between items-center">
            <div className="flex justify-between items-center flex-wrap">
              <div className="text-gray-900 font-medium text-2xl md:text-2xl">
                {title}
              </div>
            </div>
          </div>
          <h3 className="text-gray-500 mb-2 hidden md:block">{subtitle}</h3>
        </div>
        <div className="flex justify-end flex-1 font-poppins text-gray-700 font-semibold text-2xl">
          {formatNumber(data.total)}
        </div>
      </div>
      <hr className="h-px mt-4 mb-8 bg-gray-200 border-0 " />
      <div>
        <Chart
          height={defaultHeight}
          options={{ ...defaultOptions, labels }}
          series={series}
          type="area"
        />
      </div>
    </div>
  );
}

export default OrgLogins;
