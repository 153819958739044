export default (numberOfProfiles: number) => {
  const weight = 0.05 * numberOfProfiles;

  if (numberOfProfiles >= 0 && numberOfProfiles <= 8)
    return {
      height: 0.05,
      width: 0.13,
      length: 0.19,
      weight,
    };

  if (numberOfProfiles > 8 && numberOfProfiles <= 29)
    return {
      height: 0.09,
      width: 0.15,
      length: 0.18,
      weight,
    };

  if (numberOfProfiles > 29 && numberOfProfiles <= 60)
    return {
      height: 0.09,
      width: 0.15,
      length: 0.18,
      weight,
    };

  return null;
};
