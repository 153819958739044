import { useRef } from 'react';
import { Switch } from '@headlessui/react';
import classNames from '@/helpers/classNames';

interface IToggle {
  enabled: boolean;
  label: string;
  description?: string;
  setter: (enabled: boolean) => void;
  disabled?: boolean;
  message?: string;
  className?: string;
  spaceX?: number;
  lableFont?: string;
  lableColor?: string;
  onChange?: (enabled: boolean) => void;
}

export default function ToggleField({
  label,
  setter,
  enabled,
  description,
  disabled,
  message,
  className,
  spaceX = 2,
  lableFont = 'medium',
  lableColor = 'gray-900',
  onChange,
}: IToggle) {
  const changeRef = useRef<any>();

  const handleChange = async () => {
    setter(!enabled);
    if (onChange) {
      if (changeRef.current) clearTimeout(changeRef.current);
      changeRef.current = setTimeout(async () => {
        onChange(!enabled);
      }, 500);
    }
  };

  return (
    <Switch.Group
      as="div"
      className={`flex items-center space-x-${spaceX} justify-between ${className}`}
    >
      {(label || description) && (
        <span className="flex-grow flex flex-col">
          <Switch.Label
            as="span"
            className={`text-sm leading-5 font-${lableFont} text-${lableColor}`}
            passive
          >
            {label}
          </Switch.Label>
          {description && (
            <Switch.Description
              as="span"
              className="text-sm leading-5 text-gray-500"
            >
              {description}
            </Switch.Description>
          )}
        </span>
      )}
      <div className={`flex items-center justify-end space-x-${spaceX}`}>
        {message && <span className="text-sm text-gray-500">{message}</span>}
        <Switch
          checked={enabled}
          disabled={disabled}
          onChange={() => handleChange()}
          className={classNames(
            enabled && !disabled ? '!bg-brand-500' : '',
            enabled && disabled ? 'bg-brand-200' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            )}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}
