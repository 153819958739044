import { useEffect, useState } from 'react';
import Moment from 'react-moment';

import {
  CheckIcon,
  ClipboardDocumentCheckIcon,
  ArrowTopRightOnSquareIcon as ExternalLinkIcon,
  EyeIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import clsx from 'clsx';

import STAGE from '@/constants/stage';
import fulfillmentStageHelper from '@/helpers/fulfillmentStage';
import StageHelper from '@/helpers/stage';
import { OrderDesignFile } from '@/types/IOrderV2';

interface IOrdersListItem {
  uuid: string;
  order_status_url: string;
  orderNumber: string;
  designFile: OrderDesignFile | null;
  numberOfProfiles: number;
  orderStatus: string;
  inserted_at?: string;
  index?: number;
}

export default function OrdersListItem({
  uuid,
  orderNumber,
  order_status_url,
  numberOfProfiles,
  designFile,
  orderStatus,
  inserted_at,
  index,
}: IOrdersListItem) {
  const [desktopWidth, setDestopWidth] = useState(window.innerWidth >= 768);
  const [orderInfoWidth, setOrderInfoWidth] = useState(
    window.innerWidth >= 768 && window.innerWidth <= 796,
  );

  const shopUrl =
    (import.meta.env.VITE_SHOP_BASE_URL as string) || 'https://tapt.test:3001';

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 768) {
        setDestopWidth(true);
      } else {
        setDestopWidth(false);
      }

      if (window.innerWidth >= 768 && window.innerWidth <= 796) {
        setOrderInfoWidth(true);
      } else {
        setOrderInfoWidth(false);
      }
    }

    window.addEventListener('resize', handleResize);
  }, []);

  const DesignLabel = () => {
    return (
      <div
        className={`rounded-full h-5 px-2 bg-${
          StageHelper.getOrderStatus(orderStatus).color
        }-100`}
      >
        <p
          className={`text-xs text-center font-medium h-full flex items-center justify-center text-${
            StageHelper.getOrderStatus(orderStatus).color
          }-800`}
        >
          {StageHelper.getOrderStatus(orderStatus).label}
          <div className="ml-1">
            {StageHelper.getOrderStatus(orderStatus).icon}
          </div>
        </p>
      </div>
    );
  };

  return (
    <li className="z-50" id={uuid}>
      <div
        className={clsx('block', {
          'bg-gray-50': (index || 0) % 2 === 1,
          'hover:bg-gray-50': (index || 0) % 2 === 0,
          'hover:bg-gray-100': (index || 0) % 2 === 1,
        })}
      >
        <div className="flex items-center py-4">
          <div className="min-w-0 flex-1 md:flex md:items-center">
            {desktopWidth ? (
              <div className="md:grid md:grid-cols-4 md:gap-8 xl:grid-cols-6 min-w-0 flex-1">
                <div className="flex flex-row justify-center">
                  <div className="flex items-end flex-col">
                    <p className="text-sm text-gray-900 font-medium">
                      #{orderNumber}
                    </p>
                    <p className="text-sm text-gray-500 font-normal">
                      {numberOfProfiles === 1
                        ? `${numberOfProfiles} card`
                        : `${numberOfProfiles} cards`}
                    </p>
                  </div>
                </div>

                {/* DATE */}
                <div className="flex items-center justify-self-center">
                  <p className="text-sm text-gray-900 font-medium">
                    <Moment local format="DD/MM/YY">
                      {inserted_at}
                    </Moment>
                  </p>
                </div>

                {/* order info */}
                <div className="flex items-center justify-center">
                  <a href={order_status_url} target="_blank" rel="noreferrer">
                    <EyeIcon className="h-6 w-6 text-gray-700" />
                  </a>
                </div>

                {/* design */}
                <div className="md:hidden xl:flex items-center justify-center">
                  {[
                    STAGE.order.awaiting_client,
                    STAGE.order.fulfilled,
                    STAGE.order.ready_for_print,
                  ].includes(orderStatus) ? (
                    <a
                      href={
                        designFile === null ? undefined : `./preview/${uuid}`
                      }
                    >
                      <DesignLabel />
                    </a>
                  ) : StageHelper.getOrderStatus(orderStatus).id ===
                    'design_needed' ? (
                    <a
                      target="_blank"
                      href={`${
                        import.meta.env.VITE_CUSTOMISER_BASE_URL
                      }/order/${uuid}`}
                    >
                      <DesignLabel />
                    </a>
                  ) : (
                    <DesignLabel />
                  )}
                </div>

                {/* Order Fulfillment */}
                <div className="md:hidden xl:flex items-center justify-self-center">
                  {orderStatus === STAGE.order.fulfilled ? (
                    <div className="flex justify-center items-center rounded-full w-[30px] h-[30px]">
                      <CheckIcon className="text-emerald-500 w-5 h-5" />
                    </div>
                  ) : (
                    <div className="flex justify-center items-center rounded-full w-[30px] h-[30px]">
                      <XMarkIcon className="text-red-450 w-5 h-5" />
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-center">
                  <a href={`${shopUrl}/products/customise/`}>
                    <ShoppingBagIcon className="h-6 w-6 text-gray-700" />
                  </a>
                </div>
              </div>
            ) : (
              <>
                <div className="flex w-full">
                  <div className="flex items-start flex-col w-1/2 text-sm font-normal">
                    <div className="flex items-center">
                      <p className="text-sm text-gray-900 font-medium">
                        Order #{orderNumber}{' '}
                      </p>
                      <ExternalLinkIcon className="h-3 w-3 ml-1 stroke-1" />
                    </div>
                    <p className=" text-gray-600 ">{numberOfProfiles} cards</p>
                  </div>

                  <div className="flex items-center justify-start text-xs leading-4 mt-5">
                    <button
                      type="button"
                      className={`flex justify-center items-center font-medium bg-${
                        fulfillmentStageHelper.getOrderStatus(orderStatus).color
                      }-100 text-${
                        fulfillmentStageHelper.getOrderStatus(orderStatus).color
                      }-800 rounded-full px-2 py-1`}
                    >
                      {fulfillmentStageHelper.getOrderStatus(orderStatus).label}
                    </button>
                    <div className="flex items-center justify-self-center ml-2">
                      <div
                        className={`rounded-full w-36 px-2 py-1 bg-${
                          StageHelper.getOrderStatus(orderStatus).color
                        }-100`}
                      >
                        <p
                          className={`text-xs leading-4 text-center font-medium w-full h-full text-${
                            StageHelper.getOrderStatus(orderStatus).color
                          }-800`}
                        >
                          {StageHelper.getOrderStatus(orderStatus).label}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end w-1/2">
                    <p
                      className=" text-gray-400 font-medium leading-5"
                      style={{ fontSize: '9px' }}
                    >
                      Order placed{' '}
                      <Moment local format="DD/MM/YY">
                        {inserted_at}
                      </Moment>
                    </p>
                  </div>
                </div>
                <div className="mt-3 flex justify-end items-center ">
                  <div className="ml-3">
                    {(orderStatus === STAGE.order.awaiting_client && (
                      <div className="flex items-center justify-center ">
                        <a
                          href={`./preview/${uuid}`}
                          className="text-xs text-white font-medium rounded-md bg-brand-500 w-40 h-9 flex items-center justify-center"
                        >
                          <ClipboardDocumentCheckIcon
                            className="w-5 h-4  mr-2 text-white"
                            style={{ strokeWidth: '1px' }}
                          />
                          Review design
                        </a>
                      </div>
                    )) ||
                      ((orderStatus === STAGE.order.fulfilled ||
                        orderStatus === STAGE.order.ready_for_print) && (
                        <div className="flex items-center justify-center ">
                          <a
                            href={`./preview/${uuid}`}
                            className="text-white font-medium border border-gray-300 rounded-md bg-brand-500 w-40 h-9 text-xs flex items-center justify-center"
                          >
                            <ClipboardDocumentCheckIcon
                              className="w-5 h-4  mr-2 text-white"
                              style={{ strokeWidth: '1px' }}
                            />
                            View design
                          </a>
                        </div>
                      )) ||
                      ((orderStatus === STAGE.order.new ||
                        orderStatus === STAGE.order.design_edits) && (
                        <div className="flex items-center justify-center ">
                          <button
                            type="button"
                            className="text-xs text-gray-700 font-medium rounded-md bg-gray-300 w-40 h-9 flex items-center justify-center"
                          >
                            <ClipboardDocumentCheckIcon
                              className="w-5 h-4 mr-2 text-gray-700"
                              style={{ strokeWidth: '1px' }}
                            />
                            Not available
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
