import React from 'react';

import clsx from 'clsx';

import InputCheckbox from '@/components/InputCheckbox';
import {
  BaseTab,
  CARD_STATUS,
  IBaseTabState,
  IItem,
} from '@/components/ProductsAdminPage/BaseTab';
import ActivationKeyCell from '@/components/ProductsAdminPage/Cell/ActivationKeyCell';
import CardUrlCell from '@/components/ProductsAdminPage/Cell/CardUrlCell';
import OrganisationCell from '@/components/ProductsAdminPage/Cell/OrganisationCell';
import ProfileCell from '@/components/ProductsAdminPage/Cell/ProfileCell';
import StatusCell from '@/components/ProductsAdminPage/Cell/StatusCell';
import TypeCell from '@/components/ProductsAdminPage/Cell/TypeCell';

const Header: React.FC = () => (
  <>
    <th className="font-medium p-4 pb-3">TYPE &amp; ID</th>
    <th className="font-medium p-4 pb-3 w-40 text-center">STATUS</th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell">PROFILE</th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell">ORG</th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell w-32">
      ACTIVATION
    </th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell w-16">URL</th>
  </>
);

const Item: React.FC<IItem> = ({ card, selected, checkItem }) => {
  const id = card.card_hash;
  const status = CARD_STATUS.ALL;

  return (
    <tr key={id}>
      <td className="p-4 pb-3 w-[5%]">
        <InputCheckbox
          id={`Checkbox-${status}-${id}`}
          label=""
          labelClassName=""
          value={selected}
          onChange={checkItem}
        />
      </td>

      <TypeCell card={card} />
      <StatusCell card={card} />
      <ProfileCell card={card} />
      <OrganisationCell card={card} />
      <ActivationKeyCell card={card} />
      <CardUrlCell card={card} />
    </tr>
  );
};

export const AllProductsTab: React.FC<IBaseTabState> = props => {
  return (
    <BaseTab
      {...props}
      name="All Products"
      status={CARD_STATUS.ALL}
      header={Header}
      item={Item}
    />
  );
};
