const NumberHelper = {
  toFixedIfNecessary: (value: number | string, dp: number) => {
    return +parseFloat(value.toString()).toFixed(dp);
  },

  toLeadingZero: (value: string): string => {
    if (value.length === 1) {
      return value.padStart(2, '0');
    }
    return value;
  },
};

export default NumberHelper;
