import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const BASE_URL = 'https://api.pdf.co/v1';
const API_KEY =
  'maxf@tapt.io_fc88800ad2e1fad55d9ddbe2cf78e28a266ed0ab2adf98a966140d08a437b507c30a85e3';

export type ReplaceTextResponse = {
  url: string;
  error: boolean;
  status: number;
  name: string;
  remainingCredits: number;
  credits: number;
};

interface AxiosRequestConfigWithAuth extends AxiosRequestConfig {
  headers: {
    'x-api-key': string;
  };
}

const axiosApiService = axios.create();

// request interceptor to add the auth token header to requests
axiosApiService.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfigWithAuth => {
    return {
      ...config,
      headers: {
        ...config.headers,
        'x-api-key': API_KEY,
      },
    };
  },
  (error) => Promise.reject(error),
);

export const getCreditsBalance = (): Promise<
  AxiosResponse<{ remainingCredits: number }>
> => {
  return axiosApiService.get(`${BASE_URL}/account/credit/balance`);
};

export const replaceText = (
  url: string,
  searchStrings: string[],
  replaceStrings: string[],
): Promise<AxiosResponse<ReplaceTextResponse>> => {
  return axiosApiService.post(`${BASE_URL}/pdf/edit/replace-text`, {
    url,
    searchStrings,
    replaceStrings,
  });
};
