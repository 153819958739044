import InfoPanelContainer from '../InfoPanelContainer';

interface IInvoices {
  orgID: number;
}

const Invoices: React.FC<IInvoices> = (props) => {
  return (
    <div className="mt-10">
      <InfoPanelContainer
        title="Invoices"
        information="The list of invoices raised for your subscription since it started."
      >
        Test
      </InfoPanelContainer>
    </div>
  );
};

export { Invoices as default };
