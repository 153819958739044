import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Badge, { BADGE_KIND } from '@/components/Badge';
import InputCheckbox from '@/components/InputCheckbox';
import Modal from '@/components/Modals/Modal';
import MESSAGES from '@/constants/messages-en';
import classNames from '@/helpers/classNames';
import { ICardAdmin } from '@/types/ICard';

interface IActivationKeysItem {
  id: number;
  activationKey: string;
  type: string;
  insertedAt: Date;
  status: string;
  noOfProfiles?: number;
  orderNumber: string;
  card: ICardAdmin | null;
  cardID?: number;
  email?: string;
  selected: boolean;
  checkItem: () => void;
  handleDelete: () => void;
}

export default function ActivationKeysItem({
  id,
  activationKey,
  card,
  cardID,
  type,
  noOfProfiles,
  orderNumber,
  email,
  insertedAt,
  status,
  selected,
  checkItem,
  handleDelete,
}: IActivationKeysItem) {
  const history = useHistory();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <InputCheckbox
            id={`Checkbox-${status}-${id}`}
            label=""
            value={selected}
            onChange={checkItem}
          />
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div
              className={classNames(
                'min-w-0 flex-1 md:grid md:grid-cols-4 md:gap-4',
                type === 'shopify_generated'
                  ? 'xl:grid-cols-7'
                  : 'xl:grid-cols-6',
              )}
            >
              <div className="flex items-center">
                <p className="text-sm text-gray-500 font-medium">
                  <span className="md:hidden">Key:&nbsp;</span>#{activationKey}
                </p>
              </div>
              {type === 'card_generated' && (
                <div className="flex items-center md:hidden xl:flex">
                  {cardID && (
                    <p className="text-sm text-gray-900">
                      <span className="md:hidden">Linked Card ID:&nbsp;</span>#
                      {cardID}
                    </p>
                  )}
                </div>
              )}
              {type === 'shopify_generated' && (
                <div className="flex items-center md:hidden xl:flex">
                  <p className="text-sm text-gray-900">
                    <span className="md:hidden">Shopify order:&nbsp;</span>
                    {orderNumber}
                  </p>
                </div>
              )}
              {type === 'shopify_generated' && (
                <div className="flex items-center md:hidden xl:flex">
                  <div className="min-w-0">
                    <p className="text-sm text-gray-900 truncate">{email}</p>
                  </div>
                </div>
              )}
              <div className="flex items-center md:hidden xl:flex">
                <p className="text-sm text-gray-900">
                  <span className="md:hidden">No. of profiles:&nbsp;</span>
                  {noOfProfiles}
                </p>
              </div>
              <div className="flex items-center xl:flex">
                {status && (
                  <p>
                    {status === 'activated' && (
                      <Badge kind={BADGE_KIND.GREEN} text="Activated" />
                    )}
                    {status === 'not_activated' && (
                      <Badge kind={BADGE_KIND.AMBER} text="Not activated" />
                    )}
                  </p>
                )}
              </div>
              <div className="flex items-center xl:flex">
                {card && card.organisation_id && (
                  <button
                    type="button"
                    onClick={() =>
                      history.push(
                        `/view-organisation/${card.organisation_id || ''}`,
                        {
                          fromName: 'Activation Keys',
                          fromURL: '/activation-keys',
                        },
                      )
                    }
                    className="appearance-none text-sm leading-5 text-brand-500 text-left truncate"
                  >
                    {card.profile?.organisation_name && (
                      <>
                        {card.profile?.organisation_name}
                        <br />
                      </>
                    )}
                    <span className="text-sm md:hidden">Org ID:&nbsp;</span>
                    {`#${card.organisation_id}`}
                  </button>
                )}
              </div>
              <div className="flex items-center xl:flex">
                {insertedAt && (
                  <p className="text-sm text-gray-900">
                    {insertedAt.toLocaleString()}
                  </p>
                )}
              </div>
              {type === 'custom' && (
                <div className="flex items-center md:justify-end md:hidden xl:flex">
                  <button
                    type="button"
                    className="appearance-none text-red-500"
                    onClick={() => setIsDeleteOpen(true)}
                  >
                    Delete key
                  </button>
                </div>
              )}
            </div>
          </div>
          <Modal
            isOpen={isDeleteOpen}
            setIsOpen={setIsDeleteOpen}
            dialogTitle={MESSAGES.activationKey.delete.heading}
            successButtonText="Delete key"
            onSuccess={() => {
              handleDelete();
              setIsDeleteOpen(false);
            }}
          />
        </div>
      </div>
    </li>
  );
}
