import { useState } from 'react';

import { Menu } from '@headlessui/react';

import clsx from 'clsx';

import { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import Modal from '@/components/Modals/Modal';
import {
  BaseTab,
  CARD_STATUS,
  IBaseTabState,
  IItem,
} from '@/components/ProductsAdminPage/BaseTab';
import CardUrlCell from '@/components/ProductsAdminPage/Cell/CardUrlCell';
import CreatedCell from '@/components/ProductsAdminPage/Cell/CreatedCell';
import OrganisationCell from '@/components/ProductsAdminPage/Cell/OrganisationCell';
import ProfileCell from '@/components/ProductsAdminPage/Cell/ProfileCell';
import TypeCell from '@/components/ProductsAdminPage/Cell/TypeCell';
import ActionsMenu from '@/components/ProductsAdminPage/Menu/ActionsMenu';
import MESSAGES from '@/constants/messages-en';

const Header: React.FC = () => (
  <>
    <th className="font-medium p-4 pb-3">TYPE &amp; ID</th>
    <th className="font-medium p-4 pb-3">CREATED</th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell">PROFILE</th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell">ORG</th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell w-16">URL</th>
    <th className="font-medium p-4 pb-3 w-20">OTHER</th>
  </>
);

const Item: React.FC<IItem> = ({
  card,
  selected,
  checkItem,
  deleteProduct,
  unlinkProfile,
}) => {
  const id = card.card_hash;
  const status = CARD_STATUS.CONNECTED;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [isUnlinkProfileModalOpen, setIsUnlinkProfileModalOpen] =
    useState(false);
  const [isUnlinkProfileConfirmed, setIsUnlinkProfileConfirmed] =
    useState(false);

  return (
    <tr key={id}>
      <td className="p-4 pb-3 w-[5%]">
        <InputCheckbox
          id={`Checkbox-${status}-${id}`}
          label=""
          labelClassName=""
          value={selected}
          onChange={checkItem}
        />
      </td>
      <TypeCell card={card} />
      <CreatedCell card={card} />
      <ProfileCell card={card} />
      <OrganisationCell card={card} />
      <CardUrlCell card={card} />
      <ActionsMenu card={card}>
        <Menu.Item as="div">
          <button
            type="button"
            onClick={() => {
              setIsUnlinkProfileModalOpen(true);
            }}
            className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
          >
            Unlink profile
          </button>
        </Menu.Item>
        <Menu.Item as="div">
          <button
            type="button"
            onClick={() => {
              setIsDeleteModalOpen(true);
            }}
            className="text-red-450 w-full text-left block px-4 py-2 text-sm"
          >
            Delete product &amp; profile
          </button>
        </Menu.Item>
      </ActionsMenu>
      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onCancel={() => {
            setIsDeleteModalOpen(false);
          }}
          dialogTitle="Deleting this product & profile is permanent."
          dialogDescription="By proceeding, you acknowledge that you will permanently delete this card and profile."
          onSuccess={() => {
            deleteProduct &&
              deleteProduct(card).then(() => {
                setIsDeleteConfirmed(false);
                setIsDeleteModalOpen(false);
              });
          }}
          checkbox={isDeleteConfirmed}
          setCheckbox={setIsDeleteConfirmed}
          isDisabled={!isDeleteConfirmed}
          checkboxDescription="I am sure I want to delete this card and profile."
          successButtonText="Confirm"
          successButtonKind={BUTTON_KIND.RED}
        />
      )}
      {isUnlinkProfileModalOpen && (
        <Modal
          isOpen={isUnlinkProfileModalOpen}
          setIsOpen={setIsUnlinkProfileModalOpen}
          onCancel={() => {
            setIsUnlinkProfileModalOpen(false);
          }}
          dialogTitle={MESSAGES.card.unlink.heading}
          dialogDescription={MESSAGES.card.unlink.description}
          onSuccess={() => {
            unlinkProfile &&
              unlinkProfile(card).then(() => {
                setIsUnlinkProfileConfirmed(false);
                setIsUnlinkProfileModalOpen(false);
              });
          }}
          checkbox={isUnlinkProfileConfirmed}
          setCheckbox={setIsUnlinkProfileConfirmed}
          isDisabled={!isUnlinkProfileConfirmed}
          checkboxDescription={MESSAGES.card.unlink.checkboxDescription}
          successButtonText="Confirm"
        />
      )}
    </tr>
  );
};

export const ConnectedTab: React.FC<IBaseTabState> = props => {
  return (
    <BaseTab
      {...props}
      name="Connected"
      status={CARD_STATUS.CONNECTED}
      header={Header}
      item={Item}
    />
  );
};
