import { Fragment, useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon as DotsVerticalIcon } from '@heroicons/react/20/solid';

import { QRCodeFileSelectionModal } from '@/components/ProfilesListPage/QRCode';
import useAuth from '@/hooks/useAuth';
import { IProfileBase } from '@/types/IProfile';

import { ProfileListRowProps } from '../ProfileListItem';
import { AddProfilesToGroupModal } from './AddProfilesToGroupModal';

type MoreOptionsMenuProps = {
  profile: IProfileBase;
  refreshProfiles: ProfileListRowProps['refreshProfiles'];
  setActionResult: ProfileListRowProps['setActionResult'];
};

export function MoreOptionsMenu({
  profile,
  refreshProfiles,
  setActionResult,
}: MoreOptionsMenuProps) {
  const { userRole } = useAuth();

  const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

  const { id } = profile;

  return (
    <>
      <Menu as="div" className="relative inline-block items-center">
        <div className="flex items-center">
          <Menu.Button className="appearance-none">
            <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            {userRole === 'org_admin' && (
              <Menu.Item>
                <button
                  type="button"
                  className="text-gray-700 w-full text-left px-4 py-2 text-sm block"
                  onClick={() => setIsAddToGroupModalOpen(true)}
                >
                  Add to group
                </button>
              </Menu.Item>
            )}
            <Menu.Item>
              <button
                className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
                onClick={() => setIsQRCodeModalOpen(true)}
              >
                Export QR Code
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <AddProfilesToGroupModal
        isOpen={isAddToGroupModalOpen}
        setIsOpen={setIsAddToGroupModalOpen}
        checkedProfiles={[profile]}
        onSuccessCallback={() => {
          refreshProfiles();
          setActionResult({
            outcome: 'success',
            message: 'Profile was added to group successfully',
          });
        }}
      />
      <QRCodeFileSelectionModal
        checkedProfiles={[id]}
        isOpen={isQRCodeModalOpen}
        setIsOpen={setIsQRCodeModalOpen}
      />
    </>
  );
}
