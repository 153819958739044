import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import useAuth from '@/hooks/useAuth';
import { searchParamsQuery } from '@/helpers/urlHandler';
import LayoutStandard from '@/components/LayoutStandard';

interface ISsoConsumePage {
  location: {
    search: string;
  };
}

export default function SsoConsumePage({ location }: ISsoConsumePage) {
  const { ssoLogin, user } = useAuth();
  const { access_token: accessToken } = searchParamsQuery(location.search);

  useEffect(() => {
    ssoLogin(accessToken);
  });

  if (user) {
    return <Redirect to="/" />;
  }

  if (!accessToken) {
    <Redirect to="/login" />;
  }

  return (
    <LayoutStandard heading="Log in to your Tapt account">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md text-center">
        Logging in...
      </div>
    </LayoutStandard>
  );
}
