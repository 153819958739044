import styled from "styled-components";
  
  const ColumnChartContainer = styled.div({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '24px',
    '& canvas': {
      width: '80%!important',
      height: "250px !important"
    },
  });


export { ColumnChartContainer as default, ColumnChartContainer };
