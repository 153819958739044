import { useState } from 'react';
import Layout from '@/components/Layout';
import SimpleAnalytics from '@/components/Analytics/SimpleAnalytics';

export default function OldAnalyticsPage(): JSX.Element {
  const [profileIds, setProfileIds] = useState<number[]>([]);

  return (
    <Layout pageName="Analytics">
      <SimpleAnalytics setProfileIds={(ids) => setProfileIds(ids)}/>
    </Layout>
  );
};
