export default function PrepaidPlanDetail() {
  return (
    <div className="w-full">
      <article className="flex flex-col justify-center p-6 text-base bg-white rounded-lg border border-gray-200 max-md:px-5 max-md:max-w-full">
        <header className="text-xl font-medium text-gray-900 max-md:max-w-full">
          Plan
        </header>
        <section className="mt-3 leading-6 max-md:max-w-full">
          <p>
            Status: <span className="text-emerald-400">Active</span>
          </p>
        </section>
      </article>
    </div>
  );
}
