import { useState } from 'react';

import { clsx } from 'clsx';
import { Tooltip } from 'flowbite-react';
import { useCopyToClipboard } from 'usehooks-ts';

import Copy from '@/components/Icons/Copy';
import { ICardAdmin } from '@/types/ICard';

const CardUrlCell: React.FC<{ card: ICardAdmin }> = ({ card }) => {
  const [, copy] = useCopyToClipboard();
  const [urlCopied, setUrlCopied] = useState(false);

  const cardUrl = card.card_page_url;
  async function copyUrlToClipboard() {
    await copy(cardUrl);

    setUrlCopied(true);
    setTimeout(() => {
      setUrlCopied(false);
    }, 3000);
  }

  return (
    <td className={'p-4 pb-3 hidden md:table-cell'}>
      <Tooltip
        content="Copied!"
        trigger="click"
        placement="left"
        theme={{
          arrow: {
            style: {
              dark: 'bg-brand-100',
              light: 'bg-brand-100',
              auto: 'bg-brand-100',
            },
          },
          style: {
            dark: 'text-brand-500 bg-brand-100',
            light: 'text-brand-500 bg-brand-100',
            auto: 'text-brand-500 bg-brand-100',
          },
        }}
        className={clsx(
          // Manually hide the tooltip once the copy event is finished
          urlCopied ? 'visible opacity-100' : 'invisible opacity-0',
        )}
      >
        <div className="hover:cursor-pointer" onClick={copyUrlToClipboard}>
          <Copy
            className={clsx(
              'w-6 h-6',
              urlCopied ? 'text-brand-500' : 'text-gray-400',
            )}
          />
        </div>
      </Tooltip>
    </td>
  );
};

export default CardUrlCell;
