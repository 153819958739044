import { useState } from 'react';
import Layout from '@/components/Layout';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import adminAPI from '@/api/admin';
import Sort from '@/components/Sort';
import classNames from '@/helpers/classNames';
import MESSAGES from '@/constants/messages-en';
import { IPilot } from '@/types/IOrganisation';
import PilotsListItem from '@/components/PilotsListItem';
import LoadingAnimation from '@/components/LoadingAnimation';
import { searchParamsQuery } from '@/helpers/urlHandler';

interface IPilotsListPage {
  location: {
    search: string;
  };
}

const PilotsListPage: React.FC<IPilotsListPage> = ({ location }) => {
  const { orgId } = searchParamsQuery(location.search);
  const [data, setData] = useState<IPilot[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState(orgId || '');

  async function listPilots(
    newPage: number = page,
    newPageSize: number = pageSize,
    newSort: string = sort,
    newOrder: string = order,
    newSearch: string = search,
  ) {
    const res = await adminAPI.listPilots({
      page: newPage,
      pageSize: newPageSize,
      sort: newSort,
      order: newOrder,
      search: newSearch,
    });

    setData(res.data.data);
    setPage(newPage);
    setPageSize(newPageSize);

    return res.data;
  }

  return (
    <Layout pageName="Pilots">
      <div className="pb-8 pt-8 md:pt-0">
        <div className="pb-8 flex flex-col space-y-4 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
          <div className="flex flex-shrink-0 space-x-4">
            <Search
              id={`OrganisationsList-${page}-${pageSize}-${sort}-${order}-${search}`}
              search={search}
              setSearch={setSearch}
              fetchQuery={(searchQuery) =>
                listPilots(page, pageSize, sort, order, searchQuery)
              }
            />
            <Sort
              id={`OrganisationsList-${page}-${pageSize}-${sort}-${order}-${search}`}
              options={[
                {
                  sort: 'date',
                  order: 'desc',
                  label: 'Date created (Newest first)',
                },
                {
                  sort: 'date',
                  order: 'asc',
                  label: 'Date created (Oldest first)',
                },
              ]}
              sort={sort}
              setSort={setSort}
              order={order}
              setOrder={setOrder}
              fetchQuery={(sortQuery, orderQuery) =>
                listPilots(page, pageSize, sortQuery, orderQuery, search)
              }
            />
          </div>
        </div>
        {data && data.length > 0 ? (
          <div className="flex flex-col">
            <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
              <div className="block w-full lg:px-8">
                <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                  <ul
                    className={classNames(
                      isLoading ? 'opacity-40' : '',
                      'divide-y divide-gray-200',
                    )}
                  >
                    <li
                      key="OrganisationsListHeader"
                      className="hidden lg:block"
                    >
                      <div className="bg-gray-50">
                        <div className="flex items-center px-4 py-2 sm:px-6">
                          <div className="min-w-0 flex-1 flex items-start md:items-center">
                            <div className="min-w-0 flex-1 md:grid md:grid-cols-8 md:gap-4 xl:grid-cols-9">
                              <p className="col-span-1 text-sm font-medium text-gray-900">
                                ORG ID
                              </p>
                              <p className="md:mt-0 col-span-1 text-sm font-medium text-gray-900">
                                ORG NAME
                              </p>
                              <p className="md:mt-0 col-span-1 text-sm font-medium text-gray-900 hidden xl:block">
                                CREATED
                              </p>
                              <p className="col-span-1 text-sm font-medium text-gray-900">
                                LOGIN
                              </p>
                              <p className="col-span-1 text-sm font-medium text-gray-900">
                                STATUS
                              </p>
                              <p className="col-span-1 text-sm font-medium text-gray-900">
                                SUBSCRIPTION
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    {data?.map((item) => (
                      <PilotsListItem
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        saasEnabled={item.saas_enabled}
                        insertedAt={new Date(item.inserted_at)}
                        subscription={item.subscription}
                        totalProfiles={item.total_profiles}
                        platformSubscriptionStatus={
                          item.platform_subscription_status
                        }
                      />
                    ))}
                  </ul>
                  {isLoading && (
                    <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                      <LoadingAnimation className="h-16 w-16 text-brand-500 mx-auto" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-32">
            <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
              {MESSAGES.organisation.list.empty.heading}
            </h3>
            <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
              {MESSAGES.organisation.list.empty.description}
            </p>
          </div>
        )}
      </div>
      <Pagination
        id={`OrganisationsList-${page}-${pageSize}-${sort}-${order}-${search}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) =>
          listPilots(pageQuery, pageSizeQuery, sort, order, search)
        }
        setIsLoading={setIsLoading}
      />
    </Layout>
  );
};

export default PilotsListPage;
