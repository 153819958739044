import { Link } from 'react-router-dom';
import Logo from '@/assets/logos/primary.svg';
import PrivacyPolicy from '@/assets/documents/EU-Privacy-Policy-Tapt-By-Hatch.pdf';
import TandCs from '@/assets/documents/Website Terms and Conditions - Tapt By Hatch.pdf';

interface ILayoutStandardNotSticky {
  heading: string;
  description?: string;
  children: React.ReactNode;
}

const LayoutStandardNotSticky: React.FC<ILayoutStandardNotSticky> = (props) => {
  const { children, heading, description } = props;

  return (
    <div className="min-h-screen bg-white flex flex-col justify-between py-6 sm:px-6 lg:px-8">
      <div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img
              className="mx-auto h-24 w-24 mt-10 mb-10"
              src={Logo}
              alt="Tapt"
            />
          </Link>
          <h2 className="mt-6 mb-4 text-center text-2xl leading-8 font-medium text-gray-900">
            {heading}
            {import.meta.env.VITE_DISPLAY_ENV
              ? ` (${import.meta.env.VITE_DISPLAY_ENV})`
              : ``}
          </h2>
          {description && (
            <p className="mt-4 px-4 text-center leading-6 text-gray-900">
              {description}
            </p>
          )}
        </div>
        {children}
      </div>
      <div className="hidden lg:block">
        <ul className="mt-6 flex justify-center flex-wrap">
          <li className="inline-flex">
            <a
              className="py-0 px-5 text-base leading-6 font-normal text-gray-500 hover:text-gray-700"
              href="https://help.tapt.io"
              target="_blank"
              rel="noreferrer"
            >
              Help
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-0 px-5 text-base leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={PrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-0 px-5 text-base leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={TandCs}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </li>
        </ul>
      </div>
      <div className="lg:hidden">
        <ul className="mt-6 flex justify-center flex-wrap">
          <li className="inline-flex">
            <a
              className="py-0 px-5 mb-0 text-xs leading-6 font-normal text-gray-500 hover:text-gray-700"
              href="https://help.tapt.io"
              target="_blank"
              rel="noreferrer"
            >
              Help
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-0 px-5 mb-0 text-xs leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={PrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="inline-flex">
            <a
              className="py-0 px-5 mb-0 text-xs leading-6 font-normal text-gray-500 hover:text-gray-700"
              href={TandCs}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export { LayoutStandardNotSticky as default };
