import InfoPanelContainer from '@/components/InfoPanelContainer';
import InputCheckbox from '@/components/InputCheckbox';
import CardDesigns from '@/components/ReOrderPage/shared/CardDesign';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import { IStep1 } from '@/types/ReOrderExperience/IStep1';

const DesignSpecification = (props: IStep1) => {
  const {
    orgID,
    variables,
    selectedDesign,
    setSelectedDesign,
    initial,
    setInitial,
  } = props;

  return (
    <>
      <InfoPanelContainer
        title="Select design"
        information="Select a design template for you card/s."
        additionalContents={
          <span className=" text-sm text-gray-500">
            You can select a previously uploaded design, or upload a new design
            for a fresh, new look!
          </span>
        }
      >
        <div>
          {orgID && (
            <CardDesigns
              orgID={orgID}
              selectedDesign={selectedDesign}
              setSelectedDesign={setSelectedDesign}
              initial={initial}
              setInitial={setInitial}
            />
          )}
        </div>
      </InfoPanelContainer>
      <InfoPanelDivider />

      <InfoPanelContainer
        title="Variable Information"
        information="
              Select which contact details will be different on each card.
      "
      >
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3 md:col-span-6">
            <div className="grid sm:grid-cols-3 gap-2">
              {variables.map(({ id, name, toggle }, index) => {
                return (
                  <div key={id} className="block bg-white ">
                    <div className="flex items-center py-1 px-2">
                      <div className="self-center">
                        <InputCheckbox
                          id={`Checkbox-${id}`}
                          label={name}
                          value={variables[index].selected}
                          onChange={(value) => toggle(value)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </InfoPanelContainer>
    </>
  );
};

export default DesignSpecification;
