import Button, { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button';
import MESSAGES from '@/constants/messages-en';

interface IProfilePageAddToContactsButton {
  className?: string;
  fullWidth?: boolean;
  href: string;
}

const ProfilePageAddToContactsButton: React.FC<
  IProfilePageAddToContactsButton
> = ({ className, fullWidth, href }) => {
  const BUTTON_LABEL =
    MESSAGES.profile.view.addToContactsCtaLabel ||
    'MESSAGES.profile.view.addToContactsCtaLabel';
  return (
    <Button
      buttonText={BUTTON_LABEL}
      className={className}
      external
      externalBlankTarget={false}
      fullWidth={fullWidth}
      href={href}
      kind={BUTTON_KIND.COLOR_OMITTED}
      size={BUTTON_SIZE.XLARGE}
    />
  );
};

export { ProfilePageAddToContactsButton as default };
