import { useEffect, useRef, useState } from 'react';

const useIsMounted = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

const useProgressiveImg = (
  lowQualitySrc: string,
  highQualitySrc: string,
): [string, { blur: boolean }] => {
  const isMounted = useIsMounted();
  const [src, setSrc] = useState(lowQualitySrc);

  useEffect(() => {
    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      if (isMounted.current) {
        setSrc(highQualitySrc);
      }
    };
  }, [isMounted, lowQualitySrc, highQualitySrc]);

  return [
    src,
    { blur: src === lowQualitySrc && lowQualitySrc !== highQualitySrc },
  ];
};

export default useProgressiveImg;
