import { useHistory } from 'react-router-dom';
import Button, { BUTTON_KIND } from './Button';

interface IAnalyticsListItem {
  id: number;
  name: string;
  orgID: number;
  viewOrganisation: string;
  viewCount: number;
  contactsCount: number;
  profilesCount: number;
}

export default function AnalyticsListAdminItem({
  id,
  name,
  orgID,
  viewOrganisation,
  viewCount,
  contactsCount,
  profilesCount,
}: IAnalyticsListItem): JSX.Element {
  const history = useHistory();

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-4 xl:grid-cols-5 md:gap-4">
              <div className="col-span-4 md:col-span-1 w-full">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {name}
                </p>
                <p className="flex items-center text-sm text-gray-500">
                  <span className="md:hidden">Org:&nbsp;</span>
                  <span className="truncate">#{orgID}</span>
                </p>
              </div>
              <div className="col-span-4 md:col-span-1 flex items-center">
                <p className="text-sm text-gray-900">
                  <span className="md:hidden">Total profiles: </span>
                  {profilesCount}
                </p>
              </div>
              <div className="col-span-4 md:col-span-1 md:hidden xl:flex items-center">
                <p className="text-sm text-gray-900">
                  <span className="md:hidden">Total page visits: </span>
                  {viewCount}
                </p>
              </div>
              <div className="col-span-4 md:col-span-1 flex items-center">
                <p className="text-sm text-gray-900">
                  <span className="md:hidden">Total contacts: </span>
                  {contactsCount}
                </p>
              </div>
              <div className="mt-2 md:mt-0 col-span-4 md:col-span-1 flex items-center md:justify-end">
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="View organisation"
                  onClick={() =>
                    history.push(viewOrganisation, {
                      fromName: 'Analytics',
                      fromURL: '/analytics',
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
