import { useState } from 'react';

import { EyeIcon, UserCircleIcon } from '@heroicons/react/24/outline';

import { useCopyToClipboard } from 'usehooks-ts';

import { Alert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import Copy from '@/components/Icons/Copy';
import { gray } from '@/constants/colors';
import { getVariantCategory, getVariantFullName } from '@/helpers/products';
import {
  adjustToLocalTime,
  getFullName,
  hashTruncate,
} from '@/helpers/strings';
import { ICardUser } from '@/types/ICard';

import { ProfileDisplay } from './ProfileDisplay';
import { ReassignCardModal } from './ReassignCardModal';

type ProductItemRowProps = {
  card: ICardUser;
  setActionResult: React.Dispatch<React.SetStateAction<Alert | undefined>>;
  refreshCards: () => void;
};

export function ProductItemRow({
  card,
  setActionResult,
  refreshCards,
}: ProductItemRowProps) {
  const { profile, type, card_hash: cardHash, updated_at: updatedAt } = card;
  const {
    first_name: firstName,
    last_name: lastName,
    email,
    profile_page_url: profilePageUrl,
  } = profile;

  const [, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);

  async function copyHashToClipboard() {
    await copy(cardHash);

    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }

  const fullName = getFullName({
    firstName,
    middleName: null,
    lastName,
  });
  const [date, time] = adjustToLocalTime(updatedAt).split(' ');

  const photoUrl = profile.photo?.thumb_url;
  const isDeleted = profile.status === 'deleted';

  const renderViewIcon = () => {
    return isDeleted ? null : (
      <div className="flex justify-center">
        <a href={profilePageUrl} target="_blank">
          <EyeIcon className="w-6 h-6" color={gray['900']} strokeWidth={1.8} />
        </a>
      </div>
    );
  };

  const renderAssignButton = () => {
    return isDeleted ? (
      <Button
        buttonText="Assign"
        kind={BUTTON_KIND.PRIMARY}
        onClick={() => setIsReassignModalOpen(true)}
      />
    ) : (
      <Button
        buttonText="Reassign"
        kind={BUTTON_KIND.WHITE}
        onClick={() => setIsReassignModalOpen(true)}
      />
    );
  };

  return (
    <tr className="hover:bg-gray-50 relative">
      <td className="py-3 px-4">
        <div className="flex flex-col">
          <span className="text-sm font-medium">
            {getVariantFullName(type)}
          </span>
          <div>
            <span className="font-light text-sm text-gray-500 flex items-center">
              ID:&nbsp;
              <button
                className="text-brand-600 font-medium flex items-center gap-0.5"
                onClick={e => {
                  e.stopPropagation();
                  copyHashToClipboard();
                }}
                disabled={isCopied}
              >
                {isCopied ? 'Copied' : hashTruncate(cardHash)}
                <Copy className="w-4 h-4" />
              </button>
            </span>
          </div>
        </div>
      </td>
      <td className="flex flex-col text-sm p-3">
        <span className="text-gray-900 font-medium">{date}</span>
        <span className="text-gray-500">{time}</span>
      </td>
      <td className="p-3">
        <ProfileDisplay
          photoUrl={photoUrl}
          fullName={fullName}
          email={email}
          isDeleted={isDeleted}
        />
      </td>
      <td className="p-3">{renderViewIcon()}</td>
      <td className="py-3 px-4">
        <div className="flex justify-center">{renderAssignButton()}</div>
      </td>
      <ReassignCardModal
        isOpen={isReassignModalOpen}
        setIsOpen={setIsReassignModalOpen}
        cardHash={cardHash}
        product={getVariantCategory(type)}
        setActionResult={setActionResult}
        onSuccessCallback={refreshCards}
      />
    </tr>
  );
}

export function ProductItemRowMobile({
  card,
  setActionResult,
  refreshCards,
}: ProductItemRowProps) {
  const { card_hash: cardHash, type, updated_at: updatedAt, profile } = card;
  const {
    first_name: firstName,
    last_name: lastName,
    email,
    profile_page_url: profilePageUrl,
  } = profile;

  const [, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const [isReassignCardModalOpen, setIsReassignCardModalOpen] = useState(false);
  const isDeleted = profile.status === 'deleted';

  async function copyHashToClipboard() {
    await copy(cardHash);

    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }

  const fullName = getFullName({
    firstName,
    middleName: null,
    lastName,
  });
  const withFullName = fullName ? `: ${fullName}` : '';
  const [date, time] = adjustToLocalTime(updatedAt).split(' ');

  const fields = [
    {
      label: getVariantFullName(type),
      value: (
        <span className="flex items-center">
          ID:&nbsp;
          <button
            className="flex items-center font-medium text-brand-600 appearance-none"
            onClick={copyHashToClipboard}
            disabled={isCopied}
          >
            {isCopied ? 'Copied' : hashTruncate(cardHash)}
            <Copy className="w-4 h-4" />
          </button>
        </span>
      ),
    },
    {
      label: 'Created',
      value: (
        <>
          {date} at {time}
        </>
      ),
    },
    isDeleted
      ? {
          label: (
            <>
              <p className="text-red-800">Deleted profile{withFullName}</p>
              <span className="text-red-800 font-normal text-wrap">
                Assign new profile to continue using the product.
              </span>
            </>
          ),
          value: '',
        }
      : {
          label: fullName,
          value: (
            <span className="flex text-gray-500 text-sm truncate w-2/3 justify-end">
              <span className="truncate">{email || ''}</span>
            </span>
          ),
        },
  ];

  return (
    <div className="border border-gray-300 bg-white rounded-md flex flex-col divide-y divide-gray-300">
      <div className="flex flex-col p-6">
        {fields.map((field, index) => (
          <div
            key={index}
            className="flex justify-between py-1 items-center text-gray-500 text-sm"
          >
            <span className="font-medium text-gray-900">{field.label}</span>
            {field.value}
          </div>
        ))}
      </div>
      <div className="flex flex-row divide-x divide-gray-300">
        <a
          href={profilePageUrl}
          target="_blank"
          className="flex items-center justify-center w-1/2 p-4 gap-2"
        >
          <EyeIcon className="w-5 h-5" color={gray['500']} />
          <span
            className="font-normal text-sm"
            style={{
              color: gray['700'],
            }}
          >
            View profile
          </span>
        </a>
        <button
          type="button"
          className="flex items-center justify-center w-1/2 p-4 gap-2"
          onClick={() => setIsReassignCardModalOpen(true)}
        >
          <UserCircleIcon className="w-5 h-5" color={gray['500']} />
          <span className="text-gray-700 font-normal text-sm">Reassign</span>
        </button>
      </div>
      <ReassignCardModal
        isOpen={isReassignCardModalOpen}
        setIsOpen={setIsReassignCardModalOpen}
        cardHash={cardHash}
        product={getVariantCategory(type)}
        setActionResult={setActionResult}
        onSuccessCallback={refreshCards}
      />
    </div>
  );
}
