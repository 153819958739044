interface IQuery {
  [key: string]: string;
}

const searchParamsQuery = (params: string): IQuery => {
  const query: IQuery = {};
  if (params) {
    const pairs = (params[0] === '?' ? params.substr(1) : params).split('&');
    for (let i = 0; i < pairs.length; i += 1) {
      const pair = pairs[i].split('=');
      const key = decodeURIComponent(pair[0]);
      query[key] = decodeURIComponent(pair[1] || '');
    }
  }
  return query;
};

const buildNewUrl = (queries: IQuery): string => {
  let newSearchParams = '';
  Object.keys(queries).forEach((key) => {
    if (key && key !== null && queries[key] !== null) {
      if (newSearchParams !== '') {
        newSearchParams += `&${key}=${queries[key]}`;
      } else {
        newSearchParams = `?${key}=${queries[key]}`;
      }
    }
  });
  return newSearchParams;
};

export { searchParamsQuery as default, searchParamsQuery, buildNewUrl };
