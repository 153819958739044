const stringHasContent = (string?: string | null) => {
  if (typeof string === 'undefined' || string === null) {
    return false;
  }

  const array = /(.|\s)*\S(.|\s)*/.exec(string);
  if (array && array.length >= 0) {
    return true;
  }

  return false;
};

const hashTruncate = (str: string) => {
  const stringLength = str.length;
  const truncatedString = [
    str.slice(0, 1),
    '...',
    str.slice(stringLength - 2),
  ].join('');

  return truncatedString;
};

const getFullName = ({
  firstName,
  middleName,
  lastName,
}: {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
}) => {
  return [firstName, middleName, lastName].filter(Boolean).join(' ') || null;
};

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const adjustToLocalTime = (datetimeString: string) => {
  const hasTime = datetimeString.includes(':');

  const utcString = hasTime ? datetimeString + 'Z' : datetimeString + 'T00:00Z';

  const date = new Date(utcString);

  if (hasTime) {
    return date
      .toLocaleString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .replace(', ', ' ');
  } else {
    return date.toLocaleDateString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
};

const formatDateToLocalISOString = (date: Date) => {
  const year = date.getFullYear(); // Gets the year (e.g., 2024)
  const month = date.getMonth() + 1; // getMonth() is zero-based, add 1 to get 1-12 for months
  const day = date.getDate(); // Gets the day of the month (1-31)

  // Pad the month and day with leading zeros if necessary
  const formattedMonth = month < 10 ? '0' + month : month;
  const formattedDay = day < 10 ? '0' + day : day;

  return `${year}-${formattedMonth}-${formattedDay}`;
};

export async function copyToClipboard(value: string, callback?: () => void) {
  await navigator.clipboard.writeText(value);
  callback?.();
}

export {
  stringHasContent as default,
  stringHasContent,
  hashTruncate,
  getFullName,
  capitalize,
  adjustToLocalTime,
  formatDateToLocalISOString,
};
