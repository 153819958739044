import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { IFulfilledOrder } from '@/types/IAnalytics';

const defaultOptions: ApexOptions = {
  plotOptions: {
    area: {
      fillTo: 'end',
    },
    bar: {
      borderRadius: 6,
    },
  },
  tooltip: {
    style: {
      fontSize: '14px',
    },
  },
  chart: {
    fontFamily: 'Poppins, sans-serif',
    toolbar: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '14px',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: '14px',
      },
    },
  },
};

const defaultHeight = 200;

type FulfilledOrdersProps = {
  data: IFulfilledOrder[];
  title: string;
  subtitle: string;
};

export const formatNumber = (num: number) => {
  const formatter = new Intl.NumberFormat('en-AU');
  return formatter.format(num);
};

function FulfilledOrders({ data, title, subtitle }: FulfilledOrdersProps) {
  const series: ApexOptions['series'] = [
    {
      name: 'Orders',
      data: data.map((item) => item.total),
      color: '#873CFF',
    },
  ];
  const labels: ApexOptions['labels'] = data.map(
    (item) => `${item.first_name} ${item.last_name}`,
  );

  return (
    <div className="flex flex-col min-h-full min-w-full justify-between">
      <div>
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex justify-between items-center flex-wrap">
            <div className="text-gray-900 font-medium text-2xl md:text-2xl">
              {title}
            </div>
          </div>
        </div>
        <h3 className="text-gray-500 mb-2 hidden md:block">{subtitle}</h3>
        <hr className="h-px mt-4 mb-8 bg-gray-200 border-0 " />
      </div>
      <div>
        <Chart
          height={defaultHeight}
          options={{ ...defaultOptions, labels }}
          series={series}
          type="bar"
        />
      </div>
    </div>
  );
}

export default FulfilledOrders;
