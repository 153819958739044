import { useState } from 'react';

import { UserIcon } from '@heroicons/react/20/solid';

import Copy from '@/components/Icons/Copy';
import { hashTruncate } from '@/helpers/strings';

interface IAnalyticsListItem {
  id: number;
  profileHash: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  emailAddress?: string;
  photoUrl?: string;
  viewProfile: string;
  viewCount: number;
  contactsCount: number;
  setProfileIds: (ids: number[]) => void;
}

export default function AnalyticsListItem({
  id,
  profileHash,
  firstName,
  lastName,
  title,
  emailAddress,
  photoUrl,
  viewCount,
  contactsCount,
}: IAnalyticsListItem): JSX.Element {
  const [clipboardCopy, setClipboardCopy] = useState(false);

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-4 xl:grid-cols-7 md:gap-4">
              <div className="col-span-4 md:col-span-3 relative">
                <div className="flex space-x-4 relative">
                  <div>
                    {photoUrl ? (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={photoUrl}
                        alt=""
                      />
                    ) : (
                      <div className="h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                        <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                      </div>
                    )}
                  </div>
                  <div className="min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {firstName} {lastName}
                    </p>
                    <p className="flex text-sm font-medium text-gray-500">
                      <span className="md:hidden">Connection&nbsp;</span>
                      ID:&nbsp;
                      <button
                        type="button"
                        className="appearance-none text-brand-600 text-sm font-medium flex items-center"
                        onClick={() => {
                          navigator.clipboard
                            .writeText(profileHash)
                            .then(() => setClipboardCopy(true))
                            .finally(() =>
                              setTimeout(() => {
                                setClipboardCopy(false);
                              }, 2000),
                            );
                        }}
                      >
                        {clipboardCopy ? 'Copied!' : hashTruncate(profileHash)}
                        <Copy className="h-4 w-4 ml-1" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:hidden ml-14 md:ml-0 col-span-4 xl:col-span-2 xl:flex flex-col">
                <p className="text-sm text-gray-900 truncate">{title}</p>
                <p className="text-sm text-gray-500 truncate">{emailAddress}</p>
              </div>
              <div className="ml-14 md:ml-0 col-span-4 md:col-span-1 flex items-center">
                <p className="text-sm text-gray-900">
                  <span className="md:hidden">Total page visits: </span>
                  {viewCount}
                </p>
              </div>
              <div className="ml-14 md:ml-0 col-span-4 md:col-span-1 flex items-center">
                <p className="text-sm text-gray-900">
                  <span className="md:hidden">Total contacts: </span>
                  {contactsCount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
