import { useState } from 'react';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import useAuth from '@/hooks/useAuth';
import analyticsAPI from '@/api/analytics';
import AnalyticsListItem from '@/components/AnalyticsListItem';
import MESSAGES from '@/constants/messages-en';
import Sort from '@/components/Sort';
import LoadingAnimation from '@/components/LoadingAnimation';
import clsx from 'clsx';
import organisationsAPI from '@/api/organisations';
import { useQuery } from 'react-query';

type SimpleAnalyticsProps = {
  setProfileIds: (ids: number[]) => void;
};

function SimpleAnalytics({ setProfileIds }: SimpleAnalyticsProps) {
  const { orgID, user, userRole } = useAuth();

  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('page_views');
  const [order, setOrder] = useState('desc');
  const [editorsGroup, setEditorsGroup] = useState<number[]>([]);

  const { isFetching: isGroupsFetching, isFetched: isGroupsReady } = useQuery(
    'listGroups',
    fetchGroups,
    {
      enabled: orgID !== undefined && user !== undefined,
    },
  );

  const { data: analytics } = useQuery(
    ['oldListAnalytics', page, pageSize, sort, order, search, editorsGroup],
    () => {
      return listAnalytics();
    },
    {
      enabled: orgID !== undefined && isGroupsReady && !isGroupsFetching,
    },
  );

  async function fetchGroups() {
    if (orgID === undefined || user === undefined) {
      return;
    }

    const { data: groups } = await organisationsAPI.listOrganisationGroups({
      orgID,
    });

    const groupList: number[] = [];

    groups.data.forEach((group) => {
      if (group.editors.length > 0) {
        group.editors.forEach((editor) => {
          if (editor.user.id === user.id) {
            groupList.push(group.id);
          }
        });
      }
    });

    setEditorsGroup(groupList);

    return groups;
  }

  async function listAnalytics(
    newPage: number = page,
    newPageSize: number = pageSize,
    newSort: string = sort,
    newOrder: string = order,
    newSearch: string = search,
    newGroupIDs: number[] = editorsGroup,
  ) {
    if (userRole === 'org_editor' && editorsGroup.length === 0) {
      return {
        data: [],
        paging: {
          page_number: 0,
          page_offset: 0,
          page_size: pageSize,
          total_entries: 0,
          total_pages: 0,
        },
      };
    }

    const { data: analytics } = await analyticsAPI.oldListAnalytics({
      orgID,
      page: newPage,
      pageSize: newPageSize,
      sort: newSort,
      order: newOrder,
      search: newSearch,
      groupIDs: newGroupIDs,
    });

    setPage(newPage);
    setPageSize(newPageSize);
    setSort(newSort);
    setOrder(newOrder);

    return analytics;
  }

  return (
    <>
      <div className="pb-8 pt-8 md:pt-0">
        <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
          <div className="flex flex-shrink-0 space-x-3">
            <Search
              id={`Analytics-${page}-${pageSize}-${sort}-${order}-${search}`}
              search={search}
              setSearch={setSearch}
              fetchQuery={(newSearch) =>
                listAnalytics(
                  page,
                  pageSize,
                  sort,
                  order,
                  newSearch,
                  editorsGroup,
                )
              }
              fetchEnabled={!isGroupsFetching}
            />
            <Sort
              id={`Analytics-${page}-${pageSize}-${sort}-${order}-${search}`}
              options={[
                {
                  sort: 'page_views',
                  order: 'desc',
                  label: 'Page visits (Highest first)',
                },
                {
                  sort: 'page_views',
                  order: 'asc',
                  label: 'Page visits (Lowest first)',
                },
                {
                  sort: 'contacts',
                  order: 'desc',
                  label: 'Contacts (Highest first)',
                },
                {
                  sort: 'contacts',
                  order: 'asc',
                  label: 'Contacts (Lowest first)',
                },
              ]}
              sort={sort}
              setSort={setSort}
              order={order}
              setOrder={setOrder}
              fetchQuery={(newSort, newOrder) =>
                listAnalytics(
                  page,
                  pageSize,
                  newSort,
                  newOrder,
                  search,
                  editorsGroup,
                )
              }
              fetchQueryEnabled={!isGroupsFetching}
            />
          </div>
        </div>
        {analytics ? (
          analytics.data.length > 0 ? (
            <div className="flex flex-col">
              <div className="relative md:mx-0">
                <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                  <ul
                    className={clsx('divide-y divide-gray-200', {
                      'opacity-40': isPaginationLoading,
                    })}
                  >
                    <li key="AnalyticsListHeader" className="hidden md:block">
                      <div className="bg-gray-50 items-center">
                        <div className="flex items-center px-4 py-2 sm:px-6 flex-1 w-full">
                          <div className="min-w-0 flex-1 flex items-start md:items-center">
                            <div className="min-w-0 flex-1 md:grid md:grid-cols-4 xl:grid-cols-7 md:gap-4">
                              <p className="md:col-span-3 text-sm font-medium text-gray-900">
                                PROFILE
                              </p>
                              <p className="mt-2 md:mt-0 hidden xl:block md:col-span-2 text-sm font-medium text-gray-900">
                                POSITION
                              </p>
                              <p className="mt-2 md:mt-0 xl:col-span-1 text-sm font-medium text-gray-900">
                                TOTAL PAGE VISITS
                              </p>
                              <p className="mt-2 md:mt-0 xl:col-span-1 text-sm font-medium text-gray-900">
                                TOTAL CONTACTS
                              </p>
                            </div>
                          </div>
                          <div className="w-5" />
                        </div>
                      </div>
                    </li>
                    {analytics.data.map((item, index) => (
                      <AnalyticsListItem
                        key={index}
                        id={item.profile_id}
                        firstName={item.first_name}
                        lastName={item.last_name}
                        title={item.job_title}
                        emailAddress={item.email}
                        photoUrl={item.photo?.thumb_url}
                        viewCount={item.analytics_view_count}
                        contactsCount={item.total_contacts}
                        profileHash={item.profile_hash}
                        viewProfile={`/view-profile/${item.profile_hash}`}
                        setProfileIds={setProfileIds}
                      />
                    ))}
                  </ul>
                  {isPaginationLoading && (
                    <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                      <LoadingAnimation className="h-16 w-16 text-brand-500 mx-auto" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.analytics.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.analytics.list.empty.description}
              </p>
            </div>
          )
        ) : (
          <div className="flex flex-col justify-center min-h-screen py-6 bg-white sm:px-6 lg:px-8">
            <div>
              <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
            </div>
          </div>
        )}
      </div>
      <Pagination
        id={`Analytics-${page}-${pageSize}-${sort}-${order}-${search}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(newPage, newPageSize) =>
          listAnalytics(newPage, newPageSize, sort, order, search, editorsGroup)
        }
        setIsLoading={setIsPaginationLoading}
        fetchQueryEnabled={!isGroupsFetching}
      />
    </>
  );
}

export default SimpleAnalytics;
