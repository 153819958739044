import { useState } from 'react';

import { Menu } from '@headlessui/react';

import { clsx } from 'clsx';

import Button, { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import Modal from '@/components/Modals/Modal';
import {
  BaseTab,
  CARD_STATUS,
  IBaseTabState,
  IItem,
} from '@/components/ProductsAdminPage/BaseTab';
import ActivationKeyCell from '@/components/ProductsAdminPage/Cell/ActivationKeyCell';
import CardUrlCell from '@/components/ProductsAdminPage/Cell/CardUrlCell';
import CreatedCell from '@/components/ProductsAdminPage/Cell/CreatedCell';
import TypeCell from '@/components/ProductsAdminPage/Cell/TypeCell';
import ActionsMenu from '@/components/ProductsAdminPage/Menu/ActionsMenu';

import ConnectProfileModal from './Modal/ConnectProfileModal';

const Header: React.FC = () => (
  <>
    <th className="font-medium p-4 pb-3">TYPE &amp; ID</th>
    <th className="font-medium p-4 pb-3">CREATED</th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell">
      ACTIVATION KEY
    </th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell w-40 text-center">
      LINK TO PROFILE
    </th>
    <th className="font-medium p-4 pb-3 hidden md:table-cell w-16">URL</th>
    <th className="font-medium p-4 pb-3 w-20">OTHER</th>
  </>
);

const Item: React.FC<IItem> = ({
  card,
  selected,
  checkItem,
  deleteProduct,
  markAsUnprogrammed,
  connectProfile,
}) => {
  const [isMarkAsUnprogrammedModalOpen, setIsMarkAsUnprogrammedModalOpen] =
    useState(false);
  const [isMarkAsUnprogrammedConfirmed, setIsMarkAsUnprogrammedConfirmed] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [isConnectProfileModalOpen, setIsConnectProfileModalOpen] =
    useState(false);

  const id = card.card_hash;
  const status = CARD_STATUS.PROGRAMMED;

  return (
    <tr key={id}>
      <td className="p-4 pb-3 w-[5%]">
        <InputCheckbox
          id={`Checkbox-${status}-${id}`}
          label=""
          labelClassName=""
          value={selected}
          onChange={checkItem}
        />
      </td>
      <TypeCell card={card} />
      <CreatedCell card={card} />
      <ActivationKeyCell card={card} />
      <td className="font-medium p-4 pb-3 hidden md:table-cell">
        <div className="flex items-center justify-center">
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Select profile"
            onClick={() => {
              setIsConnectProfileModalOpen(true);
            }}
          />
        </div>
      </td>
      <CardUrlCell card={card} />
      <ActionsMenu card={card}>
        <Menu.Item as="div">
          <button
            type="button"
            onClick={() => {
              setIsMarkAsUnprogrammedModalOpen(true);
            }}
            className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
          >
            Mark as unprogrammed
          </button>
        </Menu.Item>
        <Menu.Item as="div">
          <button
            type="button"
            onClick={() => {
              setIsDeleteModalOpen(true);
            }}
            className="text-red-450 w-full text-left block px-4 py-2 text-sm"
          >
            Delete product
          </button>
        </Menu.Item>
      </ActionsMenu>
      {isMarkAsUnprogrammedModalOpen && (
        <Modal
          isOpen={isMarkAsUnprogrammedModalOpen}
          setIsOpen={setIsMarkAsUnprogrammedModalOpen}
          onCancel={() => {
            setIsMarkAsUnprogrammedModalOpen(false);
          }}
          dialogTitle="Are you sure you want to mark this card as unprogrammed?"
          onSuccess={() => {
            markAsUnprogrammed &&
              markAsUnprogrammed(card).then(() => {
                setIsMarkAsUnprogrammedConfirmed(false);
                setIsMarkAsUnprogrammedModalOpen(false);
              });
          }}
          checkbox={isMarkAsUnprogrammedConfirmed}
          setCheckbox={setIsMarkAsUnprogrammedConfirmed}
          isDisabled={!isMarkAsUnprogrammedConfirmed}
          checkboxDescription="I am sure I want to mark this card as unprogrammed."
          successButtonText="Confirm"
          successButtonKind={BUTTON_KIND.RED}
        />
      )}
      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onCancel={() => {
            setIsDeleteModalOpen(false);
          }}
          dialogTitle="Deleting this product is permanent."
          dialogDescription="By proceeding, you acknowledge that you will permanently delete this card."
          onSuccess={() => {
            deleteProduct &&
              deleteProduct(card).then(() => {
                setIsDeleteConfirmed(false);
                setIsDeleteModalOpen(false);
              });
          }}
          checkbox={isDeleteConfirmed}
          setCheckbox={setIsDeleteConfirmed}
          isDisabled={!isDeleteConfirmed}
          checkboxDescription="I am sure I want to delete this card."
          successButtonText="Confirm"
          successButtonKind={BUTTON_KIND.RED}
        />
      )}
      {isConnectProfileModalOpen && (
        <ConnectProfileModal
          isOpen={isConnectProfileModalOpen}
          setIsOpen={setIsConnectProfileModalOpen}
          card={card}
          handleConnectProfile={connectProfile}
        />
      )}
    </tr>
  );
};

export const UnconnectedTab: React.FC<IBaseTabState> = props => {
  return (
    <BaseTab
      {...props}
      name="Unconnected"
      status={CARD_STATUS.PROGRAMMED}
      header={Header}
      item={Item}
    />
  );
};
