import { useRef, useEffect } from 'react';
import Lottie from 'lottie-web';
import DownloadAppLottie from '@/assets/lotties/download-app-animation.json';

export const ActivationPageAnimation = () => {
  const lottieContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const downloadAppLottie = Lottie.loadAnimation({
      name: 'DownloadApp',
      container: lottieContainerRef.current as Element,
      animationData: DownloadAppLottie,
      autoplay: true,
      loop: true,
    });
  }, []);

  return (
    <div
      id="DownloadApp"
      ref={lottieContainerRef}
      className="max-h-[23.5rem] h-[18rem] sm:h-[23.5rem] mt-8 mb-14"
    />
  );
};
