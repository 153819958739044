import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  DevicePhoneMobileIcon,
  PencilIcon,
  QrCodeIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';

import profilesAPI from '@/api/profiles';
import Modal from '@/components/Modals/Modal';
import { QRCodeFileSelectionModal } from '@/components/ProfilesListPage/QRCode';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import { IProfileAppAccess, IProfileBase } from '@/types/IProfile';

import { Alert } from '../Alert';
import Button, { BUTTON_KIND } from '../Button';
import { AddProfilesToGroupModal } from './AddProfilesToGroupModal';

type ProfilesPageFooterProps = {
  selectedProfiles: IProfileBase[];
  setActionResult: React.Dispatch<React.SetStateAction<Alert | undefined>>;
  onActionSuccess?: () => void;
  refreshProfiles: () => void;
  hideEditSharedInfo?: boolean;
};

export function ProfilesPageFooter({
  selectedProfiles,
  setActionResult,
  onActionSuccess,
  refreshProfiles,
  hideEditSharedInfo = false,
}: ProfilesPageFooterProps) {
  const history = useHistory();
  const { orgID, userRole } = useAuth();

  const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false);
  const [isAppInviteOpen, setIsAppInviteOpen] = useState(false);
  const [isResendAppInviteOpen, setIsResendAppInviteOpen] = useState(false);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [isEditSharedOpen, setIsEditSharedOpen] = useState(false);
  const [isInviting, setIsInviting] = useState(false);

  const MAX_INVITATION_COUNT = 50;

  const noOfInvitableAccounts = selectedProfiles.filter(
    i => i.app_access !== IProfileAppAccess.connected,
  ).length;
  const hasPendingAppInvite = selectedProfiles.some(
    i => i.app_access === IProfileAppAccess.pending,
  );
  const hasProfileWithoutEmail = selectedProfiles.some(p => !p.email);

  async function inviteToApp() {
    if (orgID === undefined) {
      return;
    }

    try {
      if (selectedProfiles.length > MAX_INVITATION_COUNT) {
        setActionResult({
          outcome: 'error',
          message: `You can only send up to ${MAX_INVITATION_COUNT} invitations at once.`,
        });
        return;
      }

      if (hasProfileWithoutEmail) {
        setActionResult({
          outcome: 'error',
          message: MESSAGES.error.appInviteError,
        });
        return;
      }

      setIsInviting(true);

      await profilesAPI.inviteUsers(
        orgID,
        selectedProfiles
          .filter(p => p.app_access !== IProfileAppAccess.connected)
          .map(({ id }) => id),
      );

      setActionResult({
        outcome: 'success',
        message: 'App invite successfully sent',
      });
      refreshProfiles();

      onActionSuccess?.();
    } catch (error: unknown) {
      setActionResult({ outcome: 'error', message: MESSAGES.error.generic });
    } finally {
      setIsResendAppInviteOpen(false);
      setIsAppInviteOpen(false);
      setIsInviting(false);
    }
  }

  return (
    <div className="flex flex-wrap items-center justify-end gap-4">
      <p className="text-sm leading-5 text-gray-500">
        {selectedProfiles.length} selected
      </p>
      {userRole === 'org_admin' && (
        <Button
          buttonText="Add to group"
          kind={BUTTON_KIND.WHITE}
          icon={<UserGroupIcon />}
          onClick={() => setIsAddToGroupModalOpen(true)}
        />
      )}
      <AddProfilesToGroupModal
        isOpen={isAddToGroupModalOpen}
        setIsOpen={setIsAddToGroupModalOpen}
        checkedProfiles={selectedProfiles}
        onSuccessCallback={() => {
          setActionResult({
            outcome: 'success',
            message: 'Profiles were added to group successfully.',
          });

          refreshProfiles();
          onActionSuccess?.();
        }}
      />
      <Button
        buttonText="Invite to app"
        kind={BUTTON_KIND.WHITE}
        icon={<DevicePhoneMobileIcon />}
        onClick={
          hasPendingAppInvite
            ? () => setIsResendAppInviteOpen(true)
            : () => setIsAppInviteOpen(true)
        }
        disabled={noOfInvitableAccounts === 0}
      />
      <Modal
        isOpen={isAppInviteOpen}
        setIsOpen={setIsAppInviteOpen}
        dialogTitle={MESSAGES.profile.appInvite.send.heading}
        dialogDescription={MESSAGES.profile.appInvite.send.description}
        successButtonText="Send invite"
        onSuccess={inviteToApp}
        isLoading={isInviting}
      />
      <Modal
        isOpen={isResendAppInviteOpen}
        setIsOpen={setIsResendAppInviteOpen}
        dialogTitle={MESSAGES.profile.appInvite.resend.heading}
        dialogDescription={MESSAGES.profile.appInvite.resend.description}
        successButtonText="Send invite"
        onSuccess={inviteToApp}
        isLoading={isInviting}
      />
      <Button
        buttonText="Export QR Codes"
        kind={BUTTON_KIND.WHITE}
        icon={<QrCodeIcon />}
        onClick={() => setIsQRCodeModalOpen(true)}
      />
      <QRCodeFileSelectionModal
        isOpen={isQRCodeModalOpen}
        setIsOpen={setIsQRCodeModalOpen}
        checkedProfiles={selectedProfiles.map(({ id }) => id)}
        onSuccessCallback={onActionSuccess}
      />
      {!hideEditSharedInfo && (
        <Modal
          isOpen={isEditSharedOpen}
          setIsOpen={setIsEditSharedOpen}
          buttonTitle="Edit shared info"
          dialogTitle="Editing shared profile information"
          dialogDescription={
            <>
              This feature enables you to add information to multiple profiles
              at once.
              <br />
              <br />
              <span className="font-medium">Important:</span> Changes you make
              will overwrite existing information on the selected profiles.
            </>
          }
          buttonKind={BUTTON_KIND.WHITE}
          icon={<PencilIcon />}
          onSuccess={() => history.push(`/edit-shared-profile`)}
          successButtonText="Ok got it"
        />
      )}
    </div>
  );
}
