import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Lottie, { AnimationItem } from 'lottie-web';
import clsx from 'clsx';

export interface LottieAnimationProps {
  animationData: any;
}

function LottieAnimation(props: LottieAnimationProps) {
  const { animationData } = props;
  const { ref, inView } = useInView();
  const animation = useRef<AnimationItem | null>(null);
  const myLottieRef = useRef<HTMLDivElement>(null);

  const [isAnimationLoaded, setIsAnimationLoaded] = useState(false);
  const [isMyAnimationPlayed, setIsMyAnimationPlayed] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      myLottieRef.current &&
      !isAnimationLoaded
    ) {
      animation.current = Lottie.loadAnimation({
        container: myLottieRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        animationData,
      });
      setIsAnimationLoaded(true);
    }
  }, [animationData, isAnimationLoaded]);

  useEffect(() => {
    if (inView && animation.current && !isMyAnimationPlayed) {
      animation.current.play();
      setIsMyAnimationPlayed(true);
    } else if (!inView && animation.current && isMyAnimationPlayed) {
      animation.current.pause();
      setIsMyAnimationPlayed(false);
    }
  }, [inView, isMyAnimationPlayed]);

  useEffect(() => {
    return () => {
      animation.current?.destroy();
    };
  }, []);

  return (
    <div
      className={clsx(
        'relative flex items-center h-full justify-center w-full py-6',
      )}
      ref={ref}
    >
      <div className="relative w-full h-full">
        <div
          ref={myLottieRef}
          className="object-cover w-4/5 md:w-[98%] sm:w-full h-full mx-auto [&>svg]:w-full  [&>svg]:h-full"
        />
      </div>
    </div>
  );
}

export default LottieAnimation;
