import compareAsc from 'date-fns/compareAsc';
import Button, { BUTTON_KIND } from '@/components/Button';
import Badge, { BADGE_KIND } from '@/components/Badge';
import { capitalize } from '@/helpers/strings';

interface IPaymentCardItem {
  brand: string;
  last4: string;
  month: number;
  year: number;
  role: string | null;
}

const PaymentCardItem: React.FC<IPaymentCardItem> = ({
  brand,
  last4,
  month,
  year,
  role,
}) => {


  const hasExpired = () => {
    return compareAsc(new Date(year, month, 1), new Date()) === -1;
  };

  return (
    <div className="grid grid-cols-5 gap-4 first:border-t border-b py-4">
      <div className="col-span-3">
        <p>
          {capitalize(brand)} Ending in {last4}
        </p>
        <p className="text-sm text-gray-400">
          Expiry {month}/{year}
        </p>
      </div>
      <div className="self-center	justify-self-end">
        {(hasExpired() && <Badge kind={BADGE_KIND.RED} text="Expired" />) ||
          (role === 'primary' && (
            <Badge kind={BADGE_KIND.GREEN} text="Default" />
          )) ||
          (role === null && (
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Set as default payment"
            />
          ))}
      </div>
      <div className="justify-self-start">
        <Button buttonText="Delete" />
      </div>
    </div>
  );
};

export { PaymentCardItem as default };
