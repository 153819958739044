import { ChangeEvent } from 'react';

import { PaperClipIcon, PlusIcon } from '@heroicons/react/20/solid';

import Spinner from '@/components/Icons/Spinner';
import classNames from '@/helpers/classNames';

const FileUploadButtonComponent = ({
  id,
  title,
  buttonTitle,
  bottomText,
  url,
  filename,
  fileFormat,
  fileFormatMessage = '',
  onFileSelected,
  sizeError,
  setSizeError,
  fileError,
  multiple,
  loading,
  disabled,
  showFileSizeThresholdAndFileFormat = true,
  showIcons = true,
  buttonStyles = 'flex font-medium w-full h-full py-2.5 border border-transparent items-center justify-start',
}: {
  id?: string;
  title?: string;
  buttonTitle?: string;
  bottomText?: string;
  url?: string;
  filename?: string;
  fileFormat?: string;
  fileFormatMessage?: string;
  onFileSelected: (file: File) => void;
  sizeError?: boolean;
  setSizeError?: React.Dispatch<React.SetStateAction<boolean>>;
  fileError?: boolean;
  multiple?: boolean;
  loading?: boolean;
  disabled?: boolean;
  showFileSizeThresholdAndFileFormat?: boolean;
  showIcons?: boolean;
  buttonStyles?: string;
}) => {
  return (
    <div className="col-span-6 sm:col-span-3">
      <div className="block text-sm font-medium text-gray-700">{title}</div>
      {filename ? (
        <a
          href={url}
          download
          target="_blank"
          rel="noreferrer"
          className="text-sm leading-5 py-2.5 my-1 border border-transparent flex items-center justify-start break-all"
        >
          <PaperClipIcon
            className="text-brand-500 h-5 w-5 mr-2 flex-shrink-0"
            aria-hidden="true"
          />
          {filename}
        </a>
      ) : (
        <div className="my-1 flex items-center justify-end relative gap-2">
          <label
            htmlFor={id || 'file-upload'}
            className="cursor-pointer text-sm leading-5 whitespace-nowrap px-2"
          >
            <span className={buttonStyles}>
              {loading && <Spinner className="text-brand-500 mr-2" />}
              {!loading && showIcons && <PlusIcon className="h-5 w-5 mr-2" />}
              {loading ? 'Uploading ...' : buttonTitle || 'Upload file'}
            </span>
            <input
              id={id || 'file-upload'}
              name={id || 'file-upload'}
              type="file"
              accept={fileFormat}
              multiple={multiple}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.files && e.target.files[0]) {
                  if (e.target.files.length > 1) {
                    Object.values(e.target.files).forEach((element: any) => {
                      onFileSelected(element);
                    });
                  } else {
                    if (e.target.files[0].size > 8388608 && setSizeError) {
                      setSizeError(true);
                      return;
                    }
                    onFileSelected(e.target.files[0]);
                  }
                }
              }}
              className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer"
              disabled={disabled}
            />
          </label>
          <span
            className={classNames(
              'pl-1.5 text-sm',
              sizeError || fileError ? 'text-red-500' : 'text-gray-500',
            )}
          >
            {sizeError && 'File size is too big'}
            {fileError && 'File is invalid.'}
            {!sizeError &&
              !fileError &&
              showFileSizeThresholdAndFileFormat &&
              `Maximum file size 8MB ${fileFormatMessage}`}
          </span>
        </div>
      )}
      {bottomText && <p className="mt-2 text-sm text-gray-500">{bottomText}</p>}
    </div>
  );
};

export default FileUploadButtonComponent;
