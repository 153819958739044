import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { ITopSocialLink } from '@/types/IAnalytics';
import { formatNumber } from './Events';
import { BREAKPOINTS } from '@/components/ColumnChart';
import { useWindowSize } from 'usehooks-ts';
import clsx from 'clsx';

type TopSocialLinksProps = {
  data: ITopSocialLink[];
  title: string;
  subtitle: string;
};

function TopSocialLinks({ data, title, subtitle }: TopSocialLinksProps) {
  const dataPresent = data.length > 0;
  const { width: windowWidth } = useWindowSize();

  const options: ApexOptions = {
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
        },
      },
    },
    responsive: [
      {
        breakpoint: 1428,
        options: {
          chart: {
            width: windowWidth / 3.5,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: BREAKPOINTS.tablet,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    chart: {
      type: 'donut',
    },
    labels: data.map(({ social_link }) =>
      social_link
        .split(' ')
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' '),
    ),
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: dataPresent,
      fillSeriesColor: false,
      style: {
        fontSize: '14px',
      },
    },
    colors: dataPresent
      ? ['#AB77FF', '#CEF6FF', '#FAB6FC', '#D1FAE5', '#F8C290']
      : ['#F0F0F0'],
    legend: {
      show: dataPresent,
      position: 'right',
      fontFamily: 'Poppins',
      formatter: (seriesName: string, opts) => {
        return `${seriesName}: ${formatNumber(
          opts.w.globals.series[opts.seriesIndex],
        )}`;
      },
    },
  };

  return (
    <div className="relative">
      <div className="font-poppins">
        <h2 className="text-gray-900 font-medium text-2xl md:text-2xl">
          {title}
        </h2>
        <span className="text-gray-500 pt-2 text-sm hidden md:block">
          {subtitle}
        </span>
        <hr className="h-px mt-4 mb-8 bg-gray-200 border-0" />
        <div
          className={clsx(
            'flex justify-center self-center pt-0 md:pt-20 xl:pt-8 2xl:pt-0',
            { 'pointer-events-none': !dataPresent },
          )}
        >
          <Chart
            type="donut"
            series={dataPresent ? data.map(({ total }) => total) : [1]}
            options={options}
            width={500}
          />
        </div>
      </div>
    </div>
  );
}

export default TopSocialLinks;
