export const MAX_UPLOAD_FILE_SIZE_IN_BYTES = 8000000;

export enum PROFILE_IMAGE {
  MAX_HEIGHT = 1024,
  MAX_WIDTH = 1024,
}

export enum COVER_IMAGE {
  MAX_HEIGHT = 2048,
  MAX_WIDTH = 2048,
}