import { Link } from 'react-router-dom';

import hexToRgba from 'hex-to-rgba';

import Logo from '@/components/Logos/Primary';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
// TAPT-908
import Features from '@/types/Features';
import { css, cx } from '@emotion/css';

import { footerMenuData } from './data';

interface IProfilePageFooter {
  textColor: string;
  profileHashID: string;
}

const ProfilePageFooter: React.FC<IProfilePageFooter> = ({
  textColor,
  profileHashID,
}) => {
  const copyRightMessage = MESSAGES?.profile?.view?.footer?.copyrightMessage;
  // TAPT-908
  const { userScope } = useAuth();
  const isSuperUser = userScope === 'admin';
  const hasPremiumPlan = !isSuperUser && true;

  const styles = {
    textColor: css`
      color: ${textColor};
    `,
    textLink: css`
      &:hover {
        color: ${textColor};
        text-decoration: underline;
      }
    `,
    textColor70: css`
      color: ${hexToRgba(textColor, '0.7')};
    `,
    border10: css`
      border-color: ${hexToRgba(textColor, '0.1')};
    `,
  };

  return (
    <div
      className={cx(
        styles.border10,
        'mt-14 border-t py-12 flex flex-col items-center',
      )}
    >
      <div className="flex justify-center">
        <Link to="/">
          <div className={styles.textColor}>
            <Logo className="h-7 w-auto" />
          </div>
        </Link>
      </div>
      {footerMenuData && footerMenuData.length > 0 && (
        <ul className="mt-6 flex justify-center flex-wrap">
          {footerMenuData.map(menuItem => {
            const { href, id, text, title } = menuItem;
            return (
              <li key={id} className="inline-flex">
                <a
                  className={cx(
                    styles.textColor,
                    styles.textLink,
                    'py-2 px-5 text-base font-normal',
                  )}
                  href={href}
                  title={title}
                >
                  {text}
                </a>
              </li>
            );
          })}
        </ul>
      )}
      {/* //TAPT-908 */}
      {copyRightMessage && (
        <span className={cx(styles.textColor70, 'text-sm font-normal mt-6')}>
          {copyRightMessage}
        </span>
      )}
    </div>
  );
};

export { ProfilePageFooter as default };
