import { Link } from 'react-router-dom';

import { cx } from '@emotion/css';

type Tab = {
  name: string;
  key: string;
  href: string;
};

type ProfileGroupsPsuedoTabsProps = {
  activeTab: 'profiles' | 'groups';
};

export function ProfilesGroupsPseudoTabs({
  activeTab,
}: ProfileGroupsPsuedoTabsProps) {
  const tabs: Tab[] = [
    {
      key: 'profiles',
      name: 'All Profiles',
      href: '/',
    },
    {
      key: 'groups',
      name: 'Groups',
      href: '/groups',
    },
  ];

  return (
    <div className="border-b border-b-gray-200 grid gap-8 grid-flow-col justify-start">
      {tabs.map((tab, index) => (
        <Link
          key={index}
          to={tab.href}
          className={cx(
            'text-gray-500 text-lg py-2 hover:text-gray-900',
            activeTab === tab.key ? 'font-medium' : 'font-normal',
            {
              'text-gray-900 border-b-2 border-b-gray-900':
                activeTab === tab.key,
            },
          )}
        >
          {tab.name}
        </Link>
      ))}
    </div>
  );
}
