import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IUser from '@/types/IUser';
import { IPlatformSubscription } from '@/types/IOrganisation';

const accountsAPI = {
  signup: (body: {
    email: string;
    first_name: string;
    last_name: string;
    organisation_name: string;
    password: string;
    password_confirmation: string;
    terms_accepted: boolean;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/api/users`, body);
  },
  login: (body: {
    email: string;
    password: string;
  }): Promise<
    AxiosResponse<{
      data: {
        authentication_type: string;
        access_token: string;
        refresh_token?: string;
      };
    }>
  > => {
    return axiosApiService.post(`${BASE_URL}/api/login`, body);
  },
  verifyMfaToken: (
    mfa_token: string,
    otp_code: string,
  ): Promise<
    AxiosResponse<{ data: { access_token: string; refresh_token: string } }>
  > => {
    const config = {
      headers: {
        Authorization: `Bearer ${mfa_token}`,
      },
    };
    return axiosApiService.post(
      `${BASE_URL}/api/mfa/session/verify`,
      { otp_code },
      config,
    );
  },
  resendMfaToken: (mfa_token: string): Promise<AxiosResponse<void>> => {
    const config = {
      headers: {
        Authorization: `Bearer ${mfa_token}`,
      },
    };
    return axiosApiService.post(
      `${BASE_URL}/api/mfa/session/resend`,
      null,
      config,
    );
  },
  refreshToken: (body: {
    refresh_token: string;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/api/session`, body);
  },
  getCurrentUser: (): Promise<AxiosResponse<{ data: IUser }>> => {
    return axiosApiService.get(`${BASE_URL}/api/current_user`);
  },
  updatePassword: (body: {
    update_password: {
      password: string;
      password_confirmation: string;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/api/current_user`, body);
  },
  updateCurrentUser: (body: {
    email?: string;
    first_name?: string;
    last_name?: string;
    first_log_in_at?: Date;
    authentication_type?: string;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(`${BASE_URL}/api/current_user`, body);
  },
  forgotPassword: (body: {
    forgot: {
      email: string;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/api/forgot`, body);
  },
  verifyForgotPassword: (body: {
    forgot: {
      email: string;
      code: string;
    };
  }): Promise<
    AxiosResponse<{ data: { access_token: string; refresh_token: string } }>
  > => {
    return axiosApiService.put(`${BASE_URL}/api/forgot`, body);
  },
  getIntegrationToken: (
    orgID: number,
  ): Promise<AxiosResponse<{ token: string }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/current_user/integration_token/${orgID}`,
    );
  },
  getSubscription: (
    orgID: number,
  ): Promise<
    AxiosResponse<{
      subscription: IPlatformSubscription | null;
      total_profiles: number;
    }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/current_user/subscription/${orgID}`,
    );
  },
  sendPaymentMethodUpdateEmail: (
    orgID: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/current_user/subscription/${orgID}/payment_method_update_email`,
    );
  },
  getShopifySsoLink: (): Promise<AxiosResponse<{ data: { url: string } }>> => {
    return axiosApiService.get(`${BASE_URL}/api/current_user/shopify_sso_link`);
  },
  getSsoAuthUrl: (email: string): Promise<AxiosResponse<{ url: string }>> => {
    return axiosApiService.get(`${BASE_URL}/api/sso_auth_url`, {
      params: { email },
    });
  },
  sendSubscriptionCancellationEmail: (
    orgID: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/current_user/subscription/${orgID}/cancellation_request`,
    );
  },
};

export default accountsAPI;
