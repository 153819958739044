import { ArrowRightIcon, PaintBrushIcon } from '@heroicons/react/24/outline';

import useAuth from '@/hooks/useAuth';

function DesignWidget() {
  const { user } = useAuth();

  if (user?.last_design_order?.total === 0) {
    return null;
  }

  return (
    <div className="flex p-3 bg-brand-50 rounded-tl-2xl rounded-br-2xl rounded-tr-sm rounded-bl-sm space-x-3">
      <PaintBrushIcon className="w-6 h-6 text-brand-500" strokeWidth={2} />
      <div className="flex flex-col space-y-2">
        <div className="text-sm md:text-xs font-medium text-brand-900">
          Card design and/or approval needed.
        </div>
        <a
          className="flex text-sm md:text-xs items-center"
          target="_blank"
          href={`${import.meta.env.VITE_CUSTOMISER_BASE_URL}/order/${
            user?.last_design_order.uuid
          }`}
        >
          Let's go&nbsp;
          <ArrowRightIcon className="w-4 h-4" strokeWidth={2} />
        </a>
      </div>
    </div>
  );
}

export default DesignWidget;
