import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@material-ui/core';
import CustomInfiniteScroll from '../../../styleds/CustomInfiniteScroll';
import Button, { BUTTON_KIND, BUTTON_SIZE } from '@/components/Button';
import IDesign from '@/types/IDesign';
import { downloadFile } from '@/helpers/downloadFile';
import IOrderV2 from '@/types/IOrderV2';

interface IDesignInformation {
  setIsUploadDesign: Dispatch<SetStateAction<boolean>>;
  orderDesign: IDesign[];
  order: IOrderV2;
}

export default function DesignInformation({
  setIsUploadDesign,
  orderDesign,
  order,
}: IDesignInformation) {
  const LogoFiles: React.FC = () => {
    const LogoFilesLink: React.FC<{ name: string; url: string | null }> = ({
      url,
      name,
    }) => {
      if (!url) return null;

      return (
        <Box className="flex items-center gap-3 p-3" key={url}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 2C3.34315 2 2 3.34315 2 5V9C2 11.7614 4.23858 14 7 14C9.76142 14 12 11.7614 12 9V5C12 4.44772 12.4477 4 13 4C13.5523 4 14 4.44772 14 5V9C14 12.866 10.866 16 7 16C3.13401 16 0 12.866 0 9V5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5V9C10 10.6569 8.65685 12 7 12C5.34315 12 4 10.6569 4 9V5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5V9C6 9.55228 6.44772 10 7 10C7.55228 10 8 9.55228 8 9V5C8 3.34315 6.65685 2 5 2Z"
              fill="#873CFF"
            />
          </svg>
          <p className="truncate font-normal" style={{ maxWidth: '226px' }}>
            {name}
          </p>
          <Box className="flex justify-end flex-1 font-normal text-brand-500 cursor-pointer">
            <Box
              className="flex items-center justify-center gap-2"
              onClick={() => {
                const fileName = url.split('/').pop() || '';

                downloadFile(url, fileName);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M0.664062 8.66699V9.33366C0.664062 9.86409 0.874776 10.3728 1.24985 10.7479C1.62492 11.1229 2.13363 11.3337 2.66406 11.3337H9.33073C9.86116 11.3337 10.3699 11.1229 10.7449 10.7479C11.12 10.3728 11.3307 9.86409 11.3307 9.33366V8.66699M8.66406 6.00033L5.9974 8.66699M5.9974 8.66699L3.33073 6.00033M5.9974 8.66699V0.666992"
                  stroke="#873CFF"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              download
            </Box>
          </Box>
        </Box>
      );
    };

    return (
      <>
        <LogoFilesLink name="Front logo" url={order.front_logo_url} />
        <LogoFilesLink name="Back logo" url={order.back_logo_url} />
      </>
    );
  };

  return (
    <Box className=" font-poppins font-medium text-base leading-5 text-gray-900 grid gap-2">
      <p>Design Information</p>
      <Box className=" font-poppins text-gray-700 font-medium text-sm flex items-center justify-start">
        <p className="flex flex-1 ">Design updates</p>
        <Button
          buttonText="Send proof"
          size={BUTTON_SIZE.XSMALL}
          kind={BUTTON_KIND.WHITE}
          onClick={() => setIsUploadDesign(true)}
        />
      </Box>
      <Box className=" font-poppins text-gray-700 font-medium text-sm ">
        <p className="mb-4">Attachments</p>
        <CustomInfiniteScroll
          fetchMore={() => {}}
          customRerenderArray={[orderDesign]}
          className="overflow-y-scroll h-24"
        >
          <Box className="w-full border grid divide-y">
            {orderDesign.map((item) => {
              return (
                <Box
                  className="flex items-center gap-3 p-3"
                  key={`${item.id} - ${item.description}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5 2C3.34315 2 2 3.34315 2 5V9C2 11.7614 4.23858 14 7 14C9.76142 14 12 11.7614 12 9V5C12 4.44772 12.4477 4 13 4C13.5523 4 14 4.44772 14 5V9C14 12.866 10.866 16 7 16C3.13401 16 0 12.866 0 9V5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5V9C10 10.6569 8.65685 12 7 12C5.34315 12 4 10.6569 4 9V5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5V9C6 9.55228 6.44772 10 7 10C7.55228 10 8 9.55228 8 9V5C8 3.34315 6.65685 2 5 2Z"
                      fill="#873CFF"
                    />
                  </svg>
                  <p
                    className="truncate font-normal"
                    style={{ maxWidth: '226px' }}
                  >
                    {item.design_file?.file?.file_name}
                  </p>
                  <Box className="flex justify-end flex-1 font-normal text-brand-500 cursor-pointer">
                    <Box
                      className="flex items-center justify-center gap-2"
                      onClick={() =>
                        item.design_file?.original_url &&
                        item.design_file?.file?.file_name &&
                        downloadFile(
                          item.design_file.original_url,
                          item.design_file.file.file_name,
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M0.664062 8.66699V9.33366C0.664062 9.86409 0.874776 10.3728 1.24985 10.7479C1.62492 11.1229 2.13363 11.3337 2.66406 11.3337H9.33073C9.86116 11.3337 10.3699 11.1229 10.7449 10.7479C11.12 10.3728 11.3307 9.86409 11.3307 9.33366V8.66699M8.66406 6.00033L5.9974 8.66699M5.9974 8.66699L3.33073 6.00033M5.9974 8.66699V0.666992"
                          stroke="#873CFF"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      download
                    </Box>
                  </Box>
                </Box>
              );
            })}
            <LogoFiles />
          </Box>
        </CustomInfiniteScroll>
      </Box>
    </Box>
  );
}
