import { Dispatch, SetStateAction } from 'react';
import { Box } from '@material-ui/core';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import Input from '@/components/Input';
import InputSelect from '@/components/InputSelect';
import { IBillingInformation } from '@/types/ReOrderExperience/IReorderMultistepPage';
import { ICOUNTRIES, ISTATES } from '@/types/ReOrderExperience/IStep3';

interface IBillingAddress {
  billingInformation: IBillingInformation;
  setBillingInformation: Dispatch<SetStateAction<IBillingInformation>>;
  COUNTRIES: ICOUNTRIES[];
  STATES: ISTATES[];
}

const BillingAddress = (props: IBillingAddress) => {
  const { billingInformation, setBillingInformation, COUNTRIES, STATES } =
    props;

  return (
    <>
      <InfoPanelDivider />
      <InfoPanelContainer
        title={
          <div>
            <p className="text-2xl font-medium leading-6 text-gray-900 mb-2">
              Billing address
            </p>
          </div>
        }
        border={false}
        padding=""
      >
        <Box className="grid grid-cols-6 gap-6">
          <Box className="col-span-6 sm:col-span-3 md:col-span-6">
            <Box className="grid gap-6 p-6 border rounded-lg border-gray-100">
              <Box className="text-left">
                <Input
                  type="text"
                  label="Company Name"
                  value={billingInformation.company}
                  onChange={(company) => {
                    setBillingInformation({ ...billingInformation, company });
                  }}
                  required
                />
              </Box>
              <Box className="text-left w-1/2">
                <InputSelect
                  id="Billing Country/Region"
                  label="Country/Region"
                  value={billingInformation.countryRegion || 'Australia'}
                  onChange={(countryRegion) => {
                    setBillingInformation({
                      ...billingInformation,
                      countryRegion,
                    });
                  }}
                  options={COUNTRIES}
                  selectClassName="block w-full py-2 font-normal text-gray-900 focus:ring-brand-500 focus:border-brand-500 shadow-sm border-gray-300 rounded-md"
                />
              </Box>

              <Box className="col-span-full">
                <Input
                  type="text"
                  label="Street address"
                  value={billingInformation.address}
                  onChange={(address) => {
                    setBillingInformation({
                      ...billingInformation,
                      address,
                    });
                  }}
                  required
                />
              </Box>

              <Box className="grid grid-cols-3 gap-6">
                <Box className="col-span-1">
                  <Input
                    type="text"
                    label="City"
                    value={billingInformation.city}
                    onChange={(city) => {
                      setBillingInformation({
                        ...billingInformation,
                        city,
                      });
                    }}
                    required
                  />
                </Box>

                <Box className="col-span-1">
                  <InputSelect
                    id="Billing State / Province"
                    label="State / Province"
                    value={billingInformation.state}
                    onChange={(state) => {
                      setBillingInformation({ ...billingInformation, state });
                    }}
                    options={STATES}
                    selectClassName="block w-full py-2 font-normal text-gray-900 focus:ring-brand-500 focus:border-brand-500 shadow-sm border-gray-300 rounded-md"
                  />
                </Box>
                <Box className="col-span-1">
                  <Input
                    type="text"
                    label="Zip / Postal"
                    value={billingInformation.postCode}
                    onChange={(postCode) => {
                      setBillingInformation({
                        ...billingInformation,
                        postCode,
                      });
                    }}
                    required
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </InfoPanelContainer>
    </>
  );
};

export default BillingAddress;
