import { Fragment, ReactNode } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import Button, { BUTTON_KIND } from '@/components/Button';
import classNames from '@/helpers/classNames';

import Steps, { StepsProps } from '../Steps';

export interface IModal {
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  buttonKind?: string;
  buttonSize?: string;
  buttonClassName?: string;
  dialogHeroVideo?: string;
  buttonTitle?: string;
  dialogTitleIcon?: JSX.Element;
  dialogTitle?: string | JSX.Element;
  dialogDescription?: string | JSX.Element;
  checkboxDescription?: string | JSX.Element;
  dialogTitleClassName?: string;
  footerButtonContainerClassName?: string;
  wider?: boolean;
  icon?: JSX.Element;
  children?: ReactNode;
  successButtonText?: string;
  successButton?: JSX.Element;
  successButtonKind?: string;
  successButtonHref?: string;
  cancelButtonText?: string;
  cancelButton?: JSX.Element;
  isLoading?: boolean;
  isDisabled?: boolean;
  large?: boolean;
  checkbox?: boolean;
  setCheckbox?: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel?: () => void;
  onClose?: () => void;
  onSuccess: () => void;
  showSteps?: boolean;
  stepsProps?: StepsProps;
}

export default function Modal({
  isOpen,
  setIsOpen,
  buttonKind = BUTTON_KIND.WHITE,
  buttonSize,
  buttonClassName,
  buttonTitle,
  dialogHeroVideo,
  dialogTitle,
  dialogTitleIcon,
  dialogDescription,
  checkboxDescription,
  dialogTitleClassName = 'text-xl md:text-2xl text-left leading-8 font-medium text-gray-900',
  footerButtonContainerClassName = 'w-full flex justify-end space-x-2',
  wider,
  icon,
  children,
  successButtonText,
  successButtonKind = BUTTON_KIND.PRIMARY,
  successButton,
  successButtonHref,
  cancelButtonText = 'Cancel',
  cancelButton,
  isLoading,
  isDisabled = false,
  large = false,
  checkbox,
  setCheckbox,
  onCancel,
  onClose = () => setIsOpen && setIsOpen(false),
  onSuccess,
  showSteps = false,
  stepsProps,
}: IModal) {
  return (
    <>
      {buttonTitle && (
        <Button
          kind={buttonKind}
          size={buttonSize}
          icon={icon}
          iconPos="left"
          className={buttonClassName}
          onClick={() => setIsOpen && setIsOpen(true)}
          buttonText={buttonTitle}
        />
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={classNames(
                  'inline-block my-8 text-left align-middle transition-all transform bg-white shadow-xl ',
                  large
                    ? 'w-full md:w-3/4 xl:w-1/2 p-6 rounded-2xl'
                    : `${
                        wider
                          ? 'w-full md:w-538 p-5 rounded-xl'
                          : 'w-full max-w-lg p-6 rounded-2xl'
                      }`,
                )}
              >
                {showSteps && stepsProps && (
                  <div className="flex items-end justify-end w-full">
                    <Steps {...stepsProps} />
                  </div>
                )}
                {dialogHeroVideo && (
                  <video
                    src={dialogHeroVideo}
                    muted
                    autoPlay
                    loop
                    playsInline
                    className="w-full mb-6 bg-white"
                  />
                )}

                {dialogTitleIcon && (
                  <div className="flex items-center justify-center">
                    {dialogTitleIcon}
                  </div>
                )}

                {dialogTitle && (
                  <Dialog.Title as="h3" className={dialogTitleClassName}>
                    {dialogTitle}
                  </Dialog.Title>
                )}

                {dialogDescription && (
                  <div className={classNames(dialogTitle ? 'mt-2' : '')}>
                    <Dialog.Description className="text-sm leading-5 text-gray-500">
                      {dialogDescription}
                    </Dialog.Description>
                  </div>
                )}

                {children}
                {checkboxDescription && (
                  <div className="flex items-center justify-start w-full space-x-2 mt-4">
                    <input
                      id="model_checkbox"
                      type="checkbox"
                      className="w-4 h-4 border-gray-300 rounded focus:ring-brand-500 text-brand-500"
                      defaultChecked={checkbox}
                      onClick={() => {
                        setCheckbox?.(!checkbox);
                      }}
                    />
                    <label
                      className="text-sm text-gray-700 font-medium"
                      htmlFor="model_checkbox"
                    >
                      {checkboxDescription}
                    </label>
                  </div>
                )}
                <div className="flex justify-between mt-6">
                  <div className={footerButtonContainerClassName}>
                    {!cancelButton && (
                      <Button
                        className="flex-1 md:flex-none"
                        kind={BUTTON_KIND.WHITE}
                        onClick={() => {
                          onCancel?.();
                          setIsOpen?.(false);
                        }}
                        buttonText={cancelButtonText}
                      />
                    )}

                    {cancelButton}

                    {!successButton && successButtonText && (
                      <Button
                        className="flex-1 md:flex-none"
                        kind={successButtonKind}
                        onClick={!!successButtonHref ? undefined : onSuccess}
                        buttonText={successButtonText}
                        loading={isLoading}
                        disabled={isDisabled}
                        href={successButtonHref}
                        external={!!successButtonHref}
                        externalBlankTarget={!successButtonHref}
                      />
                    )}

                    {successButton}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
