import { useState } from 'react';

import { useCopyToClipboard } from 'usehooks-ts';

import Copy from '@/components/Icons/Copy';
import { getVariantFullName } from '@/helpers/products';
import { hashTruncate } from '@/helpers/strings';
import { ICardAdmin } from '@/types/ICard';

const TypeCell: React.FC<{ card: ICardAdmin }> = ({ card }) => {
  const [, copy] = useCopyToClipboard();
  const [hashCopied, setHashCopied] = useState(false);

  const cardHash = card.card_hash;
  async function copyHashToClipboard() {
    await copy(cardHash);

    setHashCopied(true);
    setTimeout(() => {
      setHashCopied(false);
    }, 3000);
  }

  return (
    <td className={'flex flex-col p-4 pb-3 min-w-0'}>
      <span className="font-medium truncate text-sm text-gray-900">
        {getVariantFullName(card.type)}
      </span>
      <span className="truncate flex flex-row items-center text-gray-500">
        ID:&nbsp;
        <button
          type="button"
          className="appearance-none text-brand-600 text-sm font-medium items-center flex flex-row"
          onClick={copyHashToClipboard}
        >
          {hashCopied ? 'Copied!' : hashTruncate(cardHash)}
          <Copy className="w-4 h-4 ml-1" />
        </button>
      </span>
    </td>
  );
};

export default TypeCell;
