import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const BASE_URL = 'https://api.starshipit.com/api';

export type StarshipitOrder = {
  order_id: number;
};

export type OrderResponse = {
  order: StarshipitOrder;
};

export type PrintLabelResponse = {
  labels?: string[];
  success: boolean;
};

type PrintLabelParams = {
  orderNumber: string;
  packages: {
    height: number;
    width: number;
    length: number;
    weight: number;
  }[];
};

interface AxiosRequestConfigWithAuth extends AxiosRequestConfig {
  headers?: {
    'StarShipIT-Api-Key'?: string;
    'Ocp-Apim-Subscription-Key'?: string;
  };
}

const apiKey = import.meta.env.VITE_STARSHIPIT_API_KEY as string;
const subscriptionKey = import.meta.env
  .VITE_STARSHIPIT_SUBSCRIPTION_KEY as string;

const axiosApiService = axios.create();

// request interceptor to add the auth token header to requests
axiosApiService.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    let newConfig: AxiosRequestConfigWithAuth = config;
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      newConfig = {
        ...newConfig,
        headers: {
          ...newConfig.headers,
          'StarShipIT-Api-Key': apiKey,
          'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
      };
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

export const getOrder = (
  orderNumber: string,
): Promise<AxiosResponse<OrderResponse>> => {
  return axiosApiService.get(`${BASE_URL}/orders?order_number=${orderNumber}`);
};

export const printLabel = ({
  orderNumber,
  packages,
}: PrintLabelParams): Promise<AxiosResponse<PrintLabelResponse>> => {
  return axiosApiService.post(`${BASE_URL}/orders/shipment`, {
    order_number: orderNumber,
    packages,
  });
};
