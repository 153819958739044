import React, { useCallback, useState } from 'react';

import { Tab } from '@headlessui/react';

import adminAPI from '@/api/admin';
import InputCheckbox from '@/components/InputCheckbox';
import LoadingAnimation from '@/components/LoadingAnimation';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import classNames from '@/helpers/classNames';
import useAppState from '@/hooks/useAppState';
import { ICardAdmin } from '@/types/ICard';
import { IListNew, IListPaging } from '@/types/IList';

import Pagination from '../Pagination';

export enum CARD_STATUS {
  UNPROGRAMMED = 'unprogrammed',
  PROGRAMMED = 'programmed',
  PENDING_TO_PRINT = 'pending_to_print',
  CONNECTED = 'connected',
  ALL = 'all',
}

export interface IBaseTabState {
  selectAll: boolean;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  checkedItems: number[];
  setCheckedItems: React.Dispatch<React.SetStateAction<number[]>>;
  setSuccess: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IItem {
  card: ICardAdmin;
  selected: boolean;
  checkItem: () => void;
  setSuccess?: React.Dispatch<React.SetStateAction<string>>;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  markAsUnconnected?: (
    card: ICardAdmin,
  ) => Promise<{ data: ICardAdmin[]; paging: IListPaging }>;
  markAsUnprogrammed?: (
    card: ICardAdmin,
  ) => Promise<{ data: ICardAdmin[]; paging: IListPaging }>;
  deleteProduct?: (
    card: ICardAdmin,
  ) => Promise<{ data: ICardAdmin[]; paging: IListPaging }>;
  connectProfile?: (
    profileID: number | undefined,
    orgID: number | undefined,
  ) => Promise<{ data: ICardAdmin[]; paging: IListPaging }>;
  unlinkProfile?: (
    card: ICardAdmin,
  ) => Promise<{ data: ICardAdmin[]; paging: IListPaging }>;
}

export interface IBaseTab extends IBaseTabState {
  name: string;
  status: CARD_STATUS;
  header: React.ComponentType;
  item: React.ComponentType<IItem>;
}

const tabs = [
  {
    name: 'Unprogrammed',
    value: CARD_STATUS.UNPROGRAMMED,
  },
  {
    name: 'Unconnected',
    value: CARD_STATUS.PROGRAMMED,
  },
  {
    name: 'In Progress',
    value: CARD_STATUS.PENDING_TO_PRINT,
  },
  {
    name: 'Connected',
    value: CARD_STATUS.CONNECTED,
  },
  {
    name: 'All Products',
    value: CARD_STATUS.ALL,
  },
];

export const BaseTab: React.FC<IBaseTab> = ({
  name,
  status,
  selectAll,
  setSelectAll,
  checkedItems,
  setCheckedItems,
  setSuccess,
  setError,
  header: Header,
  item: Item,
}) => {
  const { selectCards } = useAppState();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ICardAdmin[]>();

  const listCards = useCallback(
    async ({
      newPage = page,
      newPageSize = pageSize,
      newSort = sort,
      newOrder = order,
      newStatus = status,
      newSearch = search,
      initial = false,
    }: IListNew): Promise<{ data: ICardAdmin[]; paging: IListPaging }> => {
      const res = await adminAPI.listCards({
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        status: newStatus,
        order: newOrder,
        search: newSearch,
      });

      if (!initial) {
        setData(res.data.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      // setCount(newStatus, res.data.paging.total_entries);

      return res.data;
    },
    [order, page, pageSize, search, sort],
  );

  function handleCheckItem(cardID: number) {
    let arr = checkedItems;
    if (checkedItems.includes(cardID)) {
      arr = arr.filter(e => e !== cardID);
    } else {
      arr = [...checkedItems, cardID];
    }
    setCheckedItems(arr);
    selectCards(arr);
  }

  function updateCard(
    cardID: number,
    newStatus: string,
    adminNote?: string,
    profileID?: number | null,
    orgID?: number,
  ): Promise<{ data: ICardAdmin[]; paging: IListPaging }> {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    return adminAPI
      .updateCard(cardID, {
        card: {
          status: newStatus,
          ...(profileID !== undefined
            ? {
                profile_id: profileID,
              }
            : {}),
          ...(orgID !== undefined
            ? {
                organisation_id: orgID,
              }
            : {}),
          ...(adminNote !== undefined
            ? {
                admin_note: adminNote,
              }
            : {}),
        },
      })
      .then(() => setSuccess(MESSAGES.card.update.success(cardID)))
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listCards({ newPage: 1 }))
      .then(() => listCards({ newStatus, initial: true }));
  }

  const handleDelete = (
    cardID: number,
    profileID?: number | null,
  ): Promise<{ data: ICardAdmin[]; paging: IListPaging }> => {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    return adminAPI
      .deleteCard(cardID)
      .then(() =>
        setSuccess(
          profileID
            ? MESSAGES.card.delete.successWithProfile(cardID, profileID)
            : MESSAGES.card.delete.success(cardID),
        ),
      )
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listCards({ newPage: 1 }));
  };

  return (
    <Tab.Panel key={name} className="outline-none">
      <div className="py-8">
        <div className="pb-8 flex flex-col space-y-3 flex-shrink-0 sm:space-x-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
          <Search
            id={`CardsList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
            search={search}
            setSearch={setSearch}
            fetchQuery={searchQuery => listCards({ newSearch: searchQuery })}
          />
          <Sort
            id={`CardsList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
            options={[
              {
                sort: 'date',
                order: 'desc',
                label: 'Date created (Newest first)',
              },
              {
                sort: 'date',
                order: 'asc',
                label: 'Date created (Oldest first)',
              },
            ]}
            sort={sort}
            setSort={setSort}
            order={order}
            setOrder={setOrder}
            fetchQuery={(sortQuery, orderQuery) =>
              listCards({ newSort: sortQuery, newOrder: orderQuery })
            }
          />
        </div>
        {data && data.length > 0 ? (
          <div className="flex flex-col">
            <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
              <div className="block w-full lg:px-8">
                <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8">
                  <div className="md:hidden flex items-center px-4 py-2 sm:px-6 bg-white border-b border-gray-200">
                    <InputCheckbox
                      id="select-all"
                      value={selectAll}
                      onChange={value => {
                        setSelectAll(value);
                        if (value) {
                          const arr = data.map(({ id }) => id);
                          setCheckedItems(arr);
                          selectCards(arr);
                        } else {
                          setCheckedItems([]);
                          selectCards([]);
                        }
                      }}
                    />
                  </div>
                  <table className="w-full max-w-full table-fixed">
                    <thead className="bg-gray-50 border-b border-gray-200">
                      <tr className="text-left text-sm font-medium text-gray-900">
                        <th scope="col" className="p-4 pb-3 w-[5%]">
                          <InputCheckbox
                            id="select-all"
                            label=""
                            labelClassName=""
                            value={selectAll}
                            onChange={value => {
                              setSelectAll(value);
                              if (value) {
                                const arr = data.map(({ id }) => id);
                                setCheckedItems(arr);
                                selectCards(arr);
                              } else {
                                setCheckedItems([]);
                                selectCards([]);
                              }
                            }}
                          />
                        </th>
                        <Header />
                      </tr>
                    </thead>
                    {data?.map((item: ICardAdmin) => (
                      <>
                        <Item
                          card={item}
                          key={item.id}
                          selected={checkedItems.includes(item.id)}
                          checkItem={() => handleCheckItem(item.id)}
                          setSuccess={setSuccess}
                          setError={setError}
                          markAsUnconnected={(card: ICardAdmin) =>
                            updateCard(card.id, CARD_STATUS.PROGRAMMED)
                          }
                          markAsUnprogrammed={(card: ICardAdmin) =>
                            updateCard(card.id, CARD_STATUS.UNPROGRAMMED)
                          }
                          deleteProduct={(card: ICardAdmin) =>
                            handleDelete(card.id, card.profile?.id)
                          }
                          connectProfile={(
                            profileID: number | undefined,
                            orgID: number | undefined,
                          ) =>
                            updateCard(
                              item.id,
                              CARD_STATUS.CONNECTED,
                              '',
                              profileID,
                              orgID,
                            )
                          }
                          unlinkProfile={(card: ICardAdmin) =>
                            updateCard(
                              card.id,
                              CARD_STATUS.PROGRAMMED,
                              '',
                              null,
                            )
                          }
                        />
                      </>
                    ))}
                  </table>
                  {isLoading && (
                    <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                      <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-32">
            <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
              {MESSAGES.card.list.empty.heading}
            </h3>
            <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
              {MESSAGES.card.list.empty.description}
            </p>
          </div>
        )}
      </div>

      <Pagination
        id={`CardsList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) =>
          listCards({ newPage: pageQuery, newPageSize: pageSizeQuery })
        }
        setIsLoading={setIsLoading}
      />
    </Tab.Panel>
  );
};
