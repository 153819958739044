import { useEffect, useRef } from 'react';

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

export type Alert = {
  outcome: 'success' | 'error';
  message: string;
  action?: JSX.Element;
};

type AlertProps = {
  message: string;
  className?: string;
  actionButton?: JSX.Element;
};

export function SuccessAlert({
  message,
  className = 'rounded-md bg-emerald-50 border border-emerald-400 p-3 flex space-x-2 text-sm',
  actionButton,
}: AlertProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  return (
    <div ref={ref} className={className}>
      <div className="flex-shrink-0">
        <CheckCircleIcon
          className="h-5 w-5 text-emerald-400"
          aria-hidden="true"
        />
      </div>
      <div className="flex flex-col space-y-1">
        <span className="font-medium text-emerald-800">{message}</span>
        {actionButton && actionButton}
      </div>
    </div>
  );
}

export function ErrorAlert({
  message,
  className = 'rounded-md bg-red-50 border border-red-400 p-3 flex space-x-2 text-sm',
  actionButton,
}: AlertProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  return (
    <div ref={ref} className={className}>
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="flex flex-col space-y-1">
        <span className="font-medium text-red-800">{message}</span>
        {actionButton && actionButton}
      </div>
    </div>
  );
}
