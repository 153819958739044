import { Tab } from '@headlessui/react';
import { useState } from 'react';
import Button, { BUTTON_SIZE } from '../../Button';
import TabList from '../../TabList';

type ExtraData = {
  rounded: boolean;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  validStep: number[];
};

interface IMultiStepProgressBarProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setOpenUploadCsvDialog: React.Dispatch<React.SetStateAction<boolean>>;
  paymentTerm: boolean;
  validStep: number[];
}

const MultiStepProgressBar = (
  props: IMultiStepProgressBarProps,
): JSX.Element => {
  const { step, setStep, setOpenUploadCsvDialog, paymentTerm, validStep } =
    props;
  const tabs = [
    {
      name: 'Step 1',
      value: 'step1',
      selectedName: 'Step 1 - Design Specifcations',
    },
    {
      name: 'Step 2',
      value: 'step2',
      selectedName: 'Step 2 - Add Card Details',
    },
    ...(paymentTerm
      ? [
          {
            name: 'Step 3',
            value: 'step3',
            selectedName: 'Step 3 - Billing Address',
          },
          {
            name: 'Step 4',
            value: 'step4',
            selectedName: 'Step 4 - Order Confirmation',
          },
        ]
      : [
          {
            name: 'Step 3',
            value: 'step3',
            selectedName: 'Step 3 - Order Confirmation',
          },
        ]),
  ];

  const [tab, setTab] = useState<string>(tabs[0].value);

  return (
    <Tab.Group
      selectedIndex={step}
      onChange={(index: number) => {
        setTab(tabs[index].value);
        if (validStep.includes(index)) setStep(index);
      }}
    >
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
        <div className="pt-4 md:pt-0 w-full relative">
          <TabList tabs={tabs} value={tab} />
          {step === 1 && (
            <div className="w-full flex justify-end items-center absolute bottom-6">
              <div className="flex justify-center items-center px-4">
                <div className="self-end">
                  <Button
                    buttonText="Upload CSV"
                    onClick={() => setOpenUploadCsvDialog(true)}
                    size={BUTTON_SIZE.LARGE}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Tab.Group>
  );
};

export default MultiStepProgressBar;
