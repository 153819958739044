import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Tab } from '@headlessui/react';
import { EyeIcon } from '@heroicons/react/24/outline';

import filesAPI from '@/api/files';
import organisationsAPI from '@/api/organisations';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import ColorPicker from '@/components/ColorPicker';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Layout from '@/components/Layout';
import { ProfilePreviewModal } from '@/components/ProfilePreview';
import TabList from '@/components/TabList';
import ToggleField from '@/components/ToggleField';
import ToggleFieldWithInfo from '@/components/ToggleFieldWithInfo';
import UploadPhoto from '@/components/UploadPhoto';
import { COVER_IMAGE } from '@/constants/files';
import MESSAGES from '@/constants/messages-en';
import useAppState from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';
import SubscriptionStatus from '@/types/SubscriptionStatus';

export default function TemplatesPage() {
  const { platformSubscriptionStatus } = useAuth();
  const { templatesTab, selectTemplatesTab } = useAppState();

  const disableTabs =
    platformSubscriptionStatus === SubscriptionStatus.INACTIVE;

  const tabs = [
    {
      name: 'Profile Design',
      value: 'profile-design',
      current: true,
      disabled: disableTabs,
    },
    {
      name: 'Contact Exchange',
      value: 'contact-exchange',
      current: false,
      disabled: disableTabs,
    },
  ];

  const [tab, setTab] = useState(tabs[templatesTab].value ?? 'profile-design');

  return (
    <Layout pageName="Templates" className="bg-gray-50">
      <Tab.Group
        defaultIndex={0}
        selectedIndex={templatesTab}
        onChange={index => {
          setTab(tabs[index].value);
          selectTemplatesTab(index);
        }}
      >
        <div className="md:border-b border-gray-200">
          <TabList tabs={tabs} value={tab} />
        </div>
        <Tab.Panels>
          <Tab.Panel className="outline-none">
            <ProfileDesign />
          </Tab.Panel>
          <Tab.Panel className="outline-none">
            <ContactExchange />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
}

function ProfileDesign() {
  // moved over the existing code from SettingsPage
  const { orgID } = useAuth();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const [backgroundColour, setBackgroundColour] = useState('#0A0A0A');
  const [textColour, setTextColour] = useState('#FFFFFF');
  const [buttonBackgroundColour, setButtonBackgroundColour] =
    useState('#873CFF');
  const [buttonTextColour, setButtonTextColour] = useState('#FFFFFF');
  const [logoFile, setLogoFile] = useState<IFile | undefined>(undefined);

  const [organisationError, setOrganisationError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    getOrganisationSettings();
  }, []);

  async function getOrganisationSettings() {
    if (orgID === undefined) {
      return;
    }

    try {
      setIsLoading(true);

      const {
        data: { data: organisation },
      } = await organisationsAPI.showOrganisationSettings(orgID);

      if (organisation.company_logo) {
        setLogoFile(organisation.company_logo);
      }
      if (organisation.bg_color) {
        setBackgroundColour(organisation.bg_color);
      }
      if (organisation.text_color) {
        setTextColour(organisation.text_color);
      }
      if (organisation.button_bg_color) {
        setButtonBackgroundColour(organisation.button_bg_color);
      }
      if (organisation.button_text_color) {
        setButtonTextColour(organisation.button_text_color);
      }
    } catch {
      setOrganisationError(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOrganisationSettingsSave() {
    if (orgID === undefined) {
      return;
    }

    try {
      setIsSaving(true);
      setSuccessMessage(undefined);
      setOrganisationError(false);

      await organisationsAPI.updateOrganisationSettings(orgID, {
        organisation_setting: {
          bg_color: backgroundColour,
          text_color: textColour,
          button_bg_color: buttonBackgroundColour,
          button_text_color: buttonTextColour,
          company_logo_file_id: logoFile ? logoFile.id : null,
        },
      });

      setSuccessMessage(MESSAGES.organisation.template);
    } catch {
      setOrganisationError(true);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <div className="pt-8 pb-[33%] md:pb-[68px]">
      {successMessage && (
        <div className="pb-8">
          <SuccessAlert message={successMessage} />
        </div>
      )}
      <InfoPanelContainer
        information="Customise the look of the profile pages of your cardholders. Changes will apply to all profile pages belonging to your organisation."
        title="Profile page appearance"
        footerContent={() => (
          <>
            <Button
              buttonText="Preview"
              kind={BUTTON_KIND.WHITE}
              icon={<EyeIcon strokeWidth={2} />}
              onClick={() => setIsPreviewOpen(true)}
            />
            <ProfilePreviewModal
              isOpen={isPreviewOpen}
              setIsOpen={setIsPreviewOpen}
              settings={{
                bgColor: backgroundColour,
                textColor: textColour,
                buttonBgColor: buttonBackgroundColour,
                buttonTextColor: buttonTextColour,
                companyLogo: logoFile,
              }}
            />
          </>
        )}
      >
        {organisationError && (
          <ErrorAlert message="Something went wrong. Please try again later" />
        )}
        <h4 className="text-xl leading-7 font-medium text-gray-900">
          Cover image
        </h4>
        <p className="text-sm leading-5 text-gray-500">
          Choose an image to display at the top of cardholder profile pages.
        </p>
        <div className="flex items-center mt-4 mb-0">
          {isLoading ? (
            <div className="w-full mb-6">
              <Skeleton height={175} />
            </div>
          ) : (
            <UploadPhoto
              title="Cover image"
              photo={logoFile}
              setPhoto={setLogoFile}
              size="large"
              aspectRatio={16 / 11}
              fileFormatMessage="Recommended dimensions 1024px x 704px"
              maxHeight={COVER_IMAGE.MAX_HEIGHT}
              maxWidth={COVER_IMAGE.MAX_WIDTH}
            />
          )}
        </div>
        <h4 className="text-xl leading-7 font-medium text-gray-900">Colours</h4>
        <p className="text-sm leading-5 text-gray-500">
          Create a custom theme for cardholder profile pages. Maintain good
          readability by ensuring there is sufficient contrast between text and
          background colours.
        </p>
        <div className="grid grid-cols-8 gap-4">
          <div className="col-span-6">
            <div className="mt-4 text-left">
              <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                Page background color
              </p>
              {isLoading ? (
                <Skeleton width={190} height={42} />
              ) : (
                <ColorPicker
                  color={backgroundColour}
                  setColor={setBackgroundColour}
                />
              )}
            </div>
          </div>
          <div className="col-span-6">
            <div className="mt-4 text-left">
              <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                Text color
              </p>
              {isLoading ? (
                <Skeleton width={190} height={42} />
              ) : (
                <ColorPicker color={textColour} setColor={setTextColour} />
              )}
            </div>
          </div>
          <div className="col-span-6">
            <div className="mt-4 text-left">
              <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                Button background color
              </p>
              {isLoading ? (
                <Skeleton width={190} height={42} />
              ) : (
                <ColorPicker
                  color={buttonBackgroundColour}
                  setColor={setButtonBackgroundColour}
                />
              )}
            </div>
          </div>
          <div className="col-span-6">
            <div className="mt-4 text-left">
              <p className="block mb-2 text-sm leading-5 font-medium text-gray-900">
                Button text color
              </p>
              {isLoading ? (
                <Skeleton width={190} height={42} />
              ) : (
                <ColorPicker
                  color={buttonTextColour}
                  setColor={setButtonTextColour}
                />
              )}
            </div>
          </div>
        </div>
      </InfoPanelContainer>
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Cancel"
            className="flex-1 xl:flex-none"
            kind={BUTTON_KIND.WHITE}
            onClick={window.location.reload}
          />
          <Button
            buttonText="Save changes"
            className="flex-1 xl:flex-none"
            loading={isSaving}
            onClick={handleOrganisationSettingsSave}
          />
        </div>
      </InfoPanelFooter>
    </div>
  );
}

function ContactExchange() {
  // moved over the existing code from SettingsPage
  const { orgID } = useAuth();

  const [nameToggle, setNameToggle] = useState(true);
  const [emailToggle, setEmailToggle] = useState(false);
  const [mobileNumberToggle, setMobileNumberToggle] = useState(false);
  const [jobTitleToggle, setJobTitleToggle] = useState(false);
  const [companyNameToggle, setCompanyNameToggle] = useState(false);
  const [consentStatementToggle, setConsentStatementToggle] = useState(false);

  const [consentStatement, setConsentStatement] = useState<IFile | undefined>(
    undefined,
  );
  const [isConsentLoading, setIsConsentLoading] = useState(false);
  const [consentFileError, setConsentFileError] = useState(false);
  const [organisationError, setOrganisationError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    getOrganisationSettings();
  }, []);

  async function getOrganisationSettings() {
    if (orgID === undefined) {
      return;
    }

    try {
      const {
        data: { data: organisation },
      } = await organisationsAPI.showOrganisationSettings(orgID);

      setNameToggle(organisation.contact_exchange.name);
      setEmailToggle(organisation.contact_exchange.email);
      setMobileNumberToggle(organisation.contact_exchange.mobile_number);
      setJobTitleToggle(organisation.contact_exchange.job_title);
      setCompanyNameToggle(organisation.contact_exchange.company_name);
      setConsentStatementToggle(organisation.contact_exchange.company_consent);
      setConsentStatement(organisation.company_consent_file);
    } catch {
      setOrganisationError(true);
    }
  }

  async function handleOrganisationSettingsSave() {
    if (orgID === undefined) {
      return;
    }

    try {
      setIsSaving(true);
      setSuccessMessage(undefined);
      setOrganisationError(false);

      await organisationsAPI.updateOrganisationSettings(orgID, {
        organisation_setting: {
          contact_exchange: {
            company_name: companyNameToggle,
            email: emailToggle,
            job_title: jobTitleToggle,
            mobile_number: mobileNumberToggle,
            name: nameToggle,
            company_consent: consentStatementToggle,
          },
          company_consent_file: consentStatement,
          company_consent_file_id: consentStatement
            ? consentStatement.id
            : null,
        },
      });

      setSuccessMessage(MESSAGES.organisation.template);
    } catch {
      setOrganisationError(true);
    } finally {
      setIsSaving(false);
    }
  }

  const contactExchangeToggles = [
    {
      name: 'Full name',
      enabled: nameToggle,
      setter: setNameToggle,
      disabled: true,
      message: 'Mandatory field',
    },
    {
      name: 'Email',
      enabled: emailToggle,
      setter: setEmailToggle,
    },
    {
      name: 'Mobile number',
      enabled: mobileNumberToggle,
      setter: setMobileNumberToggle,
    },
    {
      name: 'Job Title',
      enabled: jobTitleToggle,
      setter: setJobTitleToggle,
    },
    {
      name: 'Company Name',
      enabled: companyNameToggle,
      setter: setCompanyNameToggle,
    },
  ];

  return (
    <div className="pt-8 pb-16 md:pb-0">
      {successMessage && (
        <div className="pb-8">
          <SuccessAlert message={successMessage} />
        </div>
      )}
      <InfoPanelContainer
        title="Contact exchange form settings"
        information={MESSAGES.contact.exchange.description}
      >
        {organisationError && (
          <ErrorAlert message="Something went wrong. Please try again later" />
        )}
        <div className="grid grid-cols-1 gap-5">
          {contactExchangeToggles.map((field, index) => (
            <ToggleField
              key={index}
              label={field.name}
              enabled={field.enabled}
              setter={field.setter}
              disabled={field.disabled}
              message={field.message}
            />
          ))}
        </div>
        <div className="grid grid-cols-1 gap-5 mt-5">
          {!consentStatement && (
            <ToggleFieldWithInfo
              className="text-gray-600 text-sm font-normal leading-[150%]"
              label="Consent Statement"
              enabled={consentStatementToggle}
              setter={setConsentStatementToggle}
              disabled
              modalTitle="Consent Statement"
              modalDescription="If your company has data policies they would like future contacts to consent to, add the policy document here. We will ask contacts to approve the policies before they send their details back to you!"
            />
          )}
          {consentStatement && (
            <ToggleFieldWithInfo
              label="Consent Statement"
              enabled={consentStatementToggle}
              setter={setConsentStatementToggle}
              modalTitle="Consent Statement"
              modalDescription="If your company has data policies they would like future contacts to consent to, add the policy document here. We will ask contacts to approve the policies before they send their details back to you!"
            />
          )}
        </div>
        <div className="flex mt-0 pt-0">
          {orgID && (
            <FileUploadButtonComponent
              id="consent-statement-upload"
              filename={consentStatement?.file?.file_name}
              url={consentStatement?.original_url}
              onFileSelected={file => {
                setIsConsentLoading(true);
                filesAPI
                  .createAndUploadFile(orgID, new File([file], file.name))

                  .then(res => {
                    if (res?.data?.data.id) {
                      setConsentStatement(res.data.data);
                      setConsentStatementToggle(true);
                    }
                  })
                  .catch(() => setConsentFileError(true))
                  .finally(() => setIsConsentLoading(false));
              }}
              buttonTitle="Upload consent statement"
              fileFormat=".pdf, .jpeg, .png"
              fileFormatMessage="(.jpeg, .png or .pdf only)"
              loading={isConsentLoading}
              fileError={consentFileError}
            />
          )}
          {consentStatement?.file?.file_name && (
            <button
              type="button"
              onClick={() => {
                setConsentStatement(undefined);
                setConsentStatementToggle(false);
              }}
              className="appearance-none ml-2 text-sm leading-5 text-brand-500"
            >
              Remove
            </button>
          )}
        </div>
      </InfoPanelContainer>
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Cancel"
            className="flex-1 xl:flex-none"
            kind={BUTTON_KIND.WHITE}
            onClick={window.location.reload}
          />
          <Button
            buttonText="Save changes"
            className="flex-1 xl:flex-none"
            loading={isSaving}
            onClick={handleOrganisationSettingsSave}
          />
        </div>
      </InfoPanelFooter>
    </div>
  );
}
