import { useEffect } from 'react';
import SvgMap from 'svgmap';
import { nanoid } from 'nanoid';
import { IEventsByGeo } from '@/types/IAnalytics';
import 'svgmap/dist/svgMap.min.css';
import { formatNumber } from './Events';
import clsx from 'clsx';

type EventsByGeoProps = {
  data: IEventsByGeo;
  title: string;
  subtitle: string;
  mobileSubtitle: string;
};

function EventsByGeo({
  data,
  title,
  subtitle,
  mobileSubtitle,
}: EventsByGeoProps) {
  const dataPresent = Object.keys(data).length > 0;

  const getTop5ByTotal = (eventsByGeo: IEventsByGeo): IEventsByGeo => {
    const top5Countries = Object.keys(eventsByGeo)
      .sort((a, b) => eventsByGeo[b].total - eventsByGeo[a].total)
      .slice(0, 5);

    const result = top5Countries.reduce((acc, country) => {
      return {
        ...acc,
        [country]: {
          total: eventsByGeo[country].total,
          views: eventsByGeo[country].views,
          sends: eventsByGeo[country].sends,
          receives: eventsByGeo[country].receives,
        },
      };
    }, {});

    return result;
  };

  const id = nanoid();

  useEffect(() => {
    // eslint-disable-next-line no-new,@typescript-eslint/no-unsafe-call
    new SvgMap({
      targetElementID: `svgMap${id}`,
      colorMax: '#652dbf',
      colorMin: '#f9f5ff',
      showContinentSelector: false,
      mouseWheelZoomEnabled: false,
      showZoomReset: false,
      data: {
        data: {
          views: {
            name: 'Views',
            format: '{0} views',
            thousandSeparator: ',',
          },
          sends: {
            name: 'Sent',
            format: '{0} contacts sent',
            thousandSeparator: ',',
          },
          receives: {
            name: 'Receives',
            format: '{0} contacts received',
            thousandSeparator: ',',
          },
          total: {
            name: 'Total',
            format: '{0} total',
            thousandSeparator: ',',
          },
        },
        applyData: 'total',
        values: data,
      },
    });
  }, [data, id]);

  const countryNameByCode = (code: string) => {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

    return regionNames.of(code);
  };

  const countryFlagURL = (code: string) =>
    `https://cdn.jsdelivr.net/gh/hjnilsson/country-flags@latest/svg/${code.toLowerCase()}.svg`;

  return (
    <div className="w-full">
      <div className="w-full">
        <h2 className="text-gray-900 font-poppins font-medium text-2xl md:text-2xl">
          {title}
        </h2>
        <span className="text-gray-500 pt-2 text-sm hidden md:block">
          {subtitle}
        </span>
        <h3 className="text-gray-500 mt-2 md:hidden">{mobileSubtitle}</h3>
        <hr className="h-px my-4 bg-gray-200 border-0" />
        <div className="mt-4">
          <div
            className={clsx(
              'grid gap-2 w-full',
              dataPresent ? 'grid-cols-2' : 'grid-cols-1',
            )}
          >
            <div
              className="xl:col-span-1 col-span-2 self-center"
              id={`svgMap${id}`}
              key={`svgMap${id}`}
            />
            {Object.entries(data).length > 0 && (
              <div className="col-span-2 mt-4 md:mt-0 xl:col-span-1 rounded border border-gray-200">
                <table className="table-auto w-full font-poppins">
                  <thead className="uppercase text-xs">
                    <tr>
                      <th className="text-left p-3 pl-5 bg-gray-50 rounded-tl-lg font-medium">
                        Country
                      </th>
                      <th className="text-left p-3 bg-gray-50 rounded-tr-lg md:rounded-tr-none font-medium">
                        Profile Views
                      </th>
                      <th className="text-left p-3 bg-gray-50 font-medium hidden xl:table-cell">
                        Contacts Received
                      </th>
                      <th className="text-left p-3 bg-gray-50 rounded-tr-lg font-medium hidden xl:table-cell">
                        Contacts Sent
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm">
                    {Object.entries(getTop5ByTotal(data)).map(
                      ([country, top5Data]) => (
                        <tr
                          key={country}
                          className="border-t border-gray-200 h-13"
                        >
                          <td className="pl-4">
                            <div className="flex flex-row align-middle">
                              <img
                                className="rounded w-10 border-2 border-gray-50 mr-2"
                                src={countryFlagURL(country)}
                                alt={country}
                              />
                              <div className="font-medium">
                                {countryNameByCode(country)}
                              </div>
                            </div>
                          </td>
                          <td className="pl-3 text-gray-500">
                            {formatNumber(top5Data.views)}
                          </td>
                          <td className="pl-3 text-gray-500 hidden xl:table-cell">
                            {formatNumber(top5Data.receives)}
                          </td>
                          <td className="pl-3 text-gray-500 hidden xl:table-cell">
                            {formatNumber(top5Data.sends)}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsByGeo;
