import { FC, useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Layout from '@/components/Layout';
import analyticsAPI from '@/api/analytics';
import { AnalyticsResponse } from '@/types/IAnalytics';
import 'react-loading-skeleton/dist/skeleton.css';
import CollectionAnalyticsDashboard from '@/components/Analytics/CollectionAnalyticsDashboard';
import collectionsAPI from '@/api/collections';
import ICollection from '@/types/ICollection';
import adminAPI from '@/api/admin';

export const LoadingSkeleton = () => (
  <>
    <div className="grid gap-2 grid-flow-col mb-4">
      <Skeleton width="280px" height="120px" />
      <Skeleton width="280px" height="120px" />
      <Skeleton width="280px" height="120px" />
      <Skeleton width="280px" height="120px" />
    </div>
    <div className="grid gap-2 grid-flow-col">
      <div className="grid gap-2">
        <Skeleton width="620px" height="350px" />
        <Skeleton width="620px" height="500px" />
      </div>
      <div className="grid gap-2">
        <Skeleton width="500px" height="250px" />
        <Skeleton width="500px" height="600px" />
      </div>
    </div>
  </>
);

const CollectionAnalyticsPage: FC = () => {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsResponse>();
  const [collectionsData, setCollectionsData] = useState<ICollection[]>();

  const currentCollectionID = parseInt(
    new URLSearchParams(window.location.search).get('collectionId') || '0',
    10,
  );

  const fetchData = useCallback(
    async (
      from?: string,
      to?: string,
      orgIds?: number[],
      profileIds?: number[],
      collectionId?: number,
    ) => {
      const analyticsResponse = analyticsAPI.listCollectionAnalytics(
        collectionId || currentCollectionID || -1,
        from,
        to,
        orgIds,
        profileIds,
      );
      const collectionsResponse = collectionsAPI.listCollections();

      const [analytics, collections] = await Promise.all([
        analyticsResponse,
        collectionsResponse,
      ]);

      setAnalyticsData(analytics.data);
      setCollectionsData(collections.data.data);
    },
    [currentCollectionID],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Layout pageName="Collection Analytics" className="bg-gray-100">
      {analyticsData === undefined ? (
        <LoadingSkeleton />
      ) : (
        <CollectionAnalyticsDashboard
          data={analyticsData}
          collections={collectionsData}
          refetchData={fetchData}
          listProfiles={({ search }: { search?: string }) =>
            adminAPI.listProfiles({ search })
          }
          listOrgs={({ search }: { search?: string }) =>
            adminAPI.listOrganisations({ search })
          }
        />
      )}
    </Layout>
  );
};

export default CollectionAnalyticsPage;
