import analyticsAPI from '@/api/analytics';
import organisationsAPI from '@/api/organisations';
import profilesAPI from '@/api/profiles';
import AnalyticsDashboard from '@/components/Analytics/AnalyticsDashboard';
import Layout from '@/components/Layout';
import useAuth from '@/hooks/useAuth';
import { AnalyticsResponse } from '@/types/IAnalytics';
import { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export function LoadingSkeleton() {
  return (
    <div className="mb-8 md:mb-0">
      {/* filters */}
      <div className="flex justify-end flex-col flex-wrap items-end pt-8 md:pt-0 pb-4 md:pb-8">
        <div className="flex gap-2 flex-row flex-wrap items-center">
          <Skeleton width={109} height={38} />
          <Skeleton width={109} height={38} />
          <Skeleton width={225} height={38} />
        </div>
      </div>
      {/* Domain analytics */}
      <div className="bg-white rounded-lg p-4 md:p-7 mt-2 md:mt-0">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col flex-1">
            <Skeleton width="40%" height={32} />
            <Skeleton width="75%" height={28} className="mt-2" />
          </div>
          <div className="flex flex-col">
            <Skeleton width={80} height={24} />
            <span className="self-end">
              <Skeleton width={45} height={32} />
            </span>
          </div>
        </div>
        <hr className="h-px mt-4 mb-8 bg-gray-200 border-0" />
        <div className="grid md:grid-cols-3 gap-6 md:gap-10 w-full">
          <Skeleton height="120px" />
          <Skeleton height="120px" />
          <Skeleton height="120px" />
        </div>
      </div>
      {/* Most active profiles */}
      <div className="grid grid-cols-5 gap-4">
        <div className="bg-white rounded-lg p-4 md:p-7 mt-4 col-span-5 md:col-span-5">
          <div>
            <Skeleton width="50%" height={32} />
            <Skeleton width="70%" height={20} className="mt-2" />
            <hr className="h-px mt-4 mb-8 bg-gray-200 border-0 " />
          </div>
          <Skeleton height={340} />
        </div>
      </div>
      {/* top referrers and social links */}
      <div className="grid grid-cols-4 gap-4">
        <div className="bg-white rounded-lg p-4 md:p-7 mt-4 col-span-4 md:col-span-2">
          <Skeleton width="60%" height={32} />
          <Skeleton width="85%" height={28} />
          <hr className="h-px mt-4 mb-8 bg-gray-200 border-0" />
          <Skeleton height={340} />
        </div>
        <div className="bg-white rounded-lg p-4 md:p-7 mt-4 col-span-4 md:col-span-2">
          <Skeleton width="60%" height={32} />
          <Skeleton width="85%" height={28} />
          <hr className="h-px mt-4 mb-8 bg-gray-200 border-0" />
          <Skeleton height={340} />
        </div>
      </div>
      {/* top geolocation */}
      <div className="bg-white rounded-lg p-4 md:p-7 mt-4 flex col-span-12 md:col-span-12">
        <div className="w-full">
          <Skeleton width="45%" height={32} />
          <Skeleton width="85%" height={28} />
          <hr className="h-px my-4 bg-gray-200 border-0 " />
          <div className="mt-4">
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className="col-span-2 md:col-span-1 self-center">
                <Skeleton height={300} />
              </div>
              <div className="col-span-2 mt-4 md:mt-0 md:col-span-1">
                <Skeleton height={300} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* profile analytics */}
      <div className="bg-white rounded-lg p-4 md:p-7 mt-4 flex flex-col col-span-12 md:col-span-12">
        <Skeleton width="45%" height={32} />
        <Skeleton width="85%" height={28} />
        <hr className="h-px mt-4 mb-8 bg-gray-200 border-0" />
        <Skeleton height={500} />
      </div>
    </div>
  );
}

function AnalyticsPage() {
  const { orgID, userScope, isUserViewForAdmin } = useAuth();
  const isSuperUser = userScope === 'admin';
  const showSuperUserAnalytics = isSuperUser && !isUserViewForAdmin;

  const [analytics, setAnalytics] = useState<AnalyticsResponse | undefined>(
    undefined,
  );

  const decorateAnalytics = (analytics: AnalyticsResponse) => {
    analytics.profile_views.entries = populateEntries(
      analytics.profile_views.entries,
    );
    analytics.contacts_sent.entries = populateEntries(
      analytics.contacts_sent.entries,
    );
    analytics.contacts_received.entries = populateEntries(
      analytics.contacts_received.entries,
    );
    analytics.top_referrers.app.entries = populateEntries(
      analytics.top_referrers.app.entries,
    );
    analytics.top_referrers.card.entries = populateEntries(
      analytics.top_referrers.card.entries,
    );
    analytics.top_referrers.link.entries = populateEntries(
      analytics.top_referrers.link.entries,
    );

    return analytics;
  };

  const hasDateFormat = (key: string) => {
    // Check if the key has a date only format
    return key.match(/^\d{4}-\d{2}-\d{2}$/);
  };

  const getNextDate = (key: string) => {
    const date = new Date(key);
    date.setDate(date.getDate() + 1);

    return [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2),
    ].join('-');
  };

  const getNextHour = (key: string) => {
    const date = new Date(key);
    if (date.getHours() === 23) {
      return `${getNextDate(key)} 00:00`;
    }
    const nextHour = (date.getHours() + 1).toString().padStart(2, '0');
    const dateStr = [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2),
    ].join('-');
    return `${dateStr} ${nextHour}:00`;
  };

  const populateEntries = (entries: { [milestone: string]: number }) => {
    const total = Object.keys(entries).length;

    if (total !== 1) return entries;

    const key = Object.keys(entries)[0];
    const value = entries[key];

    const otherKey = hasDateFormat(key) ? getNextDate(key) : getNextHour(key);
    return { [key]: value, [otherKey]: 0 };
  };

  const listAnalytics = useCallback(
    async (
      from?: string,
      to?: string,
      groupIds?: number[],
      profileIds?: number[],
    ) => {
      if (orgID === undefined) {
        return;
      }

      const { data: analytics } = await analyticsAPI.listAnalytics(
        orgID,
        from,
        to,
        groupIds,
        profileIds,
      );
      const decoratedAnalytics = decorateAnalytics(analytics);
      setAnalytics(decoratedAnalytics);
    },
    [orgID],
  );

  useEffect(() => {
    listAnalytics();
  }, [
    orgID,
    isSuperUser,
    listAnalytics,
    isUserViewForAdmin,
    showSuperUserAnalytics,
  ]);

  return (
    <Layout pageName="Analytics" className="bg-gray-100">
      {analytics === undefined ? (
        <LoadingSkeleton />
      ) : (
        <AnalyticsDashboard
          data={analytics}
          refetchData={listAnalytics}
          listProfiles={({ search }) =>
            profilesAPI.listProfiles({ search, orgID })
          }
          listGroups={({ search }) =>
            organisationsAPI.listOrganisationGroups({ search, orgID })
          }
        />
      )}
    </Layout>
  );
}

export default AnalyticsPage;
