import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IFile from '@/types/IFile';

export interface ISamlConfig {
  identifier: string;
  reply_url: string;
  metadata_file: IFile;
  is_active: boolean;
}

const ssoApi = {
  getSamlConfig: (orgID: number): Promise<AxiosResponse<ISamlConfig>> => {
    return axiosApiService.get(`${BASE_URL}/api/organisations/${orgID}/saml`);
  },
  updateSamlConfig: (
    orgID: number,
    saml_config: {
      is_active: boolean;
      metadata_file_id: number;
    },
  ): Promise<AxiosResponse<{ data: ISamlConfig }>> => {
    return axiosApiService.put(`${BASE_URL}/api/organisations/${orgID}/saml`, {
      saml_config,
    });
  },
};

export default ssoApi;
