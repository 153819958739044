import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/css/pagination';
import Button from '../Button';
import bannerData from './data';
import isIOS from '@/helpers/isIos';
import { AppStoreLink, PlayStoreLink } from '@/constants/apps';

const BlogCardImage: React.FC = () => {
  const [appLink, setAppLink] = useState<string>('/');

  useEffect(() => {
    setAppLink(isIOS() ? AppStoreLink : PlayStoreLink);
  }, []);

  const swiperParams = {
    pagination: {
      clickable: true,
    },
    loop: true,
    autoplay: {
      delay: 10000, // 10 seconds
      disableOnInteraction: false,
    },
    slidesPerView: 1, // Display 1 slide per view on all screen sizes
    breakpoints: {
      1024: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 1,
      },
    },
  };

  return (
    <div className="relative pb-7 text-gray-600 body-font">
      <div className="w-full mx-auto max-w-[50rem] lg:max-w-[67.5rem] large-screen:max-w-[100vw] !static [&>div]:!overflow-y-visible">
        {/* Set maximum width for Swiper container */}
        <Swiper
          {...swiperParams}
          modules={[Autoplay, Pagination]}
          className="!static"
        >
          {bannerData.map((banner) => (
            <SwiperSlide key={banner.id} className="">
              <div className="h-full">
                <div className="rounded-t-lg bg-pdfview-text">
                  <img
                    className="hidden object-contain object-center w-full h-48 rounded-t-lg md:h-[9.375rem] xl:object-cover sm:h-32 2xl:h-[12.5rem] sm:block"
                    src={banner.image}
                    alt="blog"
                  />
                  <img
                    className="object-contain object-center w-full h-48 rounded-t-lg lg:object-cover sm:h-32 lg:h-[9.375rem] 2xl:h-[12.5rem] sm:hidden"
                    src={banner.mobileImage}
                    alt="blog"
                  />
                </div>
                <div className="px-6 py-6 border border-gray-200 rounded-b-lg sm:py-4 bg-white">
                  <h6 className="text-base font-medium text-purple-500 sm:text-sm">
                    {banner.title}
                  </h6>
                  <div className="grid items-center grid-cols-1 pt-1 sm:grid-cols-[4fr_1fr]">
                    <h4 className="text-2xl font-medium leading-7 text-gray-900">
                      {banner.SubTitle}
                    </h4>
                    <div className="flex justify-end w-full mt-4 sm:mt-0">
                      <Button
                        buttonText={banner.button}
                        onClick={() => {
                          if (banner.shouldRedirectToAppStore) {
                            window.open(appLink, '_blank');
                          } else {
                            window.open(banner.url, '_blank');
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-pagination" />
        </Swiper>
      </div>
    </div>
  );
};

export default BlogCardImage;
