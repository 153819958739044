import Chart from 'react-apexcharts';

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';

import { ApexOptions } from 'apexcharts';

import { ITopReferrers } from '@/types/IAnalytics';
import { cx } from '@emotion/css';

import { formatDateFilter, formatNumber } from './Events';

type TopReferrersProps = {
  data: ITopReferrers;
  title: string;
  subtitle: string;
  mobileSubtitle: string;
  dateFilter: { from: string | undefined; to: string | undefined };
};

const defaultHeight = 100;

const defaultOptions: ApexOptions = {
  chart: {
    sparkline: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    area: {
      fillTo: 'end',
    },
  },
  theme: {
    monochrome: {
      enabled: true,
      color: '#873CFF',
    },
  },
  tooltip: {
    style: {
      fontSize: '14px',
    },
  },
  stroke: {
    curve: 'smooth',
  },
};

function TopReferrers({
  data: { app, card, link },
  dateFilter: { from = 'start', to = 'end' },
  title,
  subtitle,
  mobileSubtitle,
}: TopReferrersProps) {
  const dataPresent = {
    app:
      Object.values(app.entries).length > 1 &&
      Object.keys(app.entries).length > 1,
    card:
      Object.values(card.entries).length > 1 &&
      Object.keys(card.entries).length > 1,
    link:
      Object.values(link.entries).length > 1 &&
      Object.keys(link.entries).length > 1,
  };

  const { from: formattedFrom, to: formattedTo } = formatDateFilter(from, to);

  const placeholderData = {
    change: 0,
    total: 0,
    entries: {
      [formattedFrom]: 0,
      [formattedTo]: 0,
    },
  };

  const series = {
    app: [
      {
        name: 'App',
        data: dataPresent.app
          ? Object.values(app.entries)
          : Object.values(placeholderData.entries),
        total: app.total,
      },
    ],
    card: [
      {
        name: 'Card',
        data: dataPresent.card
          ? Object.values(card.entries)
          : Object.values(placeholderData.entries),
        total: card.total,
      },
    ],
    link: [
      {
        name: 'Link',
        data: dataPresent.link
          ? Object.values(link.entries)
          : Object.values(placeholderData.entries),
        total: link.total,
      },
    ],
  };

  const lastMonthValues = {
    app: formatNumber(series.app[0].total),
    card: formatNumber(series.card[0].total),
    link: formatNumber(series.link[0].total),
  };

  const referrers = [
    {
      name: 'App',
      type: 'app',
      hasData: dataPresent.app,
      series: series.app,
      change: app.change,
      labels: dataPresent.app
        ? Object.keys(app.entries)
        : Object.keys(placeholderData.entries),
      lastMonthValues: lastMonthValues.app,
    },
    {
      name: 'Card',
      type: 'card',
      hasData: dataPresent.card,
      series: series.card,
      change: card.change,
      labels: dataPresent.card
        ? Object.keys(card.entries)
        : Object.keys(placeholderData.entries),
      lastMonthValues: lastMonthValues.card,
    },
    {
      name: 'Link',
      type: 'link',
      hasData: dataPresent.link,
      series: series.link,
      change: link.change,
      labels: dataPresent.link
        ? Object.keys(link.entries)
        : Object.keys(placeholderData.entries),
      lastMonthValues: lastMonthValues.link,
    },
  ];

  return (
    <div className="font-poppins w-full">
      <h2 className="text-gray-900 font-medium text-2xl md:text-2xl">
        {title}
      </h2>
      <span className="text-gray-500 pt-2 text-sm hidden md:block">
        {subtitle}
      </span>
      <h3 className="text-gray-500 mt-2 md:hidden">{mobileSubtitle}</h3>
      <hr className="h-px mt-4 mb-8 bg-gray-200 border-0" />
      <div className="grid grid-flow-row gap-8">
        {referrers.map((referrer, index) => (
          <div key={index} className="grid grid-cols-3 gap-8 relative">
            <div className="col-span-2">
              <Chart
                height={defaultHeight}
                options={{
                  ...defaultOptions,
                  labels: referrer.labels,
                  tooltip: {
                    ...defaultOptions.tooltip,
                    enabled: referrer.hasData,
                  },
                }}
                series={referrer.series}
                type="line"
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="w-full">
                <div className="flex justify-between">
                  <div className="text-gray-500">{referrer.name}</div>
                  <div>
                    <div className="flex justify-end md:mr-2">
                      {referrer.change !== undefined && (
                        <div
                          className={cx(
                            'px-2.5 py-0.5 font-medium flex flex-row items-center text-xs rounded-full',
                            referrer.change > 0
                              ? 'bg-emerald-100 text-emerald-800'
                              : referrer.change === 0
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-red-100 text-red-800',
                          )}
                        >
                          {referrer.change !== 0 && (
                            <span
                              className={cx(
                                'pr-1',
                                link.change > 0
                                  ? 'text-emerald-500'
                                  : 'text-red-500',
                              )}
                            >
                              {referrer.change > 0 ? (
                                <ArrowUpIcon
                                  width={10}
                                  height={10}
                                  strokeWidth={4}
                                />
                              ) : (
                                <ArrowDownIcon
                                  width={10}
                                  height={10}
                                  strokeWidth={4}
                                />
                              )}
                            </span>
                          )}
                          {referrer.change}%
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-gray-900 text-2xl font-medium">
                  {referrer.lastMonthValues}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TopReferrers;
