const MobileDeviceIconYellow = () => {
  return (
    <svg
      width={26}
      height={28}
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 22H12.51M8.5 25H16.5C17.0304 25 17.5391 24.7893 17.9142 24.4142C18.2893 24.0391 18.5 23.5304 18.5 23V9C18.5 8.46957 18.2893 7.96086 17.9142 7.58579C17.5391 7.21071 17.0304 7 16.5 7H8.5C7.96957 7 7.46086 7.21071 7.08579 7.58579C6.71071 7.96086 6.5 8.46957 6.5 9V23C6.5 23.5304 6.71071 24.0391 7.08579 24.4142C7.46086 24.7893 7.96957 25 8.5 25Z"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="11.5" width={14} height={14} rx={7} fill="#FEF3C7" />
      <path
        d="M15 7H15.005M18.5 7H18.505M22 7H22.005M15.5 7C15.5 7.13261 15.4473 7.25979 15.3536 7.35355C15.2598 7.44732 15.1326 7.5 15 7.5C14.8674 7.5 14.7402 7.44732 14.6464 7.35355C14.5527 7.25979 14.5 7.13261 14.5 7C14.5 6.86739 14.5527 6.74021 14.6464 6.64645C14.7402 6.55268 14.8674 6.5 15 6.5C15.1326 6.5 15.2598 6.55268 15.3536 6.64645C15.4473 6.74021 15.5 6.86739 15.5 7ZM19 7C19 7.13261 18.9473 7.25979 18.8536 7.35355C18.7598 7.44732 18.6326 7.5 18.5 7.5C18.3674 7.5 18.2402 7.44732 18.1464 7.35355C18.0527 7.25979 18 7.13261 18 7C18 6.86739 18.0527 6.74021 18.1464 6.64645C18.2402 6.55268 18.3674 6.5 18.5 6.5C18.6326 6.5 18.7598 6.55268 18.8536 6.64645C18.9473 6.74021 19 6.86739 19 7ZM22.5 7C22.5 7.13261 22.4473 7.25979 22.3536 7.35355C22.2598 7.44732 22.1326 7.5 22 7.5C21.8674 7.5 21.7402 7.44732 21.6464 7.35355C21.5527 7.25979 21.5 7.13261 21.5 7C21.5 6.86739 21.5527 6.74021 21.6464 6.64645C21.7402 6.55268 21.8674 6.5 22 6.5C22.1326 6.5 22.2598 6.55268 22.3536 6.64645C22.4473 6.74021 22.5 6.86739 22.5 7Z"
        stroke="#FBBF24"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobileDeviceIconYellow;
