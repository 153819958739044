import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import { ICursorPaging } from '@/api/orders';
import Button, { BUTTON_KIND } from '@/components/Button';
import InputSelect from '@/components/InputSelect';
import IOrder from '@/types/IOrder';

interface ICursorPagination {
  id: string;
  page: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  fetchQuery: (
    page: string,
    pageSize: number,
  ) => Promise<{
    data: IOrder[];
    paging: ICursorPaging;
  }>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CursorPagination({
  id,
  page = '',
  setPage,
  pageSize,
  setPageSize,
  fetchQuery,
  setIsLoading,
}: ICursorPagination): JSX.Element {
  const { isLoading, data, isPreviousData } = useQuery(
    id,
    () => fetchQuery(page, pageSize),
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
    },
  );

  useEffect(() => {
    setIsLoading(isLoading);
  }, [setIsLoading, isLoading]);

  const nextCursor = data?.paging?.next?.page_info;
  const previousCursor = data?.paging?.previous?.page_info;

  if (!data || !data.data || !data.data.length) {
    return <div />;
  }

  return (
    <div className="bg-white flex items-center justify-between">
      <div className="flex-1 flex justify-between sm:hidden">
        <Button
          kind={BUTTON_KIND.WHITE}
          buttonText="Previous"
          disabled={!previousCursor}
          onClick={() => {
            if (previousCursor) setPage(previousCursor);
          }}
        />
        <Button
          kind={BUTTON_KIND.WHITE}
          buttonText="Next"
          disabled={!nextCursor}
          onClick={() => {
            if (nextCursor) setPage(nextCursor);
          }}
        />
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex space-x-4 items-center justify-between">
          <InputSelect
            id="page-size"
            label=""
            value={String(pageSize)}
            onChange={value => {
              setPage('');
              setPageSize(Number(value));
            }}
            options={[
              { label: '10 per page', value: '10' },
              { label: '20 per page', value: '20' },
              { label: '50 per page', value: '50' },
              { label: '100 per page', value: '100' },
              { label: '250 per page', value: '250' },
            ]}
          />
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              type="button"
              disabled={!previousCursor}
              onClick={() => {
                if (previousCursor) setPage(previousCursor);
              }}
              className="relative transition transition-all inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              disabled={!nextCursor}
              onClick={() => {
                if (nextCursor) setPage(nextCursor);
              }}
              className="relative transition transition-all inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
