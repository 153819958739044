import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { UserIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

import clsx from 'clsx';

import organisationsAPI from '@/api/organisations';
import withRestriction from '@/helpers/withRestriction';
import useAuth from '@/hooks/useAuth';
import { IOrganisationUpdate } from '@/types/IOrganisation';

import ListWithLinkRestricted from './ListWithLinkRestricted';

function ListWithLink({ maskContent = false }: { maskContent?: boolean }) {
  const { orgID } = useAuth();

  const { isFetching } = useQuery('recentUpdates', listRecentUpdates, {
    enabled: orgID !== undefined,
  });

  const [topProfile, setTopProfile] = useState<
    IOrganisationUpdate['top_profile'] | undefined
  >(undefined);

  async function listRecentUpdates() {
    if (orgID === undefined) {
      return;
    }

    const { data: recentUpdates } = await organisationsAPI.listRecentUpdates(
      orgID,
    );

    setTopProfile(recentUpdates.data.top_profile);

    return recentUpdates;
  }

  return (
    <div>
      <h4 className="block pb-3 text-2xl font-medium leading-8 text-grey-900">
        Top performing profile
      </h4>
      <div>
        <div
          className={clsx(
            'z-50 absolute w-full h-3/5 backdrop-blur-sm items-center justify-center',
            maskContent || topProfile === null ? 'flex' : 'hidden',
          )}
        >
          No data available.
        </div>
        <div
          className={clsx('p-2', {
            'pointer-events-none': topProfile === null,
          })}
        >
          <Link to="/" className="relative flex justify-between gap-x-6">
            <div className="flex gap-x-4">
              {isFetching || topProfile === undefined ? (
                <Skeleton
                  className="flex-none"
                  width={46}
                  height={46}
                  style={{
                    borderRadius: '999px',
                  }}
                />
              ) : topProfile === null || !topProfile.photo?.original_url ? (
                <div className="hidden lg:block h-10 w-10 rounded-full bg-gray-200 overflow-hidden text-center">
                  <UserIcon className="h-10 w-10 mt-1 rounded-full text-gray-600" />
                </div>
              ) : (
                <img
                  className="flex-none w-12 h-12 rounded-full bg-gray-50"
                  src={topProfile.photo.original_url}
                  alt="Profile photo"
                />
              )}
              {topProfile !== undefined ? (
                <div className="flex flex-col justify-center">
                  <span className="block text-sm font-medium text-gray-900">
                    {topProfile === null
                      ? 'Full Name'
                      : `${topProfile.first_name} ${topProfile.last_name}`}
                  </span>
                  <span className="text-sm font-normal text-gray-500">
                    {topProfile === null
                      ? `example@mail.com`
                      : topProfile.email}
                  </span>
                </div>
              ) : (
                <div className="flex flex-col justify-center">
                  <Skeleton width={100} height={20} />
                  <Skeleton width={150} height={20} />
                </div>
              )}
            </div>
            <div className="flex items-center gap-x-4">
              <ArrowRightIcon />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withRestriction(ListWithLink, ListWithLinkRestricted);
