import React from 'react';

interface ILogo {
  className: string;
}

const Logo: React.FC<ILogo> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 89 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M37.7779 19.5588C36.9542 19.5577 36.1646 19.2301 35.5822 18.6477C34.9999 18.0653 34.6722 17.2757 34.6711 16.4521V9.20502H38.0187V6.52855H34.6711V3.34149H31.9946V16.4521C31.9946 17.4755 32.2661 18.4806 32.7814 19.3647C33.2968 20.2489 34.0376 20.9805 34.9281 21.4848C35.8186 21.9891 36.827 22.248 37.8503 22.2352C38.8737 22.2224 39.8752 21.9382 40.7529 21.4118L39.3734 19.1162C38.8918 19.4062 38.3401 19.5593 37.7779 19.5588Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.7298 6.39602C52.2484 6.62997 53.667 7.29804 54.8146 8.31973V6.57179H57.4911V21.9697H54.8146V20.2218C53.667 21.2435 52.2484 21.9115 50.7298 22.1455C49.2112 22.3794 47.6573 22.1693 46.2554 21.5404C44.8535 20.9115 43.6633 19.8906 42.8283 18.6008C41.9933 17.311 41.549 15.8073 41.549 14.2708C41.549 12.7342 41.9933 11.2305 42.8283 9.94067C43.6633 8.65086 44.8535 7.63001 46.2554 7.0011C47.6573 6.37219 49.2112 6.16206 50.7298 6.39602ZM46.5883 18.6683C47.4584 19.2493 48.4813 19.5592 49.5276 19.5588C50.9293 19.5572 52.2731 18.9996 53.2643 18.0085C54.2554 17.0173 54.813 15.6735 54.8146 14.2718C54.815 13.2255 54.5051 12.2026 53.9241 11.3325C53.3431 10.4624 52.517 9.78419 51.5505 9.38362C50.584 8.98304 49.5203 8.87812 48.4941 9.08214C47.468 9.28616 46.5253 9.78994 45.7855 10.5298C45.0457 11.2696 44.5419 12.2122 44.3379 13.2384C44.1339 14.2646 44.2388 15.3282 44.6394 16.2947C45.04 17.2613 45.7182 18.0873 46.5883 18.6683Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.3299 8.31975C64.7854 7.02086 66.6682 6.30336 68.619 6.30416C69.9087 6.30429 71.179 6.61764 72.3208 7.21725C73.4626 7.81686 74.4416 8.6848 75.1738 9.74645C75.906 10.8081 76.3694 12.0317 76.5242 13.312C76.6789 14.5924 76.5204 15.8911 76.0623 17.0967C75.6042 18.3022 74.8601 19.3784 73.8941 20.2328C72.928 21.0872 71.769 21.6942 70.5165 22.0017C69.264 22.3091 67.9556 22.3077 66.7038 21.9977C65.4519 21.6876 64.2941 21.0782 63.3299 20.2218V28H60.6534V6.57181H63.3299V8.31975ZM65.6781 18.6713C66.549 19.2516 67.5725 19.5605 68.619 19.5589C70.0195 19.555 71.3614 18.9966 72.3511 18.0057C73.3408 17.0149 73.8977 15.6723 73.8999 14.2718C73.9003 13.2253 73.5902 12.2022 73.0089 11.3319C72.4276 10.4617 71.6012 9.78344 70.6343 9.38305C69.6674 8.98266 68.6035 8.8781 67.5771 9.08262C66.5508 9.28714 65.6082 9.79153 64.8686 10.532C64.129 11.2724 63.6258 12.2156 63.4224 13.2422C63.2191 14.2688 63.3249 15.3326 63.7264 16.299C64.128 17.2655 64.8072 18.0911 65.6781 18.6713Z"
          fill="currentColor"
        />
        <path
          d="M85.1627 19.5591C85.7131 19.5531 86.252 19.4009 86.7243 19.1182L88.1058 21.4118C87.2281 21.9387 86.2263 22.2233 85.2027 22.2364C84.179 22.2495 83.1703 21.9907 82.2794 21.4863C81.3886 20.9819 80.6476 20.2501 80.1321 19.3657C79.6166 18.4812 79.3452 17.4758 79.3455 16.4521V3.34149H82.0219V6.51825H85.3675V9.19472H82.0219V16.4418C82.02 16.9922 82.1644 17.5332 82.4402 18.0095C82.7161 18.4858 83.1135 18.8803 83.5919 19.1526C84.0702 19.4248 84.6123 19.5651 85.1627 19.5591Z"
          fill="currentColor"
        />
        <path
          d="M15.6022 2.70942C15.6022 4.11596 14.462 5.25618 13.0554 5.25618C11.6489 5.25618 10.5087 4.11596 10.5087 2.70942C10.5087 1.30288 11.6489 0.162659 13.0554 0.162659C14.462 0.162659 15.6022 1.30288 15.6022 2.70942Z"
          fill="currentColor"
        />
        <path
          d="M8.56926 5.62263C8.56926 7.02917 7.42903 8.16939 6.02249 8.16939C4.61596 8.16939 3.47573 7.02917 3.47573 5.62263C3.47573 4.21609 4.61596 3.07587 6.02249 3.07587C7.42903 3.07587 8.56926 4.21609 8.56926 5.62263Z"
          fill="currentColor"
        />
        <path
          d="M3.10926 15.2024C4.5158 15.2024 5.65602 14.0621 5.65602 12.6556C5.65602 11.2491 4.5158 10.1088 3.10926 10.1088C1.70272 10.1088 0.5625 11.2491 0.5625 12.6556C0.5625 14.0621 1.70272 15.2024 3.10926 15.2024Z"
          fill="currentColor"
        />
        <path
          d="M8.56926 19.6906C8.56926 21.0971 7.42903 22.2373 6.02249 22.2373C4.61596 22.2373 3.47573 21.0971 3.47573 19.6906C3.47573 18.284 4.61596 17.1438 6.02249 17.1438C7.42903 17.1438 8.56926 18.284 8.56926 19.6906Z"
          fill="currentColor"
        />
        <path
          d="M13.0554 25.1506C14.462 25.1506 15.6022 24.0103 15.6022 22.6038C15.6022 21.1972 14.462 20.057 13.0554 20.057C11.6489 20.057 10.5087 21.1972 10.5087 22.6038C10.5087 24.0103 11.6489 25.1506 13.0554 25.1506Z"
          fill="currentColor"
        />
        <path
          d="M22.6372 19.6906C22.6372 21.0971 21.497 22.2373 20.0904 22.2373C18.6839 22.2373 17.5437 21.0971 17.5437 19.6906C17.5437 18.284 18.6839 17.1438 20.0904 17.1438C21.497 17.1438 22.6372 18.284 22.6372 19.6906Z"
          fill="currentColor"
        />
        <path
          d="M23.0037 15.2024C24.4102 15.2024 25.5504 14.0621 25.5504 12.6556C25.5504 11.2491 24.4102 10.1088 23.0037 10.1088C21.5971 10.1088 20.4569 11.2491 20.4569 12.6556C20.4569 14.0621 21.5971 15.2024 23.0037 15.2024Z"
          fill="currentColor"
        />
        <path
          d="M22.6372 5.62263C22.6372 7.02917 21.497 8.16939 20.0904 8.16939C18.6839 8.16939 17.5437 7.02917 17.5437 5.62263C17.5437 4.21609 18.6839 3.07587 20.0904 3.07587C21.497 3.07587 22.6372 4.21609 22.6372 5.62263Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Logo;
