import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IList, { IListPaging } from '@/types/IList';
import { HostedPage, IBillingAddress, IPaymentCard, ISubscription, PortalSession } from '@/types/IOrganisation';

const saasAPI = {
  listSubscriptions: ({
    orgID,
    page = 1,
    pageSize = 10,
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: ISubscription[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/subscriptions?page_size=${pageSize}&page=${page}`,
    );
  },
  listPaymentCards: ({
    orgID,
    page = 1,
    pageSize = 10,
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IPaymentCard[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/payment_cards?page_size=${pageSize}&page=${page}`,
    );
  },
  addPaymentCard: (
    orgID: number,
    body: {
      token_id: string
    }
  ): Promise<AxiosResponse<{ data: IPaymentCard }>> => {
    return axiosApiService.post(`${BASE_URL}/api/organisations/${orgID}/payment_cards`, body);
  },
  createBillingAddress: (orgID: number, body: {
    city: string,
    country: string,
    email: string,
    first_name: string,
    last_name: string,
    company: string,
    line1: string,
    state: string,
    zip: string,
  }): Promise<AxiosResponse<{ data: IBillingAddress }>> => {
    return axiosApiService.post(`${BASE_URL}/api/organisations/${orgID}/billing_address`, {
      billing_address: body,
    });
  },
  createSubscription: (orgID: number, body: {
    plan_id: string,
    quantity: number
  }): Promise<AxiosResponse<{ data: ISubscription }>> => {
    return axiosApiService.post(`${BASE_URL}/api/organisations/${orgID}/subscriptions`, body);
  },
  showSubscription: (orgID: number, subscription_id: string): Promise<AxiosResponse<{ data: ISubscription }>> => {
    return axiosApiService.get(`${BASE_URL}/api/organisations/${orgID}/subscriptions/${subscription_id}`);
  },
  createCheckout:(orgID: number, body: {
    plan_id: string
  }): Promise<AxiosResponse<HostedPage>> => {
    return axiosApiService.post(`${BASE_URL}/api/organisations/${orgID}/checkout`, body);
  },
  syncCheckout:(orgID: number, hostedPageId: string): Promise<AxiosResponse<{ data: ISubscription }>> => {
    return axiosApiService.post(`${BASE_URL}/api/organisations/${orgID}/checkout/${hostedPageId}`);
  },
  getPortalSession: (orgID: number): Promise<AxiosResponse<PortalSession>> => {
    return axiosApiService.get(`${BASE_URL}/api/organisations/${orgID}/portal_session`);
  },
  cancelSubscription: (orgID: number, subscriptionId: string): Promise<AxiosResponse<void>> => {
    return axiosApiService.post(`${BASE_URL}/api/organisations/${orgID}/subscriptions/${subscriptionId}/cancel`);
  },
}

export default saasAPI;