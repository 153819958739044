import {
  IOrganisationUpdates,
  IOrganisationUpdateEvent,
} from '@/types/IOrganisation';

const feedData: IOrganisationUpdates[] = [
  {
    event: IOrganisationUpdateEvent.view_contact,
    inserted_at: '2022-12-29T00:35:14',
    profile: {
      first_name: 'Elon',
      id: 13914,
      last_name: 'D',
    },
  },
  {
    event: IOrganisationUpdateEvent.sent_contact,
    inserted_at: '2022-12-29T00:35:14',
    profile: {
      first_name: 'Maya',
      id: 13915,
      last_name: 'S',
    },
  },
  {
    event: IOrganisationUpdateEvent.view_contact,
    inserted_at: '2022-12-29T00:35:14',
    profile: {
      first_name: 'Jack',
      id: 13916,
      last_name: 'W',
    },
  },
  {
    event: IOrganisationUpdateEvent.edit_profile,
    inserted_at: '2022-12-29T00:35:14',
    profile: {
      first_name: 'Olive',
      id: 13917,
      last_name: 'L',
    },
  },
  {
    event: IOrganisationUpdateEvent.details_exchange,
    inserted_at: '2022-12-29T00:35:14',
    profile: {
      first_name: 'Alex',
      id: 13918,
      last_name: 'G',
    },
  },
  {
    event: IOrganisationUpdateEvent.details_exchange,
    inserted_at: '2022-12-29T00:35:14',
    profile: {
      first_name: 'Maya',
      id: 13919,
      last_name: 'S',
    },
  },
  {
    event: IOrganisationUpdateEvent.edit_profile,
    inserted_at: '2022-12-29T00:35:14',
    profile: {
      first_name: 'Olive',
      id: 13920,
      last_name: 'L',
    },
  },
  {
    event: IOrganisationUpdateEvent.view_contact,
    inserted_at: '2022-12-29T00:35:14',
    profile: {
      first_name: 'Jack',
      id: 13921,
      last_name: 'W',
    },
  },
  {
    event: IOrganisationUpdateEvent.view_contact,
    inserted_at: '2022-12-29T00:35:14',
    profile: {
      first_name: 'Alex',
      id: 13922,
      last_name: 'G',
    },
  },
];

export default feedData;
