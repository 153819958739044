import { useEffect, useState } from 'react';

function usePageHeight() {
  const [pageHeight, setPageHeight] = useState(0);

  useEffect(() => {
    const updatePageHeight = () => {
      const headerHeight = document.getElementsByTagName('header')[0]
        ? document.getElementsByTagName('header')[0].offsetHeight
        : 0;
      const footerHeight = document.getElementsByTagName('footer')[0]
        ? document.getElementsByTagName('footer')[0].offsetHeight
        : 0;
      const remainingPageHeight =
        window.innerHeight - headerHeight - footerHeight;

      setPageHeight(remainingPageHeight);
    };

    updatePageHeight();

    window.addEventListener('resize', updatePageHeight); // add event listener

    return () => {
      window.removeEventListener('resize', updatePageHeight); // clean up
    };
  }, [pageHeight]);

  return pageHeight;
}

export default usePageHeight;
