import React from 'react';

const ContactSent = () => {
  return (
    <svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.357 8.19036C12.9822 7.56523 13.3333 6.71739 13.3333 5.83333C13.3333 4.94928 12.9822 4.10143 12.357 3.47631C11.7319 2.85119 10.8841 2.5 10 2.5C9.11595 2.5 8.2681 2.85119 7.64298 3.47631C7.01786 4.10143 6.66667 4.94928 6.66667 5.83333C6.66667 6.71739 7.01786 7.56523 7.64298 8.19036C8.2681 8.81548 9.11595 9.16667 10 9.16667C10.8841 9.16667 11.7319 8.81548 12.357 8.19036Z"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.87522 13.3752C6.96918 12.2812 8.45291 11.6667 10 11.6667C11.5471 11.6667 13.0308 12.2812 14.1248 13.3752C15.2188 14.4692 15.8333 15.9529 15.8333 17.5H4.16667C4.16667 15.9529 4.78125 14.4692 5.87522 13.3752Z"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4167 9.81061H19.6212M19.6212 9.81061L18.2197 8.4091M19.6212 9.81061L18.2197 11.2121"
        stroke="#D1D5DB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContactSent;
