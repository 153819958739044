import Badge, { BADGE_KIND } from '@/components/Badge';
import { CARD_STATUS } from '@/components/ProductsAdminPage/BaseTab';
import { ICardAdmin } from '@/types/ICard';

const statuses = [
  {
    name: 'Unprogrammed',
    value: CARD_STATUS.UNPROGRAMMED,
    color: BADGE_KIND.RED,
  },
  {
    name: 'Unconnected',
    value: CARD_STATUS.PROGRAMMED,
    color: BADGE_KIND.PINK,
  },
  {
    name: 'In Progress',
    value: CARD_STATUS.PENDING_TO_PRINT,
    color: BADGE_KIND.AMBER,
  },
  {
    name: 'Connected',
    value: CARD_STATUS.CONNECTED,
    color: BADGE_KIND.EMERALD,
  },
];

const StatusCell: React.FC<{ card: ICardAdmin }> = ({ card }) => {
  const status = statuses.find(status => status.value === card.status);

  return (
    <td className="p-4 pb-3 text-center">
      {status && <Badge text={status.name} kind={status.color} />}
    </td>
  );
};

export default StatusCell;
