const unitPrice = 84.95;

const TaptCustomDiscount = {
  getDiscountAmountPerItem: (totalProfiles: number) => {
    const pricingTable = [
      { min: 10, discountPercent: 0.15 },
    ]

    const discountPercent = pricingTable.find((item) => totalProfiles >= item.min)?.discountPercent || 0;
    return unitPrice * discountPercent;
  },

  getPremiumDiscountAmountPerItem: () => {
    const premiumUnitPrice = 40;
    return unitPrice - premiumUnitPrice;
  }
}

export default TaptCustomDiscount