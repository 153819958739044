import { useEffect } from 'react';
import lottie from 'lottie-web';
import loadingAnimation from '../assets/lotties/dashboard-loading-animation.json';

export interface ILoading {
  className?: string;
}

export default function LoadingAnimation({ className }: ILoading) {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#loading')!,
      animationData: loadingAnimation,
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  }, []);

  return <div id="loading" className={className} />;
}
