import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  CheckIcon,
  ArrowTopRightOnSquareIcon as ExternalLinkIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { useOnClickOutside } from 'usehooks-ts';

import LottieAnimation from './LottieAnimation';

export interface ExpandPopupProps {
  onClose?: () => void;
  onNext?: () => void;
  onPrev?: () => void;
  activeAnimation?: any;
  currentStep: number;
}
const ExpandPopup: React.FC<ExpandPopupProps> = ({
  onClose = () => {},
  activeAnimation,
  currentStep,
  onNext = () => {},
  onPrev = () => {},
}) => {
  const ActiveStepData = activeAnimation[currentStep];
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose);

  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-popup-gray">
      <div className="w-full max-w-[90%] lg:max-w-[50rem]">
        <button
          className="flex items-center justify-center w-6 h-6 p-1 mb-4 ml-auto bg-white rounded-full cursor-pointer"
          onClick={onClose}
          type="button"
        >
          <XMarkIcon />
        </button>
      </div>
      <div
        className="w-full p-6 bg-white rounded-lg max-w-[90%] lg:max-w-[50rem]"
        ref={ref}
      >
        <div className="w-full">
          <div className="h-full">
            <div className="flex items-center justify-center w-full h-[18.75rem] sm:h-[31.25rem]">
              <div className="w-full h-full rounded-lg">
                {activeAnimation.map(
                  (l: { animationData: any }, index: number) =>
                    currentStep === index ? (
                      <LottieAnimation animationData={l.animationData} />
                    ) : null,
                )}
              </div>
            </div>
            <div>
              <div className="pt-3">
                <div className="flex items-center justify-between">
                  <div className="w-full h-3 max-w-full bg-gray-200 rounded-xl xl:max-w-full">
                    <div
                      className="h-3 rounded-full bg-brand-500"
                      style={{
                        width: `${(currentStep + 1) * 16.67}%`,
                      }}
                    />
                  </div>
                  <div className="pl-4 text-sm font-normal text-gray-400 xl:text-base min-w-[6.875rem] text-right">
                    Step {currentStep + 1} of 6
                  </div>
                </div>
                <h4 className="pt-4 pb-2 text-2xl font-medium text-gray-900 sm:text-3xl">
                  {ActiveStepData.name}
                </h4>
                {ActiveStepData.isExternal ? (
                  <a
                    href={ActiveStepData.url}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center cursor-pointer hover:text-brand-500"
                    onClick={ActiveStepData.onClick}
                  >
                    {ActiveStepData.linkLabel}
                    <span className="pl-1">
                      <ExternalLinkIcon />
                    </span>
                  </a>
                ) : (
                  <Link
                    to={ActiveStepData.url}
                    className="flex items-center cursor-pointer hover:text-brand-500"
                    onClick={ActiveStepData.onClick}
                  >
                    {ActiveStepData.linkLabel}
                    <span className="pl-1">
                      <ExternalLinkIcon />
                    </span>
                  </Link>
                )}
                <div className="flex items-center justify-between mt-[1.125rem]">
                  {currentStep ? (
                    <button
                      className="flex items-center justify-center w-12 h-12 bg-transparent border border-gray-300 border-solid rounded-full cursor-pointer [&>svg>path]:stroke-[#3F3F46]"
                      onClick={onPrev}
                      type="button"
                    >
                      <ChevronLeftIcon />
                    </button>
                  ) : (
                    ''
                  )}
                  <div className="ml-auto">
                    {currentStep >= activeAnimation.length - 1 ? (
                      <button
                        className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-brand-500"
                        type="button"
                      >
                        <CheckIcon />
                      </button>
                    ) : (
                      <button
                        className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-brand-500"
                        onClick={onNext}
                        type="button"
                      >
                        <ChevronRightIcon />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandPopup;
