import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IDesign from '@/types/IDesign';
import IList, { IListPaging } from '@/types/IList';

const designsAPI = {
  listDesigns: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IDesign[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/designs?page=${page}&page_size=${pageSize}&sort[${sort}]=${order}`,
    );
  },
  exportDesignsCSV: (ids: number[]): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/designs/export_csv`, {
      params: { ids },
    });
  },
  createDesign: ({
    orgID,
    body,
  }: {
    orgID: number;
    body: {
      design: Partial<IDesign>;
    };
  }): Promise<AxiosResponse<{ data: IDesign }>> => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/designs`,
      body,
    );
  },
  updateDesign: ({
    orgID,
    designID,
    body,
  }: {
    designID: number;
    orgID: number;
    body: {
      design: Partial<IDesign>;
    };
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.put(
      `${BASE_URL}/api/organisations/${orgID}/designs/${designID}`,
      body,
    );
  },
  showDesign: (
    orgID: number,
    designID: number,
  ): Promise<AxiosResponse<{ data: IDesign }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/designs/${designID}`,
    );
  },
  showDesigns: (
    orgID: number
  ): Promise<AxiosResponse<{ data: IDesign[] }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/shop/designs`,
    );
  },
  
  deleteDesign: (
    orgID: number,
    designID: number,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.delete(
      `${BASE_URL}/api/organisations/${orgID}/designs/${designID}`,
    );
  },
};

export default designsAPI;
