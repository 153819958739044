import { IIcon } from '@/types/IIcon';

const Copy = ({ className }: IIcon): JSX.Element => {
  return (
    <svg className={className} viewBox="0 0 21 20" fill="none">
      <path
        d="M13.4165 16.6665H5.08317V5.83317C5.08317 5.37484 4.70817 4.99984 4.24984 4.99984C3.7915 4.99984 3.4165 5.37484 3.4165 5.83317V16.6665C3.4165 17.5832 4.1665 18.3332 5.08317 18.3332H13.4165C13.8748 18.3332 14.2498 17.9582 14.2498 17.4998C14.2498 17.0415 13.8748 16.6665 13.4165 16.6665ZM17.5832 13.3332V3.33317C17.5832 2.4165 16.8332 1.6665 15.9165 1.6665H8.41651C7.49984 1.6665 6.74984 2.4165 6.74984 3.33317V13.3332C6.74984 14.2498 7.49984 14.9998 8.41651 14.9998H15.9165C16.8332 14.9998 17.5832 14.2498 17.5832 13.3332ZM15.9165 13.3332H8.41651V3.33317H15.9165V13.3332Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Copy;
