import { ReactNode, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button, { BUTTON_KIND } from '@/components/Button';
import classNames from '@/helpers/classNames';

interface IModalNoSuccess {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonKind?: string;
  buttonSize?: string;
  buttonClassName?: string;
  dialogHeroVideo?: string;
  buttonTitle?: string;
  dialogTitle?: string;
  dialogDescription?: string | JSX.Element;
  children?: ReactNode;
  cancelButtonText?: string;
  large?: boolean;
  onCancel?: () => void;
  className?: string;
}

export default function ModalNoSuccess({
  isOpen,
  setIsOpen,
  buttonKind = BUTTON_KIND.WHITE,
  buttonSize,
  buttonClassName,
  buttonTitle,
  dialogHeroVideo,
  dialogTitle,
  dialogDescription,
  children,
  cancelButtonText = 'Close',
  large = false,
  onCancel,
  className = '',
}: IModalNoSuccess) {
  return (
    <>
      {buttonTitle && (
        <Button
          kind={buttonKind}
          size={buttonSize}
          className={buttonClassName}
          onClick={() => setIsOpen(true)}
          buttonText={buttonTitle}
        />
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={classNames(
                  'inline-block p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl',
                  large ? 'w-full md:w-3/4 xl:w-1/2' : 'w-full max-w-lg',
                )}
              >
                {dialogHeroVideo && (
                  <video
                    src={dialogHeroVideo}
                    muted
                    autoPlay
                    loop
                    playsInline
                    className="w-full mb-6 bg-white"
                  />
                )}
                {dialogTitle && (
                  <Dialog.Title
                    as="h3"
                    className="text-2xl leading-8 font-medium text-gray-900"
                  >
                    {dialogTitle}
                  </Dialog.Title>
                )}

                {dialogDescription &&
                  dialogDescription !== 'sms_content' &&
                  dialogDescription !== 'mfa_content' && (
                    <div className={classNames(dialogTitle ? 'mt-3' : '')}>
                      <Dialog.Description
                        className={classNames(
                          'text-sm leading-5 text-gray-500',
                          className,
                        )}
                      >
                        {dialogDescription}
                      </Dialog.Description>
                    </div>
                  )}

                {dialogDescription === 'sms_content' && (
                  <div className={classNames(dialogTitle ? 'mt-2' : '')}>
                    <div className="text-gray-600 text-sm font-normal leading-[150%]">
                      Sending an SMS allows your contacts to refer back to your
                      digital profile and see your most up to date contact
                      information, files uploaded and links displayed.
                      <br />
                      <br />
                      The SMS sent to your connections will say:
                      <br />
                      <br />
                      <p className="italic">
                        You&apos;ve made a new connection - Congrats!
                        <br />
                        Here is a link to &#123;First Name&#125;&apos;s profile:
                        &#123;Profile Link&#125;.
                        <br />
                        Thanks to &#123;First Name&#125;, you can use TAPTIN at
                        checkout to recieve 10% off any Tapt product.
                      </p>
                    </div>
                  </div>
                )}

                {dialogDescription === 'mfa_content' && (
                  <div className={classNames(dialogTitle ? 'mt-3' : '')}>
                    <div className="text-sm leading-5 text-gray-500">
                      <p>
                        Multi-factor authentication (MFA) secures your tapt
                        account further by requiring two forms of identification
                        during login, safeguarding it even if your password is
                        compromised.
                      </p>
                      <br />
                      <p>
                        With MFA enabled, the email associated with this account
                        will receive a security code upon each login.
                      </p>
                    </div>
                  </div>
                )}

                {children}
                <div className="mt-6 w-full flex justify-end space-x-4">
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    onClick={() => {
                      if (onCancel) onCancel();
                      setIsOpen(false);
                    }}
                    buttonText={cancelButtonText}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
