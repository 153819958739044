import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IContacts from '@/types/IContacts';
import IList, { IListPaging } from '@/types/IList';

const contactsAPI = {
  listContacts: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IContacts[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/contacts?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },
  listContactsInGroup: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'desc',
    order = 'desc',
    search = '',
    groupID = 0,
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IContacts[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/contacts?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      } ${groupID !== 0 ? `&group_id=${groupID}` : ''}`,
    );
  },
  listContactsInGroups: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'desc',
    order = 'desc',
    search = '',
    groupIDs = [],
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IContacts[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/contacts?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      } ${groupIDs.length > 0 ? `&group_ids=${groupIDs.join(',')}` : ''}`,
    );
  },
  exportContactsCSV: (orgID: number): Promise<AxiosResponse<BlobPart>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/contacts/export_csv`,
    );
  },
};

export default contactsAPI;
