import { useCallback, useEffect, useState } from 'react';

import { UserGroupIcon } from '@heroicons/react/24/outline';

import { AxiosResponse } from 'axios';

import ICollection from '@/types/ICollection';
import { IListPaging } from '@/types/IList';
import IOrganisation from '@/types/IOrganisation';

import SearchableInput, { Item } from '../SearchableInput';

export type Props = {
  fetchData?: (orgIds: number[]) => void;
  listOrganisations: ({ search }: { search?: string }) => Promise<
    AxiosResponse<{
      data: IOrganisation[];
      paging: IListPaging;
    }>
  >;
  collection?: ICollection | { organisations: Item[]; profiles: Item[] };
};

const OrganisationFilter: React.FC<Props> = ({
  fetchData,
  listOrganisations,
  collection,
}) => {
  const [organisations, setProfiles] = useState<Item[]>([]);

  const mapOrgsToItems = useCallback((remoteOrgs: IOrganisation[]): Item[] => {
    return remoteOrgs.map(({ id, name }) => {
      const title = name === null ? 'No name' : name;

      return {
        id,
        title,
      };
    });
  }, []);

  useEffect(() => {
    const getOrgs = async () => {
      const {
        data: { data: remoteOrgs },
      } = await listOrganisations({});

      setProfiles(mapOrgsToItems(remoteOrgs));
    };

    getOrgs();
  }, [mapOrgsToItems, listOrganisations]);

  const searchItems = async (query: string) => {
    const {
      data: { data: remoteOrgs },
    } = await listOrganisations({ search: query });

    return mapOrgsToItems(remoteOrgs);
  };

  return (
    <SearchableInput
      icon={<UserGroupIcon className="w-4 h-4 mr-2" />}
      title="Organisations"
      items={organisations}
      selectedItems={collection?.organisations}
      fetchData={fetchData}
      searchItems={searchItems}
      key={`${
        (organisations.length, (collection?.organisations || []).length)
      }`}
    />
  );
};

export default OrganisationFilter;
