import { useCallback, useEffect, useState } from 'react';

import { SparklesIcon } from '@heroicons/react/24/outline';

import formatDistance from 'date-fns/formatDistance';

import saasAPI from '@/api/saas';
import Button, { BUTTON_KIND } from '@/components/Button';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import { ISubscription } from '@/types/IOrganisation';

interface ICurrentPlan {
  orgID: number;
}

const CurrentPlan: React.FC<ICurrentPlan> = ({ orgID }) => {
  const [planName, setPlanName] = useState('Tapt premium');
  const [renewalTimer, setRenewalTimer] = useState('');

  const generatePlanName = (plan: ISubscription) => {
    switch (plan.plan_id) {
      case import.meta.env.VITE_SAAS_PREMIUM_MONTHLY_PLAN_ID:
        setPlanName('Tapt Premium ($5 billed monthly)');
        break;
      case import.meta.env.VITE_SAAS_PREMIUM_YEARLY_PLAN_ID:
        setPlanName('Tapt Premium ($50 billed yearly)');
        break;
      default:
        setPlanName('Tapt Premium');
        break;
    }
  };

  const listSubscriptions = useCallback(() => {
    saasAPI
      .listSubscriptions({ orgID })
      .then(res => {
        const plan = res.data.data[0];
        generatePlanName(plan);
        setRenewalTimer(
          `Renews in ${formatDistance(
            new Date(),
            plan.current_term_end
              ? new Date(plan.current_term_end)
              : new Date(),
          )}`,
        );
      })
      .catch(() => {});
  }, [orgID]);

  useEffect(() => {
    if (orgID) {
      listSubscriptions();
    }
  }, [orgID, listSubscriptions]);

  return (
    <div className="mt-10">
      <InfoPanelContainer
        title="Current Plan"
        footerContent={() => (
          <>
            <Button kind={BUTTON_KIND.WHITE} buttonText="Cancel plan" />
            <Button buttonText="Change plan" />
          </>
        )}
      >
        <div className="grid grid-cols-6 gap-6">
          <div className="bg-brand-100 col-span-4 flex justify-center">
            <div className="rounded-md bg-brand-100 text-brand-700 flex p-4 md:mt-0 place-items-center">
              <SparklesIcon
                className="mr-3 flex-shrink-0 h-5 w-5"
                aria-hidden="true"
              />
              {planName}
            </div>
          </div>
          <div className="col-span-2 flex justify-center">
            <span className="p-4">{renewalTimer}</span>
          </div>
        </div>
      </InfoPanelContainer>
    </div>
  );
};

export { CurrentPlan as default };
