import { FC, useCallback, useEffect, useState } from 'react';
import Layout from '@/components/Layout';
import analyticsAPI from '@/api/analytics';
import { SuperuserAnalyticsResponse } from '@/types/IAnalytics';
import 'react-loading-skeleton/dist/skeleton.css';
import adminAPI from '@/api/admin';
import { LoadingSkeleton } from './AnalyticsPage';
import SuperuserAnalyticsDashboard from '@/components/Analytics/SuperuserAnalyticsDashboard';

const AnalyticsPage: FC = () => {
  const [data, setData] = useState<SuperuserAnalyticsResponse>();

  const fetchAdminData = useCallback(
    async (
      from?: string,
      to?: string,
      groupIds?: number[],
      profileIds?: number[],
    ) => {
      const response = await analyticsAPI.listAdminAnalytics(
        from,
        to,
        groupIds,
        profileIds,
      );
      setData(response.data);
    },
    [],
  );

  useEffect(() => {
    fetchAdminData();
  }, [fetchAdminData]);

  return (
    <Layout pageName="Superuser Analytics" className="bg-gray-100">
      {data === undefined ? (
        <LoadingSkeleton />
      ) : (
        <SuperuserAnalyticsDashboard
          data={data}
          refetchData={fetchAdminData}
          listProfiles={adminAPI.listProfiles}
          listGroups={adminAPI.listOrganisationGroups}
        />
      )}
    </Layout>
  );
};

export default AnalyticsPage;
