import { useState, useEffect, useCallback } from 'react';
import Layout from '@/components/Layout';
import organisationsAPI from '@/api/organisations';
import { IOrganisationUser } from '@/types/IOrganisation';
import OrganisationSettings from '@/containers/OrganisationSettings';

export default function ViewOrganisationPage({
  location,
  match,
}: {
  location: {
    state: {
      fromName: string;
      fromURL: string;
    };
  };
  match: { params: { organisation_id: number } };
}): JSX.Element {
  const locationState = location?.state;
  const orgID = match.params?.organisation_id;
  const [organisationName, setOrganisationName] = useState<string>('');
  const [orgUsers, setOrgUsers] = useState<IOrganisationUser[]>([]);

  const showOrganisation = useCallback(() => {
    organisationsAPI
      .showOrganisation(orgID)
      .then((res) => {
        setOrganisationName(res?.data?.data?.name);
      })
      .finally(() => {});
  }, [orgID, setOrganisationName]);

  const getOrganisationUsers = useCallback(() => {
    organisationsAPI
      .listOrganisationRoles({ orgID })
      .then((res) => setOrgUsers(res.data?.data))
      .finally(() => {});
  }, [orgID, setOrgUsers]);

  useEffect(() => {
    showOrganisation();
    getOrganisationUsers();
  }, [showOrganisation, getOrganisationUsers]);

  return (
    <Layout pageName="Organisation Settings" location={locationState}>
      <OrganisationSettings
        orgID={orgID}
        organisationName={organisationName}
        setOrganisationName={setOrganisationName}
        orgUsers={orgUsers}
        setOrgUsers={setOrgUsers}
        disabled
      />
    </Layout>
  );
}
