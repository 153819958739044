import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';

export interface IToken {
  access_token: string;
}

const scimApi = {
  createToken: (
    orgID: number
  ): Promise<AxiosResponse<{ data: IToken }>> => {
    return axiosApiService.post(`${BASE_URL}/api/scim/${orgID}/token`);
  }
}

export default scimApi;