import { ArrowDownTrayIcon as DownloadIcon } from '@heroicons/react/24/outline';

import { downloadFile } from '@/helpers/downloadFile';
import IOrder from '@/types/IOrderV2';

type Props = {
  cardsDetails: IOrder['cards_details'];
  cardType: IOrder['card_type'];
  backLogoUrl: IOrder['back_logo_url'];
  frontLogoUrl: IOrder['front_logo_url'];
};

const cardTypesFields = {
  black: ['name', 'mobile_number'],
  custom: [
    'name',
    'mobile_number',
    'email',
    'job_title',
    'company_name',
    'street_address',
    'company_website',
    'company_phone_number',
  ],
};

const LogoLink: React.FC<{ url: string; title: string }> = ({ title, url }) => {
  const name = url.split('/').pop() || '';

  return (
    <>
      <div className="mt-4 mb-2 text-xs font-medium">{title}:</div>
      <div className="flex text-xs">
        <div className="mr-1">
          <DownloadIcon color="#873cff" width="16" height="16" />
        </div>
        <span
          className="hover:cursor-pointer text-brand-500 underline text-sm font-medium"
          onClick={() => {
            downloadFile(url, name);
          }}
        >
          {name}
        </span>
      </div>
    </>
  );
};

const CardsDetails: React.FC<Props> = ({
  cardsDetails,
  cardType,
  backLogoUrl,
  frontLogoUrl,
}) => {
  if (!cardsDetails) return null;

  return (
    <div className="max-w-fit bg-brand-50 rounded-lg px-4 py-6 mb-3">
      <div className="text-gray-700 text-xs font-semibold uppercase">
        Printed card details
      </div>
      <div className="mt-6">
        {cardsDetails.cards.map((card, index) => (
          <div className="text-xs mb-1">
            <div className="font-medium underline mb-1">Card {index + 1}</div>
            {Object.keys(card).map(detailKey => {
              if (!card[detailKey]) return null;
              if (!!cardType && !cardTypesFields[cardType].includes(detailKey))
                return null;

              return (
                <div className="mb-1">
                  <span className="font-medium capitalize">
                    {detailKey.replace(/_/g, ' ')}:{' '}
                  </span>
                  {card[detailKey]}
                </div>
              );
            })}
          </div>
        ))}
        <hr className="mt-4 border-primary-100" />
        <div>
          {backLogoUrl && <LogoLink url={backLogoUrl} title="Back logo" />}
          {frontLogoUrl && <LogoLink url={frontLogoUrl} title="Front logo" />}
        </div>
      </div>
    </div>
  );
};

export default CardsDetails;
