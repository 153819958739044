import { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import classNames from '@/helpers/classNames';

export type GroupFilterOption<T = number> = {
  id: T;
  name: string;
};

interface IFilter<T> {
  id: string;
  filter: string;
  selected: GroupFilterOption<T>;
  setSelected: React.Dispatch<React.SetStateAction<GroupFilterOption<T>>>;
  data: GroupFilterOption<T>[];
}

export const DEFAULT_GROUP_OPTION: GroupFilterOption = {
  id: 0,
  name: 'All groups',
};

export const DEFAULT_PRODUCT_OPTION: GroupFilterOption<string[]> = {
  id: ['all_products'],
  name: 'All products',
};

export default function Filter<T>({ selected, setSelected, data }: IFilter<T>) {
  return (
    <Menu as="div" className="relative inline-block text-left z-10">
      <div>
        <Menu.Button className="inline-flex lg:max-w-52 max-w-44 justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
          {selected.name}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute lg:max-w-52 max-w-44 right-0 mt-2 w-max rounded-md shadow-lg bg-white focus:outline-none max-h-60 overflow-auto">
          <div className="py-1">
            {data.map((item, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => {
                      setSelected(item);
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'w-full text-left block px-4 py-2 text-sm',
                    )}
                  >
                    {item.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
