import { useCallback, useState } from 'react';

import adminAPI from '@/api/admin';
import Modal from '@/components/Modals/Modal';
import Search from '@/components/Search';
import { ICardAdmin } from '@/types/ICard';
import { IListPaging } from '@/types/IList';
import IProfile from '@/types/IProfile';

interface ConnectProfileModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  card: ICardAdmin;
  handleConnectProfile?: (
    profileID: number | undefined,
    orgID: number | undefined,
  ) => Promise<{ data: ICardAdmin[]; paging: IListPaging }>;
}

const ConnectProfileModal: React.FC<ConnectProfileModalProps> = ({
  isOpen,
  setIsOpen,
  card,
  handleConnectProfile,
}) => {
  const [profileID, setProfileID] = useState<number | undefined>();
  const [orgID, setOrgID] = useState<number | undefined>();
  const [profileSearch, setProfileSearch] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<IProfile[]>([]);

  const listProfiles = useCallback(async (search: string) => {
    const { data } = await adminAPI.listProfiles({
      pageSize: 5,
      search,
    });

    setProfileData(
      data.data?.filter((item: IProfile) => item.first_name && item.last_name),
    );

    return data;
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle={`Assign profile to product #${card.id}`}
      successButtonText="Assign profile"
      onSuccess={() => {
        handleConnectProfile &&
          handleConnectProfile(profileID, orgID).then(() => {
            setIsOpen(false);
          });
      }}
      isDisabled={!profileID}
    >
      <div className="mt-6 relative">
        <Search
          id={`ProfileList-1-20-date-desc--${profileSearch}`}
          label="Profile ID"
          placeholder="Search for Profile ID or Name"
          search={profileSearch}
          setSearch={value => {
            if (value !== profileSearch) {
              setProfileID(undefined);
              setOrgID(undefined);
              setShowOptions(true);
            }
            setProfileSearch(value);
          }}
          fetchQuery={(search: string) => listProfiles(search)}
        />
        {profileSearch && showOptions && (
          <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            <div className="py-1">
              {profileData && profileData.length ? (
                profileData.map((item: IProfile) => (
                  <button
                    type="button"
                    key={item.id}
                    className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                    onClick={() => {
                      setProfileID(item.id);
                      setOrgID(item.organisation_id);
                      setShowOptions(false);
                      setProfileSearch(`${item.id}`);
                    }}
                  >
                    #{item.id}{' '}
                    <span className="text-gray-500">
                      {item.first_name} {item.last_name}
                    </span>
                  </button>
                ))
              ) : (
                <li className="px-3 py-2 text-center">
                  No matching items found
                </li>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ConnectProfileModal;
