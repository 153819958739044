import { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { CreditCardIcon } from '@heroicons/react/24/outline';

import ICollection from '@/types/ICollection';

import Badge, { BADGE_KIND } from '../Badge';

export type Props = {
  cards: ICollection['cards'];
  selectedCardIds: number[];
  setSelectedCardIds: (ids: number[]) => void;
};

const CardFilter: React.FC<Props> = ({
  cards,
  selectedCardIds,
  setSelectedCardIds,
}) => {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left mt-2 lg:mt-0 z-20"
    >
      <div>
        <Menu.Button className="inline-flex items-center justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-brand-500 focus:border-brand-500">
          <CreditCardIcon className="w-4 h-4 mr-2" />
          Cards
          {(cards || []).length > 0 && (
            <>
              <Badge
                kind={BADGE_KIND.GRAY}
                className="ml-2"
                text={`${selectedCardIds.length}`}
              />
            </>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden"
          style={{
            height: cards.length <= 6 ? 'auto' : '250px',
            overflowY: cards.length <= 6 ? 'auto' : 'scroll',
          }}
        >
          <div className="py-1">
            <>
              {(cards || []).length === 0 && (
                <div>
                  <span className="mx-5 my-2 block text-sm text-gray-700">
                    Nothing here
                  </span>
                </div>
              )}
            </>
            {(cards || []).map(({ id, profile: { first_name, last_name } }) => (
              <Menu.Item key={id}>
                <div
                  onClick={e => {
                    const selectedCard = cards.find(c => c.id === id);
                    e.preventDefault(); // NOTE: This is needed to prevent the menu from closing

                    if (!selectedCard) return;

                    setSelectedCardIds(
                      selectedCardIds.includes(id)
                        ? selectedCardIds.filter(cardId => cardId !== id)
                        : [...selectedCardIds, selectedCard.id],
                    );
                  }}
                  className="flex justify-between px-3 hover:bg-gray-100 cursor-pointer"
                >
                  <div>
                    <div
                      key={id}
                      className="flex items-center py-2 pr-4"
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                    >
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-brand-500 focus:ring-brand-500 border-gray-300 rounded"
                        checked={selectedCardIds.includes(id)}
                        readOnly
                        key={id}
                      />
                      <span className="ml-3 block text-sm text-gray-700">
                        {first_name === null && last_name === null ? (
                          <span className="text-gray-400 italic">No name</span>
                        ) : (
                          `${
                            first_name?.trim() || ''
                          } ${last_name?.trim()}`.trim() || ''
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default CardFilter;
