import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { IProfileEdits } from '@/types/IAnalytics';

const defaultOptions: ApexOptions = {
  chart: {
    sparkline: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      shadeIntensity: 1,
    },
  },
  plotOptions: {
    area: {
      fillTo: 'end',
    },
  },
  tooltip: {
    style: {
      fontSize: '14px',
    },
  },
};

const defaultHeight = 200;

type ProfileEditProps = {
  data: IProfileEdits;
  title: string;
  subtitle: string;
};

export const formatNumber = (num: number) => {
  const formatter = new Intl.NumberFormat('en-AU');
  return formatter.format(num);
};

function ProfileEdits({ data, title, subtitle }: ProfileEditProps) {
  const total = data.app.total + data.dashboard.total;
  const series: ApexOptions['series'] = [
    {
      name: 'App',
      data: Object.values(data.app.entries),
      color: '#E1CEFF',
    },
    {
      name: 'Dashboard',
      data: Object.values(data.dashboard.entries),
      color: '#873CFF',
    },
  ];
  const labels: ApexOptions['labels'] = Object.keys(data.app.entries);

  return (
    <div className="flex flex-col min-h-full min-w-full justify-between">
      <div>
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex justify-between items-center flex-wrap">
            <div className="text-gray-900 font-medium text-2xl md:text-2xl">
              {title}
            </div>
          </div>
          {total !== undefined && (
            <div className="font-poppins text-gray-700 font-semibold text-2xl">
              {formatNumber(total)} edits
            </div>
          )}
        </div>
        <h3 className="text-gray-500 mb-2 hidden md:block">{subtitle}</h3>
        <hr className="h-px mt-4 mb-8 bg-gray-200 border-0" />
      </div>
      <div>
        <Chart
          height={defaultHeight}
          options={{ ...defaultOptions, labels }}
          series={series}
          type="area"
        />
        <hr className="h-px mt-4 mb-8 bg-gray-200 border-0 " />
        <div className="flex justify-evenly">
          <div className="flex items-center">
            <div className="bg-brand-500 w-2 h-2 rounded-full mr-1" />
            <div>Dashboard</div>
          </div>
          <div className="flex items-center">
            <div className="bg-brand-200 w-2 h-2 rounded-full mr-1" />
            <div>App</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileEdits;
