import { useEffect, useState } from 'react';
import accountsAPI from '@/api/accounts';
import useAuth from './useAuth';
import {
  paragon,
  AuthenticatedConnectUser,
  SDK_EVENT,
} from '@useparagon/connect';

async function getParagonUserToken(orgID: number): Promise<string> {
  const response = await accountsAPI.getIntegrationToken(orgID);
  return response.data.token;
}

export default function useParagonAuth(): {
  user?: AuthenticatedConnectUser;
  error?: Error;
} {
  const { orgID } = useAuth();

  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<AuthenticatedConnectUser | undefined>();
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (orgID) {
      getParagonUserToken(orgID).then(setToken).catch(setError);
    }
  }, [orgID]);

  // Listen for account state changes
  useEffect(() => {
    const listener = () => {
      if (paragon) {
        const authedUser = paragon.getUser();
        if (authedUser.authenticated) {
          setUser({ ...authedUser });
        }
      }
    };

    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listener);

    return () => {
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listener);
    };
  }, [paragon]);

  useEffect(() => {
    if (paragon && token && !error) {
      paragon
        .authenticate(import.meta.env.VITE_PARAGON_PROJECT_ID || '', token)
        .then(() => {
          const authedUser = paragon.getUser();
          if (authedUser.authenticated) {
            setUser(authedUser);
          }
        })
        .catch(setError);
    }
  }, [paragon, token, error]);

  return { user, error };
}
